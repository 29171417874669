(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('FilmDetailController', FilmDetailController);

    FilmDetailController.$inject = ['$scope', '$rootScope', '$state', 'previousState', 'entity', 'Film', 'VideoFile', 'Location', 'Keyword', 'Reporter', 'Event'];

    function FilmDetailController($scope, $rootScope, $state, previousState, entity, Film, VideoFile, Location, Keyword, Reporter, Event) {
        var vm = this;

        vm.film = entity;
        vm.back = back;
        vm.getVideoSrc = getVideoSrc;
        vm.getImageSrc = getImageSrc;

        function getVideoSrc(quality, format) {
            quality = quality || "HD_720P";
            format = format || "MP4";

            if (vm.film.videoFileGuid) {
                return 'https://streams2.winkball.com/videos/'+vm.film.videoFileGuid+'?quality='+quality+'&format='+format;
            }
            return "";
        }

        function getImageSrc(width, height) {
            var dimentions = '';

            if(width && height) {
                dimentions = '?w='+width+'&h='+height;
            }

            if (vm.film.thumbnail) {
                return 'https://streams2.winkball.com/images/'+vm.film.thumbnail+dimentions;
            }

            return '';
        }

        function back() {
            if(previousState.name === 'event-detail') {
                $state.go(previousState.name, previousState.params);
            } else {
                if(previousState.name === 'home') {
                    $state.go('home');
                } else {
                    $state.go('film');
                }
                
            }
            
        }

        var unsubscribe = $rootScope.$on('winkballApp:filmUpdate', function(event, result) {
            vm.film = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
