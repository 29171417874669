(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('LocationDetailController', LocationDetailController);

    LocationDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'Principal', 'previousState', 'entity', 'Location', 'User'];

    function LocationDetailController($scope, $rootScope, $state, $stateParams, Principal, previousState, entity, Location, User) {
        var vm = this;

        vm.location = entity;
        vm.previousState = previousState.name;
        vm.canEdit = canEdit;
        vm.canDelete = canDelete;
        vm.back = back;

        getAccount();

        function canEdit() {
          return canDelete() || vm.location.createdBy === vm.account.login;
        }

        function canDelete() {
          return Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SUPERVISOR']);
        }

        function getAccount() {
          Principal.identity().then(function(account) {
            console.log('identity loaded');
              vm.account = account;
              vm.isAuthenticated = Principal.isAuthenticated;
          });
        }

        var unsubscribe = $rootScope.$on('winkballApp:locationUpdate', function(event, result) {
            vm.location = result;
        });

        function back() {
          $state.go(previousState.name, previousState.params);
        }

        $scope.$on('$destroy', unsubscribe);
    }
})();
