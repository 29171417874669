(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('ReporterDetailController', ReporterDetailController);

    ReporterDetailController.$inject = ['$scope', '$rootScope', '$state', '$q', 'entity', 'previousState', 'Reporter', 'PostalAddress', 'Principal'];

    function ReporterDetailController($scope, $rootScope, $state, $q, entity, previousState, Reporter, PostalAddress, Principal) {
        var vm = this;

        vm.reporter = entity;
        vm.getImageSrc = getImageSrc;
        vm.account = null;
        vm.checkEditPermission = checkEditPermission;
        vm.back = back;
        vm.address = null;
        vm.weblinks = [];

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        $q.all([vm.reporter.$promise]).then(function() {
            if (!vm.reporter.addressId) {
                return $q.reject();
            }
            return PostalAddress.get({id : vm.reporter.addressId}).$promise;
        }).then(function(address) {
            vm.address = address;
        });

        $q.all([vm.reporter.$promise]).then(function() {
            if (!vm.reporter.id) {
                return $q.reject();
            }
            return Reporter.getWebLinks({id : vm.reporter.id}).$promise;
        }).then(function(weblinks) {
          // console.log(weblinks);
            vm.weblinks = weblinks;
            if(vm.weblinks.length > 0) {
                vm.twitter = vm.weblinks.find(function(item) {
                    return item.title.toLowerCase() === 'twitter';
                });

                vm.facebook = vm.weblinks.find(function(item) {
                    return item.title.toLowerCase() === 'facebook';
                });

                vm.instagram = vm.weblinks.find(function(item) {
                    return item.title.toLowerCase() === 'instagram';
                });

                vm.website = vm.weblinks.find(function(item) {
                    return item.title.toLowerCase() === 'website';
                });
            }
        });

        function getImageSrc(width, height) {
            var dimentions = '';

            if(width && height) {
                dimentions = '?w='+width+'&h='+height;
            }

            if (vm.reporter.profilePicture) {
                return 'https://streams2.winkball.com/images/'+vm.reporter.profilePicture+dimentions;
            }

            return '';
        }

        function checkEditPermission() {
            return vm.reporter.login === vm.account.login ||
            vm.account.authorities.indexOf('ROLE_MANAGER') > -1 || vm.account.authorities.indexOf('ROLE_ADMIN') > -1;
        }

        function back() {
            if($state.current.name === 'reporter-detail-login' && previousState.name !== 'report-detail') {
                $state.go('home');
            } else {
                if(previousState.name === 'project-detail' || previousState.name === 'report-detail') {
                    $state.go(previousState.name, previousState.params);
                } else {
                    $state.go('reporter');
                }
            }
        }

        var unsubscribe = $rootScope.$on('winkballApp:reporterUpdate', function(event, result) {
            vm.reporter = result;
        });

        $scope.$on('$destroy', unsubscribe);
    }
})();
