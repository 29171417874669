(function() {
    'use strict';

    angular
        .module('winkballApp')
        .factory('SponsorSearch', SponsorSearch);

    SponsorSearch.$inject = ['$resource'];

    function SponsorSearch($resource) {
      var resourceUrl =  'api/_search/sponsors/:id';

      return $resource(resourceUrl, {}, {
          'query': { method: 'GET', isArray: true}
      });
    }
})();
