(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('EventCommentDialogController', EventCommentDialogController);

    EventCommentDialogController.$inject = ['$timeout', '$scope', '$q', '$uibModalInstance', 'entity', 'EventComment', 'Event', 'EventSearch'];

    function EventCommentDialogController ($timeout, $scope, $q, $uibModalInstance, entity, EventComment, Event, EventSearch) {
        var vm = this;

        vm.eventComment = entity;
        vm.clear = clear;
        vm.save = save;
        // vm.events = Event.query();
        vm.searchEvents = searchEvents;
        vm.getEvent = getEvent;
        vm.event = null;
        vm.readonly = false;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        $q.all([vm.eventComment.$promise]).then(function() {
            if(!vm.eventComment.eventId) {
                return $q.reject();
            }

            return Event.get({id : vm.eventComment.eventId}).$promise;
        }).then(function(event) {
            vm.event = event;
            vm.readonly = true;
        });

        function searchEvents(query) {
            return EventSearch.query({
                query: query,
                page: 0,
                size: 10
            }).$promise;
        }

        function getEvent(data) {
            vm.eventComment.eventId = data.originalObject.id;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.eventComment.id !== null) {
                EventComment.update(vm.eventComment, onSaveSuccess, onSaveError);
            } else {
                EventComment.save(vm.eventComment, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('winkballApp:eventCommentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
