(function () {
    'use strict';

    angular
        .module('winkballApp')
        .controller('ProspectivePublisherEmailController', ProspectivePublisherEmailController);

    ProspectivePublisherEmailController.$inject = ['$uibModalInstance', 'entity', 'ProspectivePublisher'];

    function ProspectivePublisherEmailController($uibModalInstance, entity, ProspectivePublisher) {
        var vm = this;

        vm.publisher = entity;
        vm.clear = clear;
        vm.confirmEmail = confirmEmail;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmEmail() {
            ProspectivePublisher.invite(vm.publisher,
                function () {
                    $uibModalInstance.close(true);
                }
            );
        }

    }
})();
