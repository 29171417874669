(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('JobApplyController', JobApplyController);

    JobApplyController.$inject = ['$uibModalInstance', '$q', 'entity', 'Job', 'Principal', 'User'];

    function JobApplyController($uibModalInstance, $q, entity, Job, Principal, User) {
        var vm = this;

        vm.job = entity;
        vm.clear = clear;
        vm.roles = ["PRESENTER", "EDITOR", "CAMERA"];
        vm.selectedRoles = [];
        vm.applyForJob = applyForJob;
        vm.currentUser = null;
        vm.toggleRoles = toggleRoles;
        vm.originalRoles = [];

        getAccount();

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function applyForJob () {

            if(vm.currentUser) {

                if(vm.originalRoles.length > 0) {

                    var toSave = vm.selectedRoles.filter(function (item) {
                        return !vm.originalRoles.includes(item);
                    });

                    var toDelete = vm.originalRoles.filter(function (item) {
                        return !vm.selectedRoles.includes(item);
                    });

                    if (toSave.length > 0) {
                        // apply for new role, previously not saved
                        toSave.forEach(function(el) {
                            Job.applyForJob({id: vm.job.id, reporterId: vm.currentUser.id, role: el});
                        });
                    }

                    if(toDelete.length > 0) {
                        // delete existing applications
                        toDelete.forEach(function(el) {
                            Job.deleteJobApplications({id: vm.job.id, reporterId: vm.currentUser.id, role: el});
                        });
                    }

                } else {
                    save();
                }
            }

            $uibModalInstance.close(true);
        }

        function save(withDelete) {
            if(withDelete) {
                // console.log('Save with delete operation...');
                // delete existing application if exists
                var deleteOp = Job.deleteJobApplications({id: vm.job.id, reporterId: vm.currentUser.id}).$promise;

                deleteOp.then(function(success) {
                    vm.selectedRoles.forEach(function(el) {
                        Job.applyForJob({id: vm.job.id, reporterId: vm.currentUser.id, role: el});
                    });

                });
            } else {
                vm.selectedRoles.forEach(function(el) {
                    Job.applyForJob({id: vm.job.id, reporterId: vm.currentUser.id, role: el});
                });
            }
        }

        function toggleRoles(role) {
            var idx = vm.selectedRoles.indexOf(role);

          // if currently selected
            if (idx > -1) {
            // remove role
                vm.selectedRoles.splice(idx, 1);
            } else {
            // add role
                vm.selectedRoles.push(role);
            }
        }

        function getAccount() {
            Principal.identity().then(function(account) {
                // console.log('identity loaded');

                $q.all([vm.job.$promise]).then(function() {
                    if(!vm.job.id) {
                        return $q.reject();
                    }
                    return User.get({login:account.login}).$promise;
                }).then(function(user) {

                    // console.log('Current user is set');
                    vm.currentUser = user;

                    $q.all([vm.currentUser.$promise]).then(function() {
                        if(!vm.job.id) {
                            return $q.reject();
                        }

                        return Job.getJobApplicationsForReporter({id: vm.job.id, reporterId: vm.currentUser.id}).$promise;
                    }).then(function(jobs) {
                        // console.log('Job applications are set');
                        vm.selectedRoles = jobs.map(function(item) {
                            return item.reporterJobRole;
                        });
                        
                        // clone and save original set of roles
                        vm.originalRoles = vm.selectedRoles.slice();

                    });

                });

            });
        }

    }
})();
