(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('EventShareController', EventShareController);

    EventShareController.$inject = ['$window','$uibModalInstance', '$http', '$log', 'entity', 'Event'];

    function EventShareController($window, $uibModalInstance, $http, $log, entity, Event) {
        var vm = this;

        vm.event = entity;
        vm.clear = clear;
        vm.shareEvent = shareEvent;
        vm.share = {};
        vm.share.message = vm.event.title+' https://www.winkball.com/events/' + vm.event.id;
        vm.authToken = angular.fromJson(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));
        vm.twitterUrl = getTwitterUrl();
        vm.mailToUrl = getMailToUrl();
        vm.pinterestUrl = getPinterestUrl();
        vm.videoEmbed = getVideoEmbedCode();

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function shareEvent (id) {
            if(vm.share.twitter) {
                $http.post(
              'https://social.winkball.com/tweets/winkball',
              {message: vm.share.message}, {
                  headers:{
                      'Authorization': 'Bearer ' + vm.authToken
                  }}).then(
                function () {
                    $log.log('Tweet sent...');
                },
                function () {
                    $log.log('Error twitting...');
                }
            );
            }

            if(vm.share.youtube && vm.event.films.length > 0) {
                // selecting first film in the array - not ideal
                var video = vm.event.films[0].videoFile;

                var url = 'https://streams2.winkball.com/videos/'+video.guid+'?quality=HD_720P';

                var kw = [];
                for (var keyword in vm.event.keywords) {
                    kw.push(keyword.text);
                }

                var youtube = {
                    videoLink: url,
                    title: vm.event.title,
                    description: vm.event.description,
                    tags: kw,
                    channelId: "UCR_vjXrVpV9TI-GMGUVETcg"
                };

                $http.post(
              'https://social.winkball.com/youtube/winkball/video',
              youtube, {
                  headers:{
                      'Authorization': 'Bearer ' + vm.authToken
                  }}).then(
                function () {
                    $log.log('Youtube video sent...');
                },
                function () {
                    $log.log('Error sendign Youtube video...');
                }
            );
            }

            if(vm.share.facebook) {
                $log.log('sharing on Facebook');

                var facebook = {
                    linkUrl: 'https://www.winkball.com/events/' + vm.event.id,
                    title: vm.event.title,
                    description: vm.event.description,
                    caption: 'https://www.winkball.com',
                    message: vm.share.message
                };

                $http.post(
              'https://social.winkball.com/facebook/winkball/page',
              facebook, {
                  headers:{
                      'Authorization': 'Bearer ' + vm.authToken
                  }}).then(
                function () {
                    $log.log('Facebook video sent...');
                },
                function () {
                    $log.log('Error sendign Facebook video...');
                }
            );
            }

            $uibModalInstance.close(true);
        }

        function getTwitterUrl() {
            return 'https://twitter.com/intent/tweet?url=https://www.winkball.com/events/'+vm.event.id+'&text='+ $window.encodeURIComponent(vm.event.title);
        }

        function getMailToUrl() {
            return 'mailto:?subject='+ $window.encodeURIComponent(vm.event.title) +'&body=https://www.winkball.com/events/' + vm.event.id;
        }

        function getPinterestUrl() {
            return 'https://pinterest.com/pin/create/button/?url=https://www.winkball.com/events/'+vm.event.id+'&description='+ $window.encodeURIComponent(vm.event.title) +'&media=video';
        }

        function getVideoEmbedCode(width, height) {
            if (!width || !height) {
              // youtube defaults
                width = 560;
                height = 315;
            }

            if(vm.event.films.length > 0) {
                var video = vm.event.films[0].videoFile;
                return '<iframe src="https://www.winkball.com/playercard/' + video.guid + '" frameborder="0" scrolling="no" width="' + width + '" height="' + height + '"></iframe>';
            } else {
                return '';
            }
        }
    }
})();
