(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('FilmDialogController', FilmDialogController);

    FilmDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', '$http', 'entity', 'Film', 'VideoFile', 'Location', 'KeywordSearch', 'Reporter', 'Event', 'Upload', 'LocationSearch', 'ReporterSearch', 'EventSearch'];

    function FilmDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, $http, entity, Film, VideoFile, Location, KeywordSearch, Reporter, Event, Upload, LocationSearch, ReporterSearch, EventSearch) {
        var vm = this;

        vm.film = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.thumbnailsForDeletion = [];
        vm.originalThumbnail = vm.film.thumbnail;
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.getVideoSrc = getVideoSrc;
        vm.getImageSrc = getImageSrc;
        vm.deleteThumbnail = deleteThumbnail;
        vm.searchLocation = searchLocation;
        vm.searchReporters = searchReporters;
        vm.tagLocation = [];
        vm.getLeadReporter = getLeadReporter;
        vm.getEditor = getEditor;
        vm.searchEvents = searchEvents;
        vm.getEvent = getEvent;

        vm.authToken = JSON.parse(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));

        vm.videofiles = VideoFile.query({filter: 'film-is-null'});
        $q.all([vm.film.$promise, vm.videofiles.$promise]).then(function() {
            if (!vm.film.videoFileId) {
                return $q.reject();
            }
            return VideoFile.get({id : vm.film.videoFileId}).$promise;
        }).then(function(videoFile) {
            vm.videofiles.push(videoFile);
        });

        vm.locations = Location.query({filter: 'film-is-null'});

        $q.all([vm.film.$promise, vm.locations.$promise]).then(function() {
            if (!vm.film.locationId) {
                return $q.reject();
            }
            return Location.get({id : vm.film.locationId}).$promise;
        }).then(function(location) {
            vm.locations.push(location);
            vm.tagLocation = [location];
        });

        $q.all([vm.film.$promise]).then(function() {
              if (!vm.film.eventId) {
                  return $q.reject();
              }

              return Event.get({id : vm.film.eventId}).$promise;
          }).then(function(item) {
            // used for setting a value on Event autocomplete input field
            $scope.$broadcast('angucomplete-alt:changeInput', 'field_event', item);
        });

        vm.searchKeywords = searchKeywords;

        function searchLocation(query) {
          return LocationSearch.query({
              query: query,
              page: 0,
              size: 10
          }).$promise;
        }

        function searchReporters(query) {
          return ReporterSearch.query({
              query: query,
              page: 0,
              size: 10
          }).$promise;
        }

        function searchEvents(query) {
          return EventSearch.query({
              query: query,
              page: 0,
              size: 10
          }).$promise;
        }

        $timeout(function (){
            angular.element('.form-group:eq(2)>input').focus();
        });

        function searchKeywords(query) {
          return KeywordSearch.query({
              query: query,
              page: 0,
              size: 10
          }).$promise;
        }

        function getEditor(data) {
          vm.film.editor = data.originalObject;
        }

        function getLeadReporter(data) {
          vm.film.leadReporter = data.originalObject;
        }

        function getEvent(data) {
          vm.film.eventId = data.originalObject.id;
        }

        function clear () {
          vm.film.thumbnail = vm.originalThumbnail;

          var puids = vm.thumbnailsForDeletion.filter(function (value) {
              return vm.originalThumbnail != value;
          });

          if(puids.length > 0) {
            $http.post(
              'https://streams2.winkball.com/delete-images',
              puids, {
              headers:{
                'Authorization': 'Bearer ' + vm.authToken
              }}).then(
                function () {
                  vm.thumbnailsForDeletion = [];
                },
                function () {
                  console.log('error deleting images');
                }
            );
          }
          vm.tagLocation = [];

          $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            if(vm.thumbnailsForDeletion.length > 0) {
              $http.post(
                'https://streams2.winkball.com/delete-images',
                vm.thumbnailsForDeletion, {
                headers:{
                  'Authorization': 'Bearer ' + vm.authToken
                }}).then(
                  function () {
                    vm.thumbnailsForDeletion = [];
                  },
                  function () {
                    console.log('error deleting thumbnail');
                  }
              );
            }

            if(vm.tagLocation.length > 0) {
              vm.film.locationId = vm.tagLocation[0].id;
            } else {
              vm.film.locationId = null;
            }

            if (vm.film.id !== null) {
                Film.update(vm.film, onSaveSuccess, onSaveError);
            } else {
                Film.save(vm.film, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('winkballApp:filmUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            vm.originalThumbnail = vm.film.thumbnail;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function getVideoSrc(quality, format) {
            quality = quality || "HD_720P";
            format = format || "MP4";

            if (vm.film.videoFileGuid) {
                return 'https://streams2.winkball.com/videos/'+vm.film.videoFileGuid+'?quality='+quality+'&format='+format;
            }
            return "";
        }

        function getImageSrc(width, height) {
          var dimentions = '';

          if(width && height) {
            dimentions = '?w='+width+'&h='+height;
          }

          if (vm.film.thumbnail) {
            return 'https://streams2.winkball.com/images/'+vm.film.thumbnail+dimentions;
          }

          return '';
        }

        function deleteThumbnail() {
          console.log('calling deleteThumbnail');
          vm.thumbnailsForDeletion.push(vm.film.thumbnail);
          vm.film.thumbnail = null;
        }

        vm.datePickerOpenStatus.dateCompleted = false;
        vm.datePickerOpenStatus.internalSignOffDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $scope.uploadFile = function(file, errFiles, image){
          console.log('calling uploadFile...');
          vm.isSaving = true;
          $scope.f = file;
          $scope.video = !image;
          $scope.errFile = errFiles && errFiles[0];

          if(file) {
            var uploadURL = "https://streams2.winkball.com/videos";

            if(image) {
              uploadURL = "https://streams2.winkball.com/images";
            }

            file.upload = Upload.upload(
              {
                url: uploadURL,
                data: {file: file},
                headers: {'Authorization': 'Bearer ' + vm.authToken}
              }
            );

            file.upload.then(function (response) {
              $timeout(function() {
                file.result = response.data;
                vm.isSaving = false;

                if(response.status == "201") {
                  console.log('Upload status 201');
                  if(image) {
                    vm.film.thumbnail = response.data;
                  } else {
                    vm.film.videoFileGuid = response.data;
                    vm.film.videoFileName = file.name;
                  }
                }
              });
            }, function(response){
              console.log('Error...');
              vm.isSaving = false;
              if(response.status > 0) {
                $scope.errorMsg = response.status +': ' + response.data;
              }
            }, function(evt) {
              file.progress = Math.min(100, parseInt(100.0 * evt.loaded/evt.total));
            });
          }
        };
    }
})();
