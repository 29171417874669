(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('VideoFileDetailController', VideoFileDetailController);

    VideoFileDetailController.$inject = ['$scope', '$rootScope', 'previousState', 'entity'];

    function VideoFileDetailController($scope, $rootScope, previousState, entity) {
        var vm = this;

        vm.videoFile = entity;
        vm.previousState = previousState.name;
        vm.getVideoSrc = getVideoSrc;

        function getVideoSrc(quality, format) {
            quality = quality || "HD_720P";
            format = format || "MP4";

            if (vm.videoFile.guid) {
                return 'https://streams2.winkball.com/videos/'+vm.videoFile.guid+'?quality='+quality+'&format='+format;
            }
            return "";
        }

        var unsubscribe = $rootScope.$on('winkballApp:videoFileUpdate', function(event, result) {
            vm.videoFile = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
