(function () {
    'use strict';

    angular
        .module('winkballApp')
        .controller('CommissionReportRequestDeleteController', CommissionReportRequestDeleteController);

    CommissionReportRequestDeleteController.$inject = ['$uibModalInstance', 'entity', 'CommissionReportRequest'];

    function CommissionReportRequestDeleteController($uibModalInstance, entity, CommissionReportRequest) {
        var vm = this;

        vm.commissionReportRequest = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            CommissionReportRequest.delete({ id: id },
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
