(function () {
    'use strict';

    angular
        .module('winkballApp')
        .controller('CommissionReportRequestController', CommissionReportRequestController);

    CommissionReportRequestController.$inject = ['$scope', '$state', 'CommissionReportRequest', 'CommissionReportRequestSearch', 'ParseLinks', 'AlertService'];

    function CommissionReportRequestController($scope, $state, CommissionReportRequest, CommissionReportRequestSearch, ParseLinks, AlertService) {
        var vm = this;

        vm.commissionReportRequests = [];
        vm.loadPage = loadPage;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.predicate = 'createdDate';
        vm.reset = reset;
        vm.reverse = false;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.search = search;

        loadAll();

        function loadAll() {
            if (vm.currentSearch) {
                CommissionReportRequestSearch.query({
                    query: vm.currentSearch,
                    page: vm.page,
                    size: 20,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                CommissionReportRequest.query({
                    page: vm.page,
                    size: 20,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                // if (vm.predicate !== 'id') {
                //     result.push('id');
                // }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.commissionReportRequests.push(data[i]);
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset() {
            vm.page = 0;
            vm.commissionReportRequests = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function clear() {
            vm.commissionReportRequests = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = null;
            vm.currentSearch = null;
            vm.loadAll();
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.commissionReportRequests = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.loadAll();
        }
    }
})();
