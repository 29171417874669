/* global google */
(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('EventDialogController', EventDialogController);

    EventDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$uibModalInstance', '$http', '$log', '$q', 'entity', 'Event', 'Location', 'User', 'UserSearch', 'Upload', 'KeywordSearch', 'LocationSearch', 'ReporterSearch', 'Job', 'NgMap'];

    function EventDialogController ($timeout, $scope, $state, $stateParams, $uibModalInstance, $http, $log, $q, entity, Event, Location, User, UserSearch, Upload, KeywordSearch, LocationSearch, ReporterSearch, Job, NgMap) {
        var vm = this;

        vm.event = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.thumbnailsForDeletion = [];
        vm.originalThumbnail = vm.event.thumbnail;
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.searchKeywords = searchKeywords;
        vm.searchLocations = searchLocations;
        vm.tagLocation = vm.event.location ? [vm.event.location] : [];
        vm.searchReporters = searchReporters;
        vm.reporters = [];
        vm.users = [];
        vm.jobs = [];
        vm.searchUser = searchUser;
        vm.getManager = getManager;
        vm.uploadFile = uploadFile;

        vm.saveLocation = saveLocation;
        vm.location = vm.event.location || {};
        vm.location.geoPoint = vm.location.geoPoint || {};
        vm.locationRemoved = locationRemoved;
        vm.locationAdded = locationAdded;
        vm.clearLocation = clearLocation;
        vm.addMarker = addMarker;
        vm.geoCode = geoCode;
        vm.search = "";
        vm.jobApplicants = [];
        vm.selectedApplicants = [];
        vm.multiSelectSettings = {externalIdProp: '', template: '<b>{{option.reporter.firstName}} {{option.reporter.lastName}} <small>({{option.reporterJobRole}})<smal></b>'};
        vm.toggleCategory = toggleCategory;
        vm.selectReportersText = {buttonDefaultText: 'Select Reporters'};

        vm.categories = Event.categories();

        vm.jobs = Job.query({
            filter: "approved",
            page: 0,
            size: 40,
            sort: "createdDate,desc"});

        $q.all([vm.event.$promise, vm.jobs.$promise]).then(function() {
            if(!vm.event.id) {
                return $q.reject();
            }
            return Event.reporters({id : vm.event.id}).$promise;
        }).then(function(reporters) {
            vm.reporters = reporters;
        });

        $q.all([vm.event.$promise]).then(function() {
            if(!vm.event.job) {
                return $q.reject();
            }

            return Job.get({id : vm.event.job.id}).$promise;
        }).then(function(job) {
            vm.jobs.push(job);
        });

        // load job and job applicants for new event
        $q.all([vm.event.$promise]).then(function() {
            if(vm.event.id) {
                return $q.reject();
            }

            if(!$stateParams.job) {
                return $q.reject();
            }
            return Job.get({id : $stateParams.job}).$promise;
        }).then(function(job) {
            vm.event.job = job;
            vm.event.title = job.title;
            vm.event.description = job.description;
            vm.event.manager = job.manager;
            vm.event.startDate = job.startDate;
            vm.event.reportDueDate = job.reportDueDate;
            vm.event.keyTalent = job.keyTalent;
            vm.event.socialMedia = job.socialMedia;
            vm.event.editorialStoryline = job.editorialStoryline;
            vm.event.contactName = job.contactName;
            vm.event.contactPhone = job.contactPhone;
            vm.event.contactEmail = job.contactEmail;
            vm.event.categories = job.categories;

            if(job.location) {
                vm.event.location = job.location;
                vm.tagLocation.push(job.location);
            }

            var applicants = Job.reporters({id : vm.event.job.id}).$promise;
            applicants.then(function(reporters) {
                vm.jobApplicants = reporters;

                $log.log('Retrieved jobApplicants');
                $log.log(vm.jobApplicants);

              // set fake id for job applications multiselect directive
                if(vm.jobApplicants.length > 0) {
                    var counter = 0;
                    vm.jobApplicants = vm.jobApplicants.map(function(item) {
                        counter++;
                        item.id = counter;
                        return item;
                    });

                    $log.log('Filtered applicants');
                    $log.log(vm.jobApplicants);

                    vm.selectedApplicants = vm.jobApplicants.filter(function(item) {
                        return item.accepted;
                    });
                }
            });

        });

        // load job applicants for existing event
        $q.all([vm.event.$promise]).then(function() {
            $log.log('Trying to load applicants....');

            if (!vm.event.id || (!vm.event.job || !vm.event.job.id)) {
                return $q.reject();
            }
          
            return Job.reporters({id : vm.event.job.id}).$promise;
        }).then(function(reporters) {
            vm.jobApplicants = reporters;

          // set fake id for job applications multiselect directive
            if(vm.jobApplicants.length > 0) {
                var counter = 0;
                vm.jobApplicants = vm.jobApplicants.map(function(item) {
                    counter++;
                    item.id = counter;
                    return item;
                });

                vm.selectedApplicants = vm.jobApplicants.filter(function(item) {
                    return item.accepted;
                });
            }
        });

        $q.all([vm.event.$promise]).then(function() {
            if (!vm.event.location) {
                return $q.reject();
            }

            return Location.get({id : vm.event.location.id}).$promise;
        }).then(function(location) {
            vm.tagLocation = [location];
        });

        vm.getImageSrc = getImageSrc;
        vm.deleteThumbnail = deleteThumbnail;
        vm.authToken = angular.fromJson(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));

        $timeout(function (){
            angular.element('.form-group:eq(2)>input').focus();
        });

        function searchReporters(query) {
            return ReporterSearch.query({
                query: query,
                page: 0,
                size: 10
            }).$promise;
        }

        function searchKeywords(query) {
            return KeywordSearch.query({
                query: query,
                page: 0,
                size: 10
            }).$promise;
        }

        function searchLocations(query) {
            return LocationSearch.query({
                query: query,
                page: 0,
                size: 10
            }).$promise;
        }

        $scope.$on('mapInitialized', function(evt, map) {
            $log.log('map initialised');

            vm.map = map;
            google.maps.event.trigger(vm.map, 'resize');
            if(vm.location.geoPoint.lat && vm.location.geoPoint.lon) {
                map.setCenter({lat: vm.location.geoPoint.lat, lng: vm.location.geoPoint.lon});
            }
        });

        function searchUser(query) {
            return UserSearch.query({query: query}).$promise;
        }

        function getManager(data) {
            vm.event.manager = data.originalObject;
        }

        function toggleCategory(category) {
            var idx = vm.event.categories.indexOf(category);
            if(idx > -1) {
                vm.event.categories.splice(idx, 1);
            } else {
                vm.event.categories.push(category);
            }
        }

        function clear () {
            $log.log('calling clear method..');

            $log.log('originalThumbnail: ' + vm.originalThumbnail);

            vm.event.thumbnail = vm.originalThumbnail;

            var puids = vm.thumbnailsForDeletion.filter(function (value) {
                return vm.originalThumbnail !== value;
            });

            if(puids.length > 0) {
                $http.post(
              'https://streams2.winkball.com/delete-images',
              puids, {
                  headers:{
                      'Authorization': 'Bearer ' + vm.authToken
                  }}).then(
                function () {
                    $log.log('videos deleted');

                    vm.thumbnailsForDeletion = [];
                },
                function () {
                    $log.log('error deleting videos');
                }
            );
            }

            vm.tagLocation = [];
            vm.selectedApplicants = [];

            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            if(vm.thumbnailsForDeletion.length > 0) {
                $http.post(
              'https://streams2.winkball.com/delete-images',
              vm.thumbnailsForDeletion, {
                  headers:{
                      'Authorization': 'Bearer ' + vm.authToken
                  }}).then(
                function () {
                    vm.thumbnailsForDeletion = [];
                },
                function () {
                    $log.log('error deleting thumbnail');
                }
            );
            }

            if(vm.tagLocation.length > 0) {
                vm.event.location = vm.tagLocation[0];
            } else {
                vm.event.location = null;
            }

            if(vm.selectedApplicants.length > 0) {
                // extract reporters
                var selectedReporters = vm.selectedApplicants.map(function (item) {
                    return item.reporter;
                });

                // remove duplicate reporters
                vm.reporters = selectedReporters.filter(function( item, index, selectedReporters) {
                    return selectedReporters.map(function (obj) { return obj.id; }).indexOf(item.id) === index;
                });
            }

            if(vm.reporters.length > 0) {
                // HACK - vm.reporters are DTOs but vm.event.reporters are Reporter objects
                // still works when saving or updating
                vm.event.reporters = vm.reporters;

                var selectedIDs = vm.selectedApplicants.map(function(item) {
                    return item.id;
                });

                vm.jobApplicants = vm.jobApplicants.map(function(item) {
                    item.accepted = selectedIDs.indexOf(item.id) > -1;
                    delete item.id;
                    return item;
                });

                if(vm.event.job && vm.jobApplicants.length > 0) {
                    // update reporter applicants
                    Job.updateReporters({ id : vm.event.job.id }, vm.jobApplicants);
                }

            } else {
                vm.event.reporters = [];
                vm.jobApplicants = vm.jobApplicants.map(function(item) {
                    item.accepted = false;
                    delete item.id;
                    return item;
                });

                if(vm.event.job && vm.jobApplicants.length > 0) {
                    // update reporter applicants
                    Job.updateReporters({ id : vm.event.job.id }, vm.jobApplicants);
                }
            }

            if (vm.event.id !== null) {
                Event.update(vm.event, onSaveSuccess, onSaveError);
            } else {
                Event.save(vm.event, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('winkballApp:eventUpdate', result);
            vm.isSaving = false;
            vm.originalThumbnail = vm.event.thumbnail;

            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function saveLocation() {
            vm.isSaving = true;
            if (!vm.location.id) {
                Location.save(vm.location, onSaveLocationSuccess, onSaveError);
            }
        }

        function onSaveLocationSuccess(result) {
            vm.event.location = result;
            vm.tagLocation[0] = result;
            vm.isSaving = false;
        }

        function locationRemoved() {
            vm.location = {};
            vm.location.geoPoint = {};
        }

        function locationAdded() {
            vm.location = vm.tagLocation[0];
        }

        function clearLocation() {
            vm.location = {};
            vm.location.geoPoint = {};
            vm.event.location = {};
            vm.tagLocation = [];
        }

        function addMarker(event) {
            var ll = event.latLng;
          // round coordinates to 5 decimal places
            vm.location.geoPoint.lon =  Math.floor(ll.lng()*100000+0.5)/100000;
            vm.location.geoPoint.lat =  Math.floor(ll.lat()*100000+0.5)/100000;
        }

        function geoCode() {
            if(vm.search && vm.search.length > 0) {
                if(!vm.geocoder) {
                    vm.geocoder = new google.maps.Geocoder();
                }
                vm.geocoder.geocode({'address': vm.search}, function(results, status){
                    if (status === google.maps.GeocoderStatus.OK){
                        var loc = results[0].geometry.location;
                        vm.search = results[0].formatted_address;
                        if(vm.location) {
                            vm.location.title = results[0].formatted_address;
                        } else {
                            vm.location = {title: results[0].formatted_address};
                        }
                        vm.location.geoPoint.lat = Math.floor(loc.lat()*100000+0.5)/100000;
                        vm.location.geoPoint.lon = Math.floor(loc.lng()*100000+0.5)/100000;
                        vm.map.setCenter({lat: vm.location.geoPoint.lat, lng: vm.location.geoPoint.lon});
                        $scope.$apply();
                    }
                });
            }
        } // end of geoCode

        function getImageSrc(width, height) {
            var dimentions = '';

            if(width && height) {
                dimentions = '?w='+width+'&h='+height;
            }

            if (vm.event.thumbnail) {
                return 'https://streams2.winkball.com/images/'+vm.event.thumbnail+dimentions;
            }

            return '';
        }

        function deleteThumbnail() {
            vm.thumbnailsForDeletion.push(vm.event.thumbnail);
            vm.event.thumbnail = null;
        }

        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function uploadFile(file, errFiles) {
            $log.log('calling uploadFile...');

            vm.isSaving = true;
            vm.f = file;
            vm.errFile = errFiles && errFiles[0];

            if(file) {
                file.upload = Upload.upload(
                    {
                        url: "https://streams2.winkball.com/images",
                        data: {file: file},
                        headers: {
                            'Authorization': 'Bearer ' + vm.authToken
                        }
                    }
            ).then(function (response) {
                $timeout(function() {
                    file.result = response.data;
                    vm.isSaving = false;
                    if(response.status == "201") {
                        $log.log('Image upload status 201');
                        vm.event.thumbnail = response.data;
                    }
                }, 1000);
            }, function(response){
                $log.log('Error...');
                $log.debug(response);

                vm.isSaving = false;
                if(response.status > 0) {
                    vm.errorMsg = response.status +': ' + response.data;
                }
            }, function(evt) {
                file.progress = Math.min(100, parseInt(100.0 * evt.loaded/evt.total));
            });
            }
        }
    }
})();
