(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['Principal', 'User', 'UserSearch', 'ParseLinks', 'AlertService', '$state', 'pagingParams', 'paginationConstants', 'JhiLanguageService'];

    function UserManagementController(Principal, User, UserSearch, ParseLinks, AlertService, $state, pagingParams, paginationConstants, JhiLanguageService) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REPORTER', 'ROLE_SUPERVISOR', 'ROLE_CUSTOMER', 'ROLE_MANAGER', 'ROLE_TRANSCODER'];
        vm.currentAccount = null;
        vm.languages = null;
        vm.loadAll = loadAll;
        vm.setActive = setActive;
        vm.users = [];
        vm.page = 1;
        vm.totalItems = null;
        vm.clear = clear;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.search = search;

        vm.loadAll();

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });
        Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });

        function setActive (user, isActivated) {
            user.activated = isActivated;
            User.update(user, function () {
                vm.loadAll();
                vm.clear();
            });
        }

        function loadAll () {
          if (vm.currentSearch) {
            UserSearch.query({
              query: vm.currentSearch
            }, onSuccessSearch, onError);
          } else {
            User.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
          }
        }

        function onSuccessSearch(data) {
          //hide anonymous user from user management: it's a required user for Spring Security
          for (var i in data) {
              if (data[i]['login'] === 'anonymoususer') {
                  data.splice(i, 1);
              }
          }
          vm.links = null;
          vm.totalItems = data.length;
          vm.queryCount = vm.totalItems;
          vm.page = 1;
          vm.users = data;
        }

        function onSuccess(data, headers) {
            //hide anonymous user from user management: it's a required user for Spring Security
            for (var i in data) {
                if (data[i]['login'] === 'anonymoususer') {
                    data.splice(i, 1);
                }
            }
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.users = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function clear () {
          vm.currentSearch = null;
          vm.users = [];
          vm.links = null;
          vm.page = 1;
          vm.predicate = pagingParams.predicate;
          vm.reverse = pagingParams.ascending;
          vm.loadAll();
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.users = [];
            vm.links = null;
            vm.page = 1;
            vm.predicate = pagingParams.predicate;
            vm.reverse = pagingParams.ascending;
            vm.currentSearch = searchQuery;
            vm.loadAll();
        }
    }
})();
