(function() {
    'use strict';
    angular
        .module('winkballApp')
        .factory('Reporter', Reporter);

    Reporter.$inject = ['$resource', 'DateUtils'];

    function Reporter ($resource, DateUtils) {
        var resourceUrl =  'api/reporters/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateJoined = DateUtils.convertDateTimeFromServer(data.dateJoined);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getByLogin': {
              url: 'api/reporters/user/:login',
              method: 'GET',
              transformResponse: function (data) {
                  if (data) {
                      data = angular.fromJson(data);
                      data.dateJoined = DateUtils.convertDateTimeFromServer(data.dateJoined);
                  }
                  return data;
              }
            },
            'getWebLinks': { url: 'api/reporters/:id/web-links', method: 'GET', isArray: true }
        });
    }
})();
