(function() {
    'use strict';

    angular
        .module('winkballApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('project-detail', {
            parent: 'entity',
            url: '/project/{id}?filter',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'winkballApp.job.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/project/project-detail.html',
                    controller: 'ProjectDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('job');
                    $translatePartialLoader.addPart('sponsor');
                    $translatePartialLoader.addPart('location');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Job', function($stateParams, Job) {
                    return Job.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'home',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        }).state('project-detail.team', {
            parent: 'project-detail',
            url: '/team',
            data: {
                authorities: ['ROLE_REPORTER', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/project/project-team-dialog.html',
                    controller: 'ProjectTeamDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Job', function(Job) {
                            return Job.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true, location: 'replace' });
                }, function() {
                    $state.go('^', {}, { location: 'replace' });
                });
            }]
        }).state('project-detail.sponsor', {
            parent: 'project-detail',
            url: '/sponsor',
            data: {
                authorities: ['ROLE_REPORTER', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sponsor/sponsor-dialog.html',
                    controller: 'SponsorDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                sponsorName: null,
                                puid: null,
                                createdDate: null,
                                contactName: null,
                                contactEmail: null,
                                telephone: null,
                                finder: null,
                                job: { id : parseInt($stateParams.id) },
                                address: { id: null, country: 'GBR'},
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true, location: 'replace' });
                }, function() {
                    $state.go('^', {}, { location: 'replace' });
                });
            }]
        }).state('project-detail.location', {
            parent: 'project-detail',
            url: '/location/{locationId}',
            data: {
                authorities: ['ROLE_REPORTER', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/location/location-dialog.html',
                    controller: 'LocationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Location', function(Location) {
                            return Location.get({id : $stateParams.locationId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true, location: 'replace' });
                }, function() {
                    $state.go('^', {}, { location: 'replace' });
                });
            }]
        }).state('project-detail.first', {
            parent: 'project-detail',
            url: '/first',
            params: { project: null },
            data: {
                authorities: ['ROLE_REPORTER','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/project/project-wizard-first.html',
                    controller: 'FirstProjectWizardController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Job', function(Job) {
                            return Job.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('project-detail.second', { project: result }, { reload: false });
                }, function() {
                    $state.go('^', {}, { location: 'replace' });
                });
            }]
        }).state('project-detail.second', {
            parent: 'project-detail',
            url: '/second',
            params: { project: null },
            data: {
                authorities: ['ROLE_REPORTER','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/project/project-wizard-second.html',
                    controller: 'SecondProjectWizardController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function(result) {
                    $state.go('project-detail.last', { project: result }, { reload: false });
                }, function(result) {
                    if(result === 'cancel') {
                        $state.go('project-detail', { id: $stateParams.id});
                    } else {
                        $state.go('project-detail.first', { project: result }, { reload: false });
                    }
                });
            }]
        }).state('project-detail.last', {
            parent: 'project-detail',
            url: '/last',
            params: { project: null },
            data: {
                authorities: ['ROLE_REPORTER','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/project/project-wizard-last.html',
                    controller: 'ProjectWizardController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function(result) {
                    $state.go('project-detail', { id: $stateParams.id }, { reload: true });
                }, function(result) {
                    if(result === 'cancel') {
                        $state.go('project-detail', { id: $stateParams.id});
                    } else {
                        $state.go('project-detail.second', { project: result }, { reload: false });
                    }
                });
            }]
        }).state('project-inproduction', {
            parent: 'project-detail',
            url: '/inproduction',
            data: {
                authorities: ['ROLE_MANAGER', 'ROLE_SUPERVISOR', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/job/job-inproduction-dialog.html',
                    controller: 'JobInproductionController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Job', function (Job) {
                            return Job.get({
                                id: $stateParams.id
                            }).$promise;
                        }]
                    }
                }).result.then(function (result) {
                    $state.go('report-detail', { id: result.id }, {
                        reload: true
                    });
                }, function () {
                    $state.go('^');
                });
            }]
        }).state('project-detail.apply', {
            parent: 'project-detail',
            url: '/apply',
            data: {
                authorities: ['ROLE_REPORTER', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/job/job-apply-dialog.html',
                    controller: 'JobApplyController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['Job', function(Job) {
                            return Job.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true, location: 'replace' });
                }, function() {
                    $state.go('^', {}, { location: 'replace' });
                });
            }]
        });

    }

})();
