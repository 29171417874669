/* global google */
(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('LastReporttWizardController', LastReporttWizardController);

    LastReporttWizardController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$uibModalInstance', '$http', '$log', '$q', 'Event', 'Location', 'KeywordSearch', 'LocationSearch', 'NgMap'];

    function LastReporttWizardController ($timeout, $scope, $state, $stateParams, $uibModalInstance, $http, $log, $q, Event, Location, KeywordSearch, LocationSearch, NgMap) {
        var vm = this;
        // vm.event = entity;
        vm.event = $stateParams.report;

        vm.clear = clear;
        vm.thumbnailsForDeletion = [];
        vm.originalThumbnail =  angular.copy(vm.event.thumbnail);
        
        vm.save = save;
        vm.searchKeywords = searchKeywords;
        vm.searchLocations = searchLocations;
        vm.tagLocation = vm.event.location ? [vm.event.location] : [];
        vm.toggleCategory = toggleCategory;
        vm.saveLocation = saveLocation;
        vm.location = vm.event.location || {};
        vm.location.geoPoint = vm.location.geoPoint || {};
        vm.locationRemoved = locationRemoved;
        vm.locationAdded = locationAdded;
        vm.clearLocation = clearLocation;
        vm.addMarker = addMarker;
        vm.geoCode = geoCode;
        vm.search = "";

        // vm.multiSelectSettings = { externalIdProp: '', template: '<b>{{option.reporter.firstName}} {{option.reporter.lastName}} <small>({{option.reporterJobRole}})<smal></b>' };   
        // vm.selectReportersText = { buttonDefaultText: 'Select Reporters' };
        
        vm.getImageSrc = getImageSrc;
        vm.deleteThumbnail = deleteThumbnail;
        vm.authToken = angular.fromJson(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));

        vm.categories = Event.categories();

        $q.all([vm.event.$promise]).then(function() {
            if (!vm.event.location) {
                return $q.reject();
            }

            return Location.get({id : vm.event.location.id}).$promise;
        }).then(function(location) {
            vm.tagLocation = [location];
        });

        $timeout(function (){
            angular.element('.form-group:eq(2)>input').focus();
        });

        function searchKeywords(query) {
            return KeywordSearch.query({
                query: query,
                page: 0,
                size: 10
            }).$promise;
        }

        function searchLocations(query) {
            return LocationSearch.query({
                query: query,
                page: 0,
                size: 10
            }).$promise;
        }

        $scope.$on('mapInitialized', function(evt, map) {
            $log.log('map initialised');

            vm.map = map;
            google.maps.event.trigger(vm.map, 'resize');
            if(vm.location.geoPoint.lat && vm.location.geoPoint.lon) {
                map.setCenter({lat: vm.location.geoPoint.lat, lng: vm.location.geoPoint.lon});
            }
        });

        function toggleCategory(category) {
            var idx = vm.event.categories.indexOf(category);
            if(idx > -1) {
                vm.event.categories.splice(idx, 1);
            } else {
                vm.event.categories.push(category);
            }
        }

        function clear () {
            $log.log('calling clear method..');

            $log.log('originalThumbnail: ' + vm.originalThumbnail);

            vm.event.thumbnail = vm.originalThumbnail;

            var puids = vm.thumbnailsForDeletion.filter(function (value) {
                return vm.originalThumbnail !== value;
            });

            if(puids.length > 0) {
                $http.post(
              'https://streams2.winkball.com/delete-images',
              puids, {
                  headers:{
                      'Authorization': 'Bearer ' + vm.authToken
                  }}).then(
                function () {
                    $log.log('videos deleted');

                    vm.thumbnailsForDeletion = [];
                },
                function () {
                    $log.log('error deleting videos');
                }
            );
            }

            vm.tagLocation = [];
            vm.selectedApplicants = [];

            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            if(vm.thumbnailsForDeletion.length > 0) {
                $http.post(
              'https://streams2.winkball.com/delete-images',
              vm.thumbnailsForDeletion, {
                  headers:{
                      'Authorization': 'Bearer ' + vm.authToken
                  }}).then(
                function () {
                    vm.thumbnailsForDeletion = [];
                },
                function () {
                    $log.log('error deleting thumbnail');
                }
            );
            }

            if(vm.tagLocation.length > 0) {
                vm.event.location = vm.tagLocation[0];
            } else {
                vm.event.location = null;
            }

            if (vm.event.id !== null) {
                Event.update(vm.event, onSaveSuccess, onSaveError);
            } else {
                Event.save(vm.event, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('winkballApp:eventUpdate', result);
            vm.isSaving = false;
            vm.originalThumbnail = vm.event.thumbnail;

            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function saveLocation() {
            vm.isSaving = true;
            if (!vm.location.id) {
                Location.save(vm.location, onSaveLocationSuccess, onSaveError);
            }
        }

        function onSaveLocationSuccess(result) {
            vm.event.location = result;
            vm.tagLocation[0] = result;
            vm.isSaving = false;
        }

        function locationRemoved() {
            vm.location = {};
            vm.location.geoPoint = {};
        }

        function locationAdded() {
            vm.location = vm.tagLocation[0];
        }

        function clearLocation() {
            vm.location = {};
            vm.location.geoPoint = {};
            vm.event.location = {};
            vm.tagLocation = [];
        }

        function addMarker(event) {
            var ll = event.latLng;
          // round coordinates to 5 decimal places
            vm.location.geoPoint.lon =  Math.floor(ll.lng()*100000+0.5)/100000;
            vm.location.geoPoint.lat =  Math.floor(ll.lat()*100000+0.5)/100000;
        }

        function geoCode() {
            if(vm.search && vm.search.length > 0) {
                if(!vm.geocoder) {
                    vm.geocoder = new google.maps.Geocoder();
                }
                vm.geocoder.geocode({'address': vm.search}, function(results, status){
                    if (status === google.maps.GeocoderStatus.OK){
                        var loc = results[0].geometry.location;
                        vm.search = results[0].formatted_address;
                        if(vm.location) {
                            vm.location.title = results[0].formatted_address;
                        } else {
                            vm.location = {title: results[0].formatted_address};
                        }
                        vm.location.geoPoint.lat = Math.floor(loc.lat()*100000+0.5)/100000;
                        vm.location.geoPoint.lon = Math.floor(loc.lng()*100000+0.5)/100000;
                        vm.map.setCenter({lat: vm.location.geoPoint.lat, lng: vm.location.geoPoint.lon});
                        $scope.$apply();
                    }
                });
            }
        } // end of geoCode

        function getImageSrc(width, height) {
            var dimentions = '';

            if(width && height) {
                dimentions = '?w='+width+'&h='+height;
            }

            if (vm.event.thumbnail) {
                return 'https://streams2.winkball.com/images/'+vm.event.thumbnail+dimentions;
            }

            return '';
        }

        function deleteThumbnail() {
            vm.thumbnailsForDeletion.push(vm.event.thumbnail);
            vm.event.thumbnail = null;
        }
    }
})();
