(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('ReportFilmDetailController', ReportFilmDetailController);

    ReportFilmDetailController.$inject = ['$uibModalInstance', 'entity', 'Film'];

    function ReportFilmDetailController($uibModalInstance, entity, Film) {
        var vm = this;

        vm.film = entity;
        vm.clear = clear;
        vm.getImageSrc = getImageSrc;
        vm.getVideoSrc = getVideoSrc;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function getImageSrc(width, height) {
            var dimentions = '';

            if(width && height) {
                dimentions = '?w='+width+'&h='+height;
            }

            if (vm.film.thumbnail) {
                if (dimentions) {
                    return 'https://streams2.winkball.com/images/' + vm.film.thumbnail + dimentions;
                } else {
                    return 'https://streams2.winkball.com/images/' + vm.film.thumbnail + '?quality=ORIGINAL';

                }
            }

            return '';
        }

        function getVideoSrc(quality, format) {
            quality = quality || "HD_720P";
            format = format || "MP4";
  
            if (vm.film.videoFileGuid) {
                return 'https://streams2.winkball.com/videos/'+ vm.film.videoFileGuid +'?quality=' + quality + '&format=' + format;
            }
            return "";
        }
    }
})();
