(function() {
    'use strict';

    angular
        .module('winkballApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('video-file', {
            parent: 'entity',
            url: '/video-file',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'winkballApp.videoFile.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/video-file/video-files.html',
                    controller: 'VideoFileController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('videoFile');
                    $translatePartialLoader.addPart('videoStatus');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('video-file-detail', {
            parent: 'entity',
            url: '/video-file/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'winkballApp.videoFile.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/video-file/video-file-detail.html',
                    controller: 'VideoFileDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('videoFile');
                    $translatePartialLoader.addPart('videoStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'VideoFile', function($stateParams, VideoFile) {
                    return VideoFile.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'video-file',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('video-file-detail.edit', {
            parent: 'video-file-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/video-file/video-file-dialog.html',
                    controller: 'VideoFileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['VideoFile', function(VideoFile) {
                            return VideoFile.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('video-file.new', {
            parent: 'video-file',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/video-file/video-file-dialog.html',
                    controller: 'VideoFileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                guid: null,
                                puid: null,
                                status: null,
                                bitrate: null,
                                audiosamplerate: null,
                                filesize: null,
                                duration: null,
                                framerate: null,
                                width: null,
                                height: null,
                                codec: null,
                                audiocodec: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('video-file', null, { reload: true });
                }, function() {
                    $state.go('video-file');
                });
            }]
        })
        .state('video-file.edit', {
            parent: 'video-file',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/video-file/video-file-dialog.html',
                    controller: 'VideoFileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['VideoFile', function(VideoFile) {
                            return VideoFile.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('video-file', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('video-file.delete', {
            parent: 'video-file',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/video-file/video-file-delete-dialog.html',
                    controller: 'VideoFileDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['VideoFile', function(VideoFile) {
                            return VideoFile.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('video-file', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
