(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('VideoDialogController', VideoDialogController);

    VideoDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', '$log', '$q', '$http', 'entity', 'Video', 'VideoFile', 'Location', 'LocationSearch', 'KeywordSearch', 'Upload'];

    function VideoDialogController ($timeout, $scope, $uibModalInstance, $log, $q, $http, entity, Video, VideoFile, Location, LocationSearch, KeywordSearch, Upload) {
        var vm = this;

        vm.video = entity;
        vm.thumbnailsForDeletion = [];
        vm.originalThumbnail = vm.video.thumbnail;
        vm.tagLocation = [];
        vm.clear = clear;
        vm.save = save;
        vm.getVideoSrc = getVideoSrc;
        vm.getImageSrc = getImageSrc;
        vm.deleteThumbnail = deleteThumbnail;
        vm.uploadFile = uploadFile;
        vm.authToken = angular.fromJson(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));
        vm.videofiles = VideoFile.query({filter: 'video-is-null'});
        vm.categories = [
            'GENERIC',
            'CHANNEL_BRANDING',
            'TRANSITION',
            'STUDIO_WORK',
            'SPECIAL_PARTNER',
            'MEDIA_PARTNER',
            'CHANNEL_SPONSOR',
            'EXTERNAL_CONTENT',
            'TRAINING'
        ];
        vm.searchLocation = searchLocation;

        $q.all([vm.video.$promise, vm.videofiles.$promise]).then(function() {
            if (!vm.video.videoFileId) {
                return $q.reject();
            }
            return VideoFile.get({id : vm.video.videoFileId}).$promise;
        }).then(function(videoFile) {
            vm.videofiles.push(videoFile);
        });

        $q.all([vm.video.$promise]).then(function() {
            if (!vm.video.locationId) {
                return $q.reject();
            }
            return Location.get({id : vm.video.locationId}).$promise;
        }).then(function(location) {
            vm.tagLocation = [location];
        });
        
        vm.loadKeywords = loadKeywords;

        function loadKeywords(query) {
            return KeywordSearch.query({
                query: query,
                page: 0,
                size: 10
            }).$promise;
        }

        function searchLocation(query) {
            return LocationSearch.query({
                query: query,
                page: 0,
                size: 10
            }).$promise;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            vm.video.thumbnail = vm.originalThumbnail;

            var puids = vm.thumbnailsForDeletion.filter(function (value) {
                return vm.originalThumbnail !== value;
            });

            if(puids.length > 0) {
                $http.post(
              'https://streams2.winkball.com/delete-images',
              puids, {
                  headers:{
                      'Authorization': 'Bearer ' + vm.authToken
                  }}).then(
                function () {
                    vm.thumbnailsForDeletion = [];
                },
                function () {
                    $log.error('error deleting images');
                }
            );
            }

            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            if(vm.thumbnailsForDeletion.length > 0) {
                $http.post(
                'https://streams2.winkball.com/delete-images',
                vm.thumbnailsForDeletion, {
                    headers:{
                        'Authorization': 'Bearer ' + vm.authToken
                    }}).then(
                  function () {
                      vm.thumbnailsForDeletion = [];
                  },
                  function () {
                      $log.error('error deleting thumbnail');
                  }
              );
            }

            if(vm.tagLocation.length > 0) {
                vm.video.locationId = vm.tagLocation[0].id;
            } else {
                vm.video.locationId = null;
            }

            if (vm.video.id !== null) {
                Video.update(vm.video, onSaveSuccess, onSaveError);
            } else {
                Video.save(vm.video, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('winkballApp:videoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            vm.originalThumbnail = vm.video.thumbnail;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function deleteThumbnail() {
            vm.thumbnailsForDeletion.push(vm.video.thumbnail);
            vm.video.thumbnail = null;
        }

        function getVideoSrc(quality, format) {
            quality = quality || "ORIGINAL";
            format = format || "MP4";

            if (vm.video.videoFileGuid) {
                return 'https://streams2.winkball.com/videos/'+vm.video.videoFileGuid+'?quality='+quality+'&format='+format;
            }
            return "";
        }

        function getImageSrc(width, height) {
            var dimentions = '';

            if(width && height) {
                dimentions = '?w='+width+'&h='+height;
            }

            if (vm.video.thumbnail) {
                return 'https://streams2.winkball.com/images/'+vm.video.thumbnail+dimentions;
            }

            return '';
        }

        function uploadFile(file, errFiles, image) {
            vm.isSaving = true;
            vm.f = file;
            vm.videofile = !image;
            vm.errFile = errFiles && errFiles[0];

            if (file) {
                var uploadURL = "https://streams2.winkball.com/videos";

                if(image) {
                    uploadURL = "https://streams2.winkball.com/images";
                }
                file.upload = Upload.upload(
                    {
                        url: uploadURL,
                        data: { file: file },
                        headers: {
                            'Authorization': 'Bearer ' + vm.authToken
                        }
                    }
              ).then(function (response) {
                  $timeout(function () {
                      file.result = response.data;
                      vm.isSaving = false;
                      if (response.status == '201') {
                          $log.log('Image upload status 201');
                          if(image) {
                              vm.video.thumbnail = response.data;
                          } else {
                              vm.video.videoFileGuid = response.data;
                              vm.video.videoFileName = file.name;
                          }
                      }
                  }, 1000);
              }, function (response) {
                  $log.log('Error...');
                  $log.debug(response);
                  vm.isSaving = false;
                  if (response.status > 0) {
                      vm.errorMsg = response.status + ': ' + response.data;
                  }
              }, function (evt) {
                  file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
              });
            }
        } // end of uploadFile
    }
})();
