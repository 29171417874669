(function () {
    'use strict';

    angular
        .module('winkballApp')
        .controller('ProspectiveReporterDetailController', ProspectiveReporterDetailController);

    ProspectiveReporterDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ProspectiveReporter'];

    function ProspectiveReporterDetailController($scope, $rootScope, $stateParams, previousState, entity, ProspectiveReporter) {
        var vm = this;

        vm.reporter = entity;
        vm.previousState = previousState.name;
        vm.sendInvite = sendInvite;
        vm.getImageSrc = getImageSrc;

        function sendInvite() {
            ProspectiveReporter.invite(vm.reporter);
        }

        function getImageSrc(width, height) {
            var dimentions = '';

            if (width && height) {
                dimentions = '?w=' + width + '&h=' + height;
            }

            if (vm.reporter.profilePicture) {
                if(dimentions) {
                    return 'https://streams2.winkball.com/images/' + vm.reporter.profilePicture + dimentions;
                } else {
                    return 'https://streams2.winkball.com/images/' + vm.reporter.profilePicture + '?quality=ORIGINAL';
                }
                
            }

            return '';
        }

        var unsubscribe = $rootScope.$on('winkballApp:prospectiveReporterUpdate', function (event, result) {
            vm.reporter = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
