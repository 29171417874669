(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('MediaPackageDeleteController',MediaPackageDeleteController);

    MediaPackageDeleteController.$inject = ['$uibModalInstance', 'entity', 'MediaPackage'];

    function MediaPackageDeleteController($uibModalInstance, entity, MediaPackage) {
        var vm = this;

        vm.mediaPackage = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            MediaPackage.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
