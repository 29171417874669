(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('SponsorDialogController', SponsorDialogController);

    SponsorDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$q', '$uibModalInstance', 'entity', 'Principal', 'Sponsor', 'User', 'Job', 'PostalAddress', 'Event'];

    function SponsorDialogController ($timeout, $scope, $stateParams, $q, $uibModalInstance, entity, Principal, Sponsor, User, Job, PostalAddress, Event) {
        var vm = this;

        vm.sponsor = entity;
        vm.clear = clear;
        vm.save = save;
        vm.users = [];
        vm.address = "";
        vm.search = "";

        vm.jobs = Job.query({
            page: 0,
            size: 40,
            sort: "createdDate,desc"});

        vm.account = Principal.identity();

      // add current user as a finder for new sponsor
        vm.account.then(function(account){
            $q.all([vm.sponsor.$promise]).then(function() {
                if(vm.sponsor.id) {
                    return $q.reject();
                }

                return User.get({login:account.login}).$promise;
            }).then(function(user) {
                vm.sponsor.finder = user;
                vm.users.push(user);

                if(user.login !== 'winkball') {
                    var winkballUser = User.get({login:'winkball'}).$promise;
                    winkballUser.then(function (wbUser) {
                        vm.users.push(wbUser);
                    }, function (reason){
                        console.log(reason);
                    });
                }
            });
        });

        $q.all([vm.sponsor.$promise, vm.jobs.$promise]).then(function() {
            if(!vm.sponsor.job) {
                return $q.reject();
            }

            return Job.get({id : vm.sponsor.job.id}).$promise;
        }).then(function(job) {
            vm.sponsor.job = job;
            vm.jobs.push(job);
            if(!vm.sponsor.id) {
                // copy project price into sponsorship ammount
                vm.sponsor.sponsorshipAmount = job.projectPrice;
            }
        });

        $q.all([vm.sponsor.$promise, vm.jobs.$promise]).then(function() {
            if(vm.sponsor.id) {
                return $q.reject();
            }

            if(!$stateParams.job) {
                return $q.reject();
            }
            return Job.get({id : $stateParams.job}).$promise;
        }).then(function(job) {
            // set job if exists
            vm.sponsor.job = job;

            vm.jobs.push(job);

            // copy project price into sponsorship ammount
            vm.sponsor.sponsorshipAmount = job.projectPrice;
        });
        
        // required when adding sponsor on report/event page
        $q.all([vm.sponsor.$promise, vm.jobs.$promise]).then(function() {
            if(vm.sponsor.id && !$stateParams.type) {
                return $q.reject();
            }

            if($stateParams.type !== 'event') {
                return $q.reject();
            }

            return Event.get({id : $stateParams.id}).$promise;
        }).then(function(event) {
            // set job if exists
            vm.sponsor.job = event.job;

            vm.jobs.push(event.job);

            // copy project price into sponsorship ammount
            vm.sponsor.sponsorshipAmount = event.job.projectPrice;
        });

        $q.all([vm.sponsor.$promise]).then(function() {
            if(!vm.sponsor.finder) {
                return $q.reject();
            }
            vm.users.push(vm.sponsor.finder);

            if(vm.account.login !== 'winkball') {
                var winkballUser = User.get({login:'winkball'}).$promise;
                winkballUser.then(function (wbUser) {
                    vm.users.push(wbUser);
                }, function (reason){
                    console.log(reason);
                });
            }
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.sponsor.address.id !== null) {
                PostalAddress.update(vm.sponsor.address, saveSponsor, onSaveError);
            } else {
                PostalAddress.save(vm.sponsor.address, saveSponsor, onSaveError);
            }
        }

        function saveSponsor(address) {
            vm.sponsor.address = address;
            if (vm.sponsor.id !== null) {
                Sponsor.update(vm.sponsor, onSaveSuccess, onSaveError);
            } else {
                Sponsor.save(vm.sponsor, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('winkballApp:sponsorUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
