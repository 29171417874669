(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('ImageDetailController', ImageDetailController);

    ImageDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Image'];

    function ImageDetailController($scope, $rootScope, $stateParams, previousState, entity, Image) {
        var vm = this;

        vm.image = entity;
        vm.previousState = previousState.name;
        vm.getImageSrc = getImageSrc;

        function getImageSrc(width, height) {
            var dimentions = '';

            if(width && height) {
                dimentions = '?w='+width+'&h='+height;
            }

            if (vm.image.puid) {
                return 'https://streams2.winkball.com/images/'+ vm.image.puid + dimentions;
            }

            return '';
        }

        var unsubscribe = $rootScope.$on('winkballApp:imageUpdate', function(event, result) {
            vm.image = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
