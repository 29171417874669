(function () {
    'use strict';

    angular
        .module('winkballApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('prospective-reporter', {
                parent: 'entity',
                url: '/prospective-reporter',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'winkballApp.prospectiveReporter.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/prospective-reporter/prospective-reporters.html',
                        controller: 'ProspectiveReporterController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('prospectiveReporter');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('prospective-reporter-detail', {
                parent: 'entity',
                url: '/prospective-reporter/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'winkballApp.prospectiveReporter.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/prospective-reporter/prospective-reporter-detail.html',
                        controller: 'ProspectiveReporterDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('prospectiveReporter');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ProspectiveReporter', function ($stateParams, ProspectiveReporter) {
                        return ProspectiveReporter.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'prospective-reporter',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('prospective-reporter-detail.edit', {
                parent: 'prospective-reporter-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/prospective-reporter/prospective-reporter-dialog.html',
                        controller: 'ProspectiveReporterDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ProspectiveReporter', function (ProspectiveReporter) {
                                return ProspectiveReporter.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, { reload: false });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('prospective-reporter.new', {
                parent: 'prospective-reporter',
                url: '/new',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/prospective-reporter/prospective-reporter-dialog.html',
                        controller: 'ProspectiveReporterDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    puid: null,
                                    companyName: null,
                                    companyType: null,
                                    profilePicture: null,
                                    id: null,
                                    address: { id: null, country: 'GBR'},
                                    shippingAddress: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('prospective-reporter', null, { reload: true });
                    }, function () {
                        $state.go('prospective-reporter');
                    });
                }]
            })
            .state('prospective-reporter.edit', {
                parent: 'prospective-reporter',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/prospective-reporter/prospective-reporter-dialog.html',
                        controller: 'ProspectiveReporterDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ProspectiveReporter', function (ProspectiveReporter) {
                                return ProspectiveReporter.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('prospective-reporter', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('prospective-reporter.delete', {
                parent: 'prospective-reporter',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/prospective-reporter/prospective-reporter-delete-dialog.html',
                        controller: 'ProspectiveReporterDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['ProspectiveReporter', function (ProspectiveReporter) {
                                return ProspectiveReporter.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('prospective-reporter', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('prospective-reporter-detail.email', {
                parent: 'prospective-reporter-detail',
                url: '/detail/email?accepted',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/prospective-reporter/prospective-reporter-email-dialog.html',
                        controller: 'ProspectiveReporterEmailController',
                        controllerAs: 'vm',
                        size: 'md',
                        backdrop: 'static',
                        resolve: {
                            entity: ['ProspectiveReporter', function (ProspectiveReporter) {
                                return ProspectiveReporter.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, { reload: true, location: 'replace' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
