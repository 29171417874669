(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('PostalAddressDetailController', PostalAddressDetailController);

    PostalAddressDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PostalAddress'];

    function PostalAddressDetailController($scope, $rootScope, $stateParams, previousState, entity, PostalAddress) {
        var vm = this;

        vm.postalAddress = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('winkballApp:postalAddressUpdate', function(event, result) {
            vm.postalAddress = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
