/* global google */
(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('ProjectWizardController', ProjectWizardController);

    ProjectWizardController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', '$http', '$log', 
        'Job', 'LocationSearch', 'Principal', 'User', 'Location', 'NgMap', 'Upload'
    ];

    function ProjectWizardController($timeout, $scope, $stateParams, $uibModalInstance, $q, $http, $log,
        Job, LocationSearch, Principal, User, Location, NgMap, Upload) {

        var vm = this;

        vm.job = $stateParams.project;
        vm.clear = clear;
        vm.back = back;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.searchLocation = searchLocation;
        vm.tagLocation = [];
        vm.addMarker = addMarker;
        vm.geoCode = geoCode;
        vm.search = "";
        vm.saveLocation = saveLocation;
        vm.location = vm.job.location || {};
        vm.location.geoPoint = vm.location.geoPoint || {};
        vm.locationRemoved = locationRemoved;
        vm.locationAdded = locationAdded;
        vm.clearLocation = clearLocation;
        vm.users = [];
        vm.phoneRegex = /^[\+|\d]{1}[0-9|\s]{3,20}$/;
        vm.toggleCategory = toggleCategory;
        vm.thumbnailsForDeletion = [];
        vm.originalThumbnail = angular.copy(vm.job.thumbnail);
        vm.datePickerOptions = {
            minDate: new Date(),
            initDate: new Date()
        };

        vm.categories = Job.categories();
        vm.account = Principal.identity();

        vm.getImageSrc = getImageSrc;
        vm.deleteThumbnail = deleteThumbnail;
        vm.uploadFile = uploadFile;

        vm.authToken = angular.fromJson(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));

        $timeout(function (){
            angular.element('.form-group:eq(2)>input').focus();
        });

        // add current user as a manager for new jobs
        vm.account.then(function(account){
            $q.all([vm.job.$promise]).then(function() {
                if(vm.job.id) {
                    return $q.reject();
                }

                return User.get({login:account.login}).$promise;
            }).then(function(user) {
                vm.job.manager = user;
                vm.users.push(user);

                if(user.login !== 'winkball') {
                    var winkballUser = User.get({login:'winkball'}).$promise;
                    winkballUser.then(function (wbUser) {
                        vm.users.push(wbUser);
                    }, function (reason){
                        $log.log(reason);
                    });
                }
            });
        });

        // create a list of reporters from applicants
        $q.all([vm.job.$promise]).then(function() {
            if (!vm.job.id) {
                return $q.reject();
            }

            // set date picker min date depending on the start date
            // for editing of an existing Job
            vm.datePickerOptions.minDate = vm.job.startDate;

            return Job.reporters({id : vm.job.id}).$promise;
        }).then(function(reporters) {

            // unwraps reporter and removes duplicate based on id
            vm.users = reporters
                .map(function(resource){
                    return resource.reporter;})
                    .filter(function(reporter, pos, arr) { 
                        return arr.map(function(user){
                            return user.id;
                        }).indexOf(reporter.id) === pos;
                    });

            if(vm.job.manager) {
                vm.users.push(vm.job.manager);
            }
        });

        $q.all([vm.job.$promise]).then(function() {
            if (!vm.job.location) {
                return $q.reject();
            }

            return Location.get({id : vm.job.location.id}).$promise;
        }).then(function(location) {
            vm.tagLocation = [location];
        });

        $scope.$on('mapInitialized', function(evt, map) {
            $log.log('map initialised');
            vm.map = map;
            google.maps.event.trigger(vm.map, 'resize');
            if(vm.location.geoPoint.lat && vm.location.geoPoint.lon) {
                map.setCenter({lat: vm.location.geoPoint.lat, lng: vm.location.geoPoint.lon});
            }
        });

        function searchLocation(query) {
            return LocationSearch.query({
                query: query,
                page: 0,
                size: 10
            }).$promise;
        }

        function toggleCategory(category) {
            var idx = vm.job.categories.indexOf(category);
            if(idx > -1) {
                vm.job.categories.splice(idx, 1);
            } else {
                vm.job.categories.push(category);
            }
        }

        function back() {
            
            if(vm.tagLocation.length > 0) {
                vm.job.location = vm.tagLocation[0];
            }

            $uibModalInstance.dismiss(vm.job);
        }

        function clear () {
            vm.job.thumbnail = vm.originalThumbnail;

            var puids = vm.thumbnailsForDeletion.filter(function (value) {
                return vm.originalThumbnail !== value;
            });

            if(puids.length > 0) {
                $http.post(
              'https://streams2.winkball.com/delete-images',
              puids, {
                  headers:{
                      'Authorization': 'Bearer ' + vm.authToken
                  }}).then(
                function () {
                    vm.thumbnailsForDeletion = [];
                },
                function () {
                    $log.log('error deleting videos');
                }
            );
            }

            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            if(vm.thumbnailsForDeletion.length > 0) {
                $http.post(
              'https://streams2.winkball.com/delete-images',
              vm.thumbnailsForDeletion, {
                  headers:{
                      'Authorization': 'Bearer ' + vm.authToken
                  }}).then(
                function () {
                    vm.thumbnailsForDeletion = [];
                },
                function () {
                    $log.log('error deleting thumbnail');
                }
            );
            }

            if(vm.tagLocation.length > 0) {
                vm.job.location = vm.tagLocation[0];
            } else {
                vm.job.location = null;
            }

            if (vm.job.id !== null) {
                Job.update(vm.job, onSaveSuccess, onSaveError);
            } else {
                Job.save(vm.job, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.originalThumbnail = vm.job.thumbnail;
            $scope.$emit('winkballApp:jobUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function saveLocation() {
            vm.isSaving = true;
            if (!vm.location.id) {
                Location.save(vm.location, onSaveLocationSuccess, onSaveError);
            }
        }

        function onSaveLocationSuccess(result) {
            vm.job.location = result;
            vm.tagLocation[0] = result;
            vm.isSaving = false;
        }

        function clearLocation() {
            vm.location = {};
            vm.location.geoPoint = {};
            vm.job.location = {};
            vm.tagLocation = [];
        }

        function locationRemoved() {
            vm.location = {};
            vm.location.geoPoint = {};
        }

        function locationAdded() {
            vm.location = vm.tagLocation[0];
        }

        function getImageSrc(width, height) {
            var dimentions;

            if(width && height) {
                dimentions = '?w='+width+'&h='+height;
            }

            if (vm.job.thumbnail) {
                if (dimentions) {
                    return 'https://streams2.winkball.com/images/' + vm.job.thumbnail + dimentions;
                } else {
                    return 'https://streams2.winkball.com/images/' + vm.job.thumbnail + '?quality=ORIGINAL';
                }
            }

            return '';
        }

        function deleteThumbnail() {
            vm.thumbnailsForDeletion.push(vm.job.thumbnail);
            vm.job.thumbnail = null;
        }

        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.reportDueDate = false;


        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function addMarker(event) {
            var ll = event.latLng;
          // round coordinates to 5 decimal places
            vm.location.geoPoint.lon =  Math.floor(ll.lng()*100000+0.5)/100000;
            vm.location.geoPoint.lat =  Math.floor(ll.lat()*100000+0.5)/100000;
        }

        function geoCode() {
            if(vm.search && vm.search.length > 0) {
                if(!vm.geocoder) {
                    vm.geocoder = new google.maps.Geocoder();
                }
                vm.geocoder.geocode({'address': vm.search}, function(results, status){
                    if (status === google.maps.GeocoderStatus.OK) {
                        var loc = results[0].geometry.location;
                        $log.log('setting up vm.search');
                        vm.search = results[0].formatted_address;
                        if(vm.location) {
                            $log.log('location exist, setting title');
                            vm.location.title = results[0].formatted_address;
                        } else {
                            $log.log('location does not exist, setting title');
                            vm.location = {title: results[0].formatted_address};
                        }
                        vm.location.geoPoint.lat = Math.floor(loc.lat()*100000+0.5)/100000;
                        vm.location.geoPoint.lon = Math.floor(loc.lng()*100000+0.5)/100000;
                        vm.map.setCenter({lat: vm.location.geoPoint.lat, lng: vm.location.geoPoint.lon});
                        $scope.$apply();
                    }
                });
            }
        } // end of geoCode

        function uploadFile(file, errFiles) {
            $log.log('calling uploadFile...');
            vm.isSaving = true;
            vm.f = file;
            vm.errFile = errFiles && errFiles[0];

            if(file) {
                file.upload = Upload.upload(
                    {
                        url: "https://streams2.winkball.com/images",
                        data: {file: file},
                        headers: {
                            'Authorization': 'Bearer ' + vm.authToken
                        }
                    }
            ).then(function (response) {
                $timeout(function() {
                    file.result = response.data;
                    vm.isSaving = false;
                    if(response.status == "201") {
                        $log.log('Image upload status 201');
                        vm.job.thumbnail = response.data;
                    }
                }, 1000);
            }, function(response){
                $log.log('Error...');
                $log.debug(response);
                vm.isSaving = false;
                if(response.status > 0) {
                    vm.errorMsg = response.status +': ' + response.data;
                }
            }, function(evt) {
                file.progress = Math.min(100, parseInt(100.0 * evt.loaded/evt.total));
            });
            }
        } // end of uploadFile
    }
})();
