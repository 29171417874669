(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('EventEmailProspectivePublisherController', EventEmailProspectivePublisherController);

    EventEmailProspectivePublisherController.$inject = ['$window','$uibModalInstance', '$q', '$http', '$log', 'entity', 'ProspectivePublisher', 'ProspectivePublisherSearch', 'ParseLinks', 'AlertService'];

    function EventEmailProspectivePublisherController($window, $uibModalInstance, $q, $http, $log, entity, ProspectivePublisher, ProspectivePublisherSearch, ParseLinks, AlertService) {
        var vm = this;

        vm.event = entity;
        vm.clear = clear;
        vm.email = {
            toAll: false,
            to: 'editorial@winkball.com', // ignored
            publisherId: 0, // ignored
            subject: vm.event.title // set subject to report title
        };
        vm.sendEmail = sendEmail;
        vm.contacts = [];
        vm.publishers = [];
        vm.page = 0;        
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reverse = true;
        vm.authToken = angular.fromJson(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));
        vm.toggleContact = toggleContact;
        vm.loadAll = loadAll;
        vm.reset = reset;
        vm.clearPublishers = clearPublishers;
        vm.loadPage = loadPage;
        vm.search = search;

        loadAll();

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function sendEmail() {
            $log.log('sendEmail called');
            vm.email.eventId = vm.event.id;

            if (vm.email.toAll) {
                vm.contacts = [];
                $http.post(
                    '/api/mail/report/prospective-publishers',
                    vm.email, 
                    {
                        headers:{
                            'Authorization': 'Bearer ' + vm.authToken
                        }
                    }).then(
                      function () {
                          $log.log('email sent');
                          $uibModalInstance.close(vm.event);
                      },
                      function (error) {
                          $log.error('error sending emails');
                          $log.error(error);
                          AlertService.error(error.data.message);
                      }
                  );
            } else {
                vm.contacts.forEach(function (publisher) {
                    $log.log(publisher);
                    $http.post(
                        '/api/mail/report/prospective-publishers',
                        { 
                            to: publisher.email, 
                            subject: vm.email.subject, 
                            message: vm.email.message, 
                            greetings:  vm.email.greetings, 
                            eventId: vm.event.id,
                            publisherId: publisher.id
                        }, 
                        {
                            headers:{
                                'Authorization': 'Bearer ' + vm.authToken
                            }
                        }).then(
                          function () {
                              $log.log('email sent');
                          },
                          function (error) {
                              $log.error('error sending emails');
                              $log.error(error);
                              AlertService.error(error.data.message);
                          }
                      );
                });
                $uibModalInstance.close(vm.event);
            }
        }

        function toggleContact(contact) {
            var idx = vm.contacts.indexOf(contact);
            if(idx > -1) {
                vm.contacts.splice(idx, 1);
            } else {
                vm.contacts.push(contact);
            }
        }

        function loadAll() {
            if (vm.currentSearch) {
                ProspectivePublisherSearch.query({
                    query: vm.currentSearch,
                    page: vm.page,
                    size: 10,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                ProspectivePublisher.query({
                    page: vm.page,
                    size: 10,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.publishers.push(data[i]);
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset() {
            vm.page = 0;
            vm.publishers = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function clearPublishers() {
            vm.publishers = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = null;
            vm.currentSearch = null;
            vm.loadAll();
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.publishers = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.loadAll();
        }
    }
})();
