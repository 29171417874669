(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('MediaPackageDialogController', MediaPackageDialogController);

    MediaPackageDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', '$http','$localStorage', '$sessionStorage', 'entity', 'Event', 'Upload', 'MediaPackage', 'VideoFile'];

    function MediaPackageDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, $http, $localStorage, $sessionStorage, entity, Event, Upload, MediaPackage, VideoFile) {
        var vm = this;

        vm.mediaPackage = entity;
        vm.filesForDeletion = [];
        vm.logosForDeletion = [];
        vm.originalFile = vm.mediaPackage.puid;

        if(!vm.mediaPackage.sponsorDetails) {
            vm.mediaPackage.sponsorDetails = {};
        }

        vm.originalLogo = vm.mediaPackage.sponsorDetails.logo;
        vm.originalPoster = vm.mediaPackage.poster;

        vm.clear = clear;
        vm.save = save;
        vm.getFileSrc = getFileSrc;
        vm.getImageSrc = getImageSrc;
        vm.getVideoSrc = getVideoSrc;
        vm.deleteFile = deleteFile;
        vm.deleteLogo = deleteLogo;
        vm.deletePoster = deletePoster;
        vm.deleteVideo = deleteVideo;
        vm.authToken = JSON.parse(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));

        vm.events = Event.query({
            page: 0,
            size: 50,
            sort: ["startDate,desc"],
            filter: 'media-package-is-null'});

          // if (vm.mediaPackage.eventId) {
          //   vm.events.push(Event.get({id : vm.mediaPackage.eventId}));
          // }

        $q.all([vm.mediaPackage.$promise, vm.events.$promise]).then(function() {
            if (!vm.mediaPackage.eventId) {
                return $q.reject();
            }
            return Event.get({id : vm.mediaPackage.eventId}).$promise;
        }).then(function(item) {
            vm.events.push(item);
        });

        $q.all([vm.mediaPackage.$promise]).then(function() {
            if(vm.mediaPackage.id) {
                return $q.reject();
            }

            if(!$stateParams.event) {
                return $q.reject();
            }
            return Event.get({id : $stateParams.event}).$promise;
        }).then(function(item) {
            vm.events.push(item);
            vm.mediaPackage.eventId = item.id;
        });

        $timeout(function (){
            angular.element('.form-group:eq(2)>input').focus();
        });

        function clear () {
            // console.log('calling clear method..');
            // console.log('originalFile: ' + vm.originalFile);
            // console.log('originalLogo: ' + vm.originalLogo);

            vm.mediaPackage.puid = vm.originalFile;


            var filePuids = vm.filesForDeletion.filter(function (value) {
                return vm.originalFile != value;
            });

            if(filePuids.length > 0) {
                $http.post(
              'https://streams2.winkball.com/delete-files',
              filePuids, {
                  headers:{
                      'Authorization': 'Bearer ' + vm.authToken
                  }}).then(
                function () {
                    vm.filesForDeletion = [];
                },
                function () {
                    console.log('error deleting files');
                }
            );
            }

            vm.mediaPackage.sponsorDetails.logo = vm.originalLogo;

            var logoPuids = vm.logosForDeletion.filter(function (value) {
                return vm.originalLogo != value || vm.originalPoster != value;
            });

            if(logoPuids.length > 0) {
                $http.post(
              'https://streams2.winkball.com/delete-images',
              logoPuids, {
                  headers:{
                      'Authorization': 'Bearer ' + vm.authToken
                  }}).then(
                function () {
                    vm.logosForDeletion = [];
                },
                function () {
                    console.log('error deleting images');
                }
            );
            }

            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            if(vm.filesForDeletion.length > 0) {
                $http.post(
                'https://streams2.winkball.com/delete-files',
                vm.filesForDeletion, {
                    headers:{
                        'Authorization': 'Bearer ' + vm.authToken
                    }}).then(
                  function () {
                      vm.filesForDeletion = [];
                  },
                  function () {
                    console.log('error deleting files');
                  }
              );
            }

            if(vm.logosForDeletion.length > 0) {
                $http.post(
                'https://streams2.winkball.com/delete-images',
                vm.logosForDeletion, {
                    headers:{
                        'Authorization': 'Bearer ' + vm.authToken
                    }}).then(
                  function () {
                      vm.logosForDeletion = [];
                  },
                  function () {
                    console.log('error deleting logos');
                  }
              );
            }

            if (vm.mediaPackage.id !== null) {
                MediaPackage.update(vm.mediaPackage, onSaveSuccess, onSaveError);
            } else {
                MediaPackage.save(vm.mediaPackage, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('winkballApp:mediaPackageUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            vm.originalFile = vm.mediaPackage.puid;
            vm.originalLogo = vm.mediaPackage.sponsorDetails.logo;
            vm.originalPoster = vm.mediaPackage.poster;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function deleteFile() {
            vm.filesForDeletion.push(vm.mediaPackage.puid);
            vm.mediaPackage.puid = null;
        }

        function deleteLogo() {
            vm.logosForDeletion.push(vm.mediaPackage.sponsorDetails.logo);
            vm.mediaPackage.sponsorDetails.logo = null;
        }

        function deletePoster() {
            vm.logosForDeletion.push(vm.mediaPackage.poster);
            vm.mediaPackage.poster = null;
        }

        function deleteVideo() {
          // TODO - delete actual file on streams2
            vm.mediaPackage.videoFileId = null;
            vm.mediaPackage.videoFileGuid = null;
        }

        function getFileSrc() {
            if (vm.mediaPackage.puid) {
                return 'https://streams2.winkball.com/files/'+vm.mediaPackage.puid+'/download';
            }

            return '';
        }

        function getVideoSrc(quality, format) {
            quality = quality || "ORIGINAL";
            format = format || "MP4";

            if (vm.mediaPackage.videoFileGuid) {
                return 'https://streams2.winkball.com/videos/'+vm.mediaPackage.videoFileGuid+'?quality='+quality+'&format='+format;
            }
            return "";
        }

        function getImageSrc(puid) {

            if (puid) {
                return 'https://streams2.winkball.com/images/'+puid+'?quality=original';
            }

            return '';
        }

        $scope.uploadFile = function(file, errFiles, image, poster, video){
            vm.isSaving = true;
            $scope.f = file;
            $scope.image = image;
            $scope.poster = poster;
            $scope.video = video;
            $scope.errFile = errFiles && errFiles[0];

            if(file) {
                var uploadURL = "https://streams2.winkball.com/files";

                if(image || poster) {
                    uploadURL = "https://streams2.winkball.com/images";
                }

                if(video) {
                    uploadURL = "https://streams2.winkball.com/videos";
                }

                file.upload = Upload.upload(
                    {
                        url: uploadURL,
                        data: {file: file},
                        headers: {'Authorization': 'Bearer ' + vm.authToken}
                    }
            );
                file.upload.then(function (response) {
                    $timeout(function() {
                        file.result = response.data;
                        vm.isSaving = false;

                        if(response.status == "201") {
                            if(image) {
                                vm.mediaPackage.sponsorDetails.logo = response.data;
                            } else if(poster){
                                vm.mediaPackage.poster = response.data;
                            } else if (video) {
                                vm.mediaPackage.videoFileGuid = response.data;
                                vm.mediaPackage.videoFileName = file.name;
                            } else {
                                vm.mediaPackage.puid = response.data;
                            }
                        }
                    });
                }, function(response){
                    console.log('Error...');
                    vm.isSaving = false;
                    if(response.status > 0) {
                        $scope.errorMsg = response.status +': ' + response.data;
                    }
                }, function(evt) {
                    file.progress = Math.min(100, parseInt(100.0 * evt.loaded/evt.total));
                });
            }
        };
    }
})();
