(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('SponsorInvoiceDialogController', SponsorInvoiceDialogController);

    SponsorInvoiceDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$q', '$uibModalInstance', 'entity', 'Sponsor', 'SponsorInvoice'];

    function SponsorInvoiceDialogController($timeout, $scope, $stateParams, $q, $uibModalInstance, entity, Sponsor, SponsorInvoice) {
      var vm = this;

      vm.sponsorInvoice = entity;
      vm.clear = clear;
      vm.save = save;
      vm.datePickerOpenStatus = {};
      vm.openCalendar = openCalendar;

      vm.sponsors = Sponsor.query({
        page: 0,
        size: 40,
        sort: "createdDate,desc"});

      $q.all([vm.sponsorInvoice.$promise]).then(function() {
        if(!vm.sponsorInvoice.id) {
          return $q.reject();
        }
        return Sponsor.get({id : vm.sponsorInvoice.id}).$promise;
      }).then(function(sponsor) {
        vm.sponsors.push(sponsor);
        vm.sponsorInvoice.sponsor = sponsor;
      });

      $q.all([vm.sponsorInvoice.$promise]).then(function() {
        if(vm.sponsorInvoice.id) {
          return $q.reject();
        }

        if(!$stateParams.sponsor) {
          return $q.reject();
        }
          return Sponsor.get({id : $stateParams.sponsor}).$promise;
      }).then(function(sponsor) {
        // set job if exists
        vm.sponsorInvoice.sponsor = sponsor;
        // copy project price into sponsorship ammount
        vm.sponsorInvoice.amount = sponsor.sponsorshipAmount;
      });

      $timeout(function (){
        angular.element('.form-group:eq(1)>input').focus();
      });

      function clear () {
        $uibModalInstance.dismiss('cancel');
      }

      function save () {
        vm.isSaving = true;
        if (vm.sponsorInvoice.id !== null) {
          SponsorInvoice.update(vm.sponsorInvoice, onSaveSuccess, onSaveError);
        } else {
          SponsorInvoice.save(vm.sponsorInvoice, onSaveSuccess, onSaveError);
        }
      }

      function onSaveSuccess (result) {
        $scope.$emit('winkballApp:sponsorInvoiceUpdate', result);
        $uibModalInstance.close(result);
        vm.isSaving = false;
      }

      function onSaveError () {
        vm.isSaving = false;
      }

      vm.datePickerOpenStatus.paymentDate = false;

      function openCalendar (date) {
        vm.datePickerOpenStatus[date] = true;
      }

    }
})();
