(function() {
    'use strict';

    angular
        .module('winkballApp')
        .factory('InventoryItemSearch', InventoryItemSearch);

    InventoryItemSearch.$inject = ['$resource'];

    function InventoryItemSearch($resource) {
        var resourceUrl =  'api/_search/inventory-items/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
