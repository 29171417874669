(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('SponsorInvoiceDetailController', SponsorInvoiceDetailController);

    SponsorInvoiceDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'Principal', 'Sponsor', 'SponsorInvoice'];

    function SponsorInvoiceDetailController($scope, $rootScope, $state, $stateParams, previousState, entity, Principal, Sponsor, SponsorInvoice) {
        var vm = this;

        vm.sponsorInvoice = entity;
        vm.previousState = previousState.name;
        vm.canEdit = canEdit;
        vm.isManager = isManager;
        vm.back = back;

        getAccount();

        var unsubscribe = $rootScope.$on('winkballApp:sponsorInvoiceUpdate', function(event, result) {
            vm.sponsorInvoice = result;
        });

        function getAccount() {
          Principal.identity().then(function(account) {
            console.log('identity loaded');
            vm.account = account;
          });
        }

        function canEdit() {
          if(!vm.account) return false;
          return vm.sponsorInvoice.createdBy === vm.account.login ||
          Principal.hasAnyAuthority(['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SUPERVISOR']);
        }

        function isManager() {
          if(!vm.account) return false;
          return Principal.hasAnyAuthority(['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SUPERVISOR']);
        }

        function back() {
          $state.go(previousState.name, previousState.params);
        }

        $scope.$on('$destroy', unsubscribe);
    }
})();
