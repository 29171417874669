(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('PublisherDialogController', PublisherDialogController);

    PublisherDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', '$log', 'entity', 'Publisher', 'User', 'WebLink', 'Upload'];

    function PublisherDialogController($timeout, $scope, $stateParams, $uibModalInstance, $q, $log, entity, Publisher, User, WebLink, Upload) {
        var vm = this;

        vm.publisher = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.toggleCategory = toggleCategory;
        vm.thumbnailsForDeletion = [];
        vm.originalThumbnail = angular.copy(vm.publisher.profilePicture);

        vm.getImageSrc = getImageSrc;
        vm.getVideoBugSrc = getVideoBugSrc;
        vm.deleteThumbnail = deleteThumbnail;
        vm.deleteVideoBug = deleteVideoBug;
        vm.deleteWebsite = deleteWebsite;
        vm.uploadFile = uploadFile;
        vm.authToken = angular.fromJson(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));

        vm.categories = Publisher.categories();
        vm.users = User.query();
        vm.weblinks = WebLink.query();

        $timeout(function (){
            angular.element('.form-group:eq(2)>input').focus();
        });

        function deleteWebsite() {
            vm.publisher.website = null;
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            
            if (vm.publisher.address && !vm.publisher.address.country) {
                vm.publisher.address.country = 'GBR';
            }

            if (vm.publisher.id !== null) {
                Publisher.update(vm.publisher, onSaveSuccess, onSaveError);
            } else {
                Publisher.save(vm.publisher, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('winkballApp:publisherUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function toggleCategory(category) {
            var idx = vm.publisher.categories.indexOf(category);
            if(idx > -1) {
                vm.publisher.categories.splice(idx, 1);
            } else {
                vm.publisher.categories.push(category);
            }
        }

        function getImageSrc(width, height) {
            var dimentions = '';

            if (width && height) {
                dimentions = '?w=' + width + '&h=' + height;
            }

            if (vm.publisher.profilePicture) {
                if (dimentions) {
                    return 'https://streams2.winkball.com/images/' + vm.publisher.profilePicture + dimentions;
                } else {
                    return 'https://streams2.winkball.com/images/' + vm.publisher.profilePicture + '?quality=ORIGINAL';
    
                }
            }

            return '';
        }

        function getVideoBugSrc(width, height) {
            var dimentions = '';

            if (width && height) {
                dimentions = '?w=' + width + '&h=' + height;
            }

            if (vm.publisher.videoBug) {
                
                if (dimentions) {
                    return 'https://streams2.winkball.com/images/' + vm.publisher.videoBug + dimentions;
                } else {
                    return 'https://streams2.winkball.com/images/' + vm.publisher.videoBug + '?quality=ORIGINAL';
    
                }
            }
            
            return '';
        }

        function deleteThumbnail() {
            vm.thumbnailsForDeletion.push(vm.publisher.profilePicture);
            vm.publisher.profilePicture = null;
        }

        function deleteVideoBug() {
            vm.thumbnailsForDeletion.push(vm.publisher.videoBug);
            vm.publisher.videoBug = null;
        }

        function uploadFile(file, isVideoBug, errFiles) {
            vm.isSaving = true;
            vm.f = file;
            vm.errFile = errFiles && errFiles[0];

            if (file) {
                file.upload = Upload.upload(
                    {
                        url: "https://streams2.winkball.com/images",
                        data: { file: file },
                        headers: {
                            'Authorization': 'Bearer ' + vm.authToken
                        }
                    }
                ).then(function (response) {
                    $timeout(function () {
                        file.result = response.data;
                        vm.isSaving = false;
                        if (response.status === 201) {
                            $log.log('Image upload status 201');
                            if (isVideoBug) {
                                vm.publisher.videoBug = response.data;
                            } else {
                                vm.publisher.profilePicture = response.data;    
                            }
                        }
                    }, 1000);
                }, function (response) {
                    $log.log('Error...');
                    $log.debug(response);
                    vm.isSaving = false;
                    if (response.status > 0) {
                        vm.errorMsg = response.status + ': ' + response.data;
                    }
                }, function (evt) {
                    file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                });
            }
        } // end of uploadFile

        vm.datePickerOpenStatus.dateJoined = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
