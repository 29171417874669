(function() {
    'use strict';

    angular
        .module('winkballApp')
        .factory('AdvertSearch', AdvertSearch);

    AdvertSearch.$inject = ['$resource'];

    function AdvertSearch($resource) {
        var resourceUrl =  'api/_search/adverts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
