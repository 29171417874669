(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('CustomerDetailController', CustomerDetailController);

    CustomerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Customer', 'WebLink'];

    function CustomerDetailController($scope, $rootScope, $stateParams, previousState, entity, Customer, WebLink) {
        var vm = this;

        vm.customer = entity;
        vm.previousState = previousState.name;
        vm.getImageSrc = getImageSrc;

        function getImageSrc(width, height) {
            var dimentions = '';

            if (width && height) {
                dimentions = '?w=' + width + '&h=' + height;
            }

            if (vm.customer.profilePicture) {
                return 'https://streams2.winkball.com/images/' + vm.customer.profilePicture + dimentions;
            }

            return '';
        }

        var unsubscribe = $rootScope.$on('winkballApp:customerUpdate', function(event, result) {
            vm.customer = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
