(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('MediaPackageDetailController', MediaPackageDetailController);

    MediaPackageDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'entity'];

    function MediaPackageDetailController($scope, $rootScope, $state, $stateParams, previousState, entity) {
        var vm = this;

        vm.mediaPackage = entity;
        vm.previousState = previousState.name;
        vm.getImageSrc = getImageSrc;
        vm.back = back;

        function getImageSrc() {

            if (vm.mediaPackage.sponsorDetails.logo) {
                return 'https://streams2.winkball.com/images/'+vm.mediaPackage.sponsorDetails.logo+'?quality=original';
            }

            return '';
        }

        var unsubscribe = $rootScope.$on('winkballApp:mediaPackageUpdate', function(event, result) {
            vm.mediaPackage = result;
        });

        function back() {
            $state.go(previousState.name, previousState.params);
        }

        $scope.$on('$destroy', unsubscribe);
    }
})();
