(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('FilmCommentDetailController', FilmCommentDetailController);

    FilmCommentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'FilmComment', 'Film'];

    function FilmCommentDetailController($scope, $rootScope, $stateParams, previousState, entity, FilmComment, Film) {
        var vm = this;

        vm.filmComment = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('winkballApp:filmCommentUpdate', function(event, result) {
            vm.filmComment = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
