(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('PublisherDetailController', PublisherDetailController);

    PublisherDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Publisher', 'PostalAddress', 'User', 'WebLink'];

    function PublisherDetailController($scope, $rootScope, $stateParams, previousState, entity, Publisher, PostalAddress, User, WebLink) {
        var vm = this;

        vm.publisher = entity;
        vm.previousState = previousState.name;
        vm.getImageSrc = getImageSrc;
        vm.getVideoBugSrc = getVideoBugSrc;

        function getImageSrc(width, height) {
            var dimentions = '';

            if (width && height) {
                dimentions = '?w=' + width + '&h=' + height;
            }

            if (vm.publisher.profilePicture) {
                if (dimentions) {
                    return 'https://streams2.winkball.com/images/' + vm.publisher.profilePicture + dimentions;
                } else {
                    return 'https://streams2.winkball.com/images/' + vm.publisher.profilePicture + '?quality=ORIGINAL';
    
                }
            }

            return '';
        }

        function getVideoBugSrc(width, height) {
            var dimentions = '';

            if (width && height) {
                dimentions = '?w=' + width + '&h=' + height;
            }

            if (vm.publisher.videoBug) {
                
                if (dimentions) {
                    return 'https://streams2.winkball.com/images/' + vm.publisher.videoBug + dimentions;
                } else {
                    return 'https://streams2.winkball.com/images/' + vm.publisher.videoBug + '?quality=ORIGINAL';
    
                }
            }
            
            return '';
        }

        var unsubscribe = $rootScope.$on('winkballApp:publisherUpdate', function(event, result) {
            vm.publisher = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
