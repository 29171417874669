(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('SponsorInvoiceDeleteController',SponsorInvoiceDeleteController);

    SponsorInvoiceDeleteController.$inject = ['$uibModalInstance', 'entity', 'SponsorInvoice'];

    function SponsorInvoiceDeleteController($uibModalInstance, entity, SponsorInvoice) {
        var vm = this;

        vm.sponsorInvoice = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
          SponsorInvoice.delete({id: id},
              function () {
                  $uibModalInstance.close(true);
              });
        }
    }
})();
