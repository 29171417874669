(function () {
    'use strict';

    angular
        .module('winkballApp')
        .controller('CommissionReportRequestDialogController', CommissionReportRequestDialogController);

    CommissionReportRequestDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CommissionReportRequest', 'Reporter'];

    function CommissionReportRequestDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, CommissionReportRequest, Reporter) {
        var vm = this;

        vm.commissionReportRequest = entity;
        vm.clear = clear;
        vm.save = save;
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus = {};
        vm.deleteWebsite = deleteWebsite;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.commissionReportRequest.id !== null) {
                CommissionReportRequest.update(vm.commissionReportRequest, onSaveSuccess, onSaveError);
            } else {
                CommissionReportRequest.save(vm.commissionReportRequest, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('winkballApp:commissionReportRequestUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
        
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function deleteWebsite() {
            vm.commissionReportRequest.website = null;
        }

    }
})();
