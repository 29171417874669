(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('LocationDialogController', LocationDialogController);

    LocationDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Location', 'User', 'NgMap'];

    function LocationDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Location, User, NgMap) {
        var vm = this;

        vm.location = entity;
        vm.location.geoPoint = vm.location.geoPoint || {};

        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.address = "";
        vm.addMarker = addMarker;
        vm.geoCode = geoCode;
        vm.search = "";

        $scope.$on('mapInitialized', function(evt, map) {
            vm.map = map;
            // eslint-disable-next-line no-undef
            google.maps.event.trigger(vm.map, 'resize');
            if(vm.location.geoPoint.lat && vm.location.geoPoint.lon) {
                map.setCenter({lat: vm.location.geoPoint.lat, lng: vm.location.geoPoint.lon});
            }
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.location.id !== null) {
                Location.update(vm.location, onSaveSuccess, onSaveError);
            } else {
                Location.save(vm.location, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('winkballApp:locationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function addMarker(event) {
            console.log(event);
            var ll = event.latLng;
          // round coordinates to 5 decimal places
            vm.location.geoPoint.lon =  Math.floor(ll.lng()*100000+0.5)/100000;
            vm.location.geoPoint.lat =  Math.floor(ll.lat()*100000+0.5)/100000;
        }

        function geoCode() {
            if(vm.search && vm.search.length > 0) {
                if(!vm.geocoder) {
                    vm.geocoder = new google.maps.Geocoder();
                }
                vm.geocoder.geocode({'address': vm.search}, function(results, status){
                    if (status == google.maps.GeocoderStatus.OK){
                        var loc = results[0].geometry.location;
                        vm.search = results[0].formatted_address;
                        // if(!vm.location.title) {
                        //   vm.location.title = vm.search;
                        // }
                        vm.location.geoPoint.lat = Math.floor(loc.lat()*100000+0.5)/100000;
                        vm.location.geoPoint.lon = Math.floor(loc.lng()*100000+0.5)/100000;
                        vm.map.setCenter({lat: vm.location.geoPoint.lat, lng: vm.location.geoPoint.lon});
                    } else {
                        console.log("search whith no results");
                    }
                });
            }
        } // end of geoCode
    }
})();
