(function() {
    'use strict';

    angular
        .module('winkballApp')
        .factory('InterviewCommentSearch', InterviewCommentSearch);

    InterviewCommentSearch.$inject = ['$resource'];

    function InterviewCommentSearch($resource) {
        var resourceUrl =  'api/_search/interview-comments/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
