(function () {
    'use strict';

    angular
        .module('winkballApp')
        .controller('ProspectiveReporterDialogController', ProspectiveReporterDialogController);

    ProspectiveReporterDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', '$log', 'DateUtils', 'entity', 'ProspectiveReporter', 'PostalAddress', 'User', 'WebLink', 'Reporter'];

    function ProspectiveReporterDialogController($timeout, $scope, $stateParams, $uibModalInstance, $q, $log, DateUtils, entity, ProspectiveReporter, PostalAddress, User, WebLink, Reporter) {
        var vm = this;

        vm.reporter = entity;
        vm.clear = clear;
        vm.save = save;
        vm.deleteShowreel = deleteShowreel;
        vm.openCalendar = openCalendar;
        vm.authToken = angular.fromJson(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));

        $timeout(function () {
            angular.element('.form-group:eq(2)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        // function save() {
        //     vm.isSaving = true;
        //     if (vm.reporter.id !== null) {
        //         ProspectiveReporter.update(vm.reporter, onSaveSuccess, onSaveError);
        //     } else {
        //         ProspectiveReporter.save(vm.reporter, onSaveSuccess, onSaveError);
        //     }
        // }

        function save() {
            vm.isSaving = true;
            if(vm.reporter.address.id !== null) {
                PostalAddress.update(vm.reporter.address, saveReporter, onSaveError);
            } else {
                PostalAddress.save(vm.reporter.address, saveReporter, onSaveError);
            }
        }

        // function saveShippingAddress() {
        //     vm.isSaving = true;
        //     if (vm.reporter.shippingAddress) {
        //         if(vm.reporter.shippingAddress.id !== null) {
        //             PostalAddress.update(vm.reporter.shippingAddress, saveReporter, onSaveError);
        //         } else {
        //             PostalAddress.save(vm.reporter.shippingAddress, saveReporter, onSaveError);
        //         }
        //     } else {
        //         saveReporter();
        //     }
        // }

        function saveReporter(address) {
            vm.isSaving = true;
            vm.reporter.address = address;
            
            if (vm.reporter.id !== null) {
                ProspectiveReporter.update(vm.reporter, onSaveSuccess, onSaveError);
            } else {
                ProspectiveReporter.save(vm.reporter, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('winkballApp:prospectiveReporterUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function deleteShowreel() {
            vm.reporter.showreelLink = null;
        }

        vm.datePickerOpenStatus = { birthday: false };
        vm.datePickerOptions = {
            minDate: new Date(1940, 12, 31),
            initDate: new Date(2000, 12, 31)
        };
        vm.dateformat = DateUtils.dateformat();
        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

    }
})();
