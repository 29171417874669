(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('SponsorDetailController', SponsorDetailController);

    SponsorDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$q', 'previousState', 'entity', 'Principal', 'Sponsor', 'SponsorInvoice'];

    function SponsorDetailController($scope, $rootScope, $state, $stateParams, $q, previousState, entity, Principal, Sponsor, SponsorInvoice) {
        var vm = this;

        vm.sponsor = entity;
        vm.previousState = previousState.name;
        vm.canEdit = canEdit;
        vm.isManager = isManager;
        vm.back = back;
        vm.sponsorInvoice = null;

        getAccount();

        $q.all([vm.sponsor.$promise]).then(function() {
            if (!vm.sponsor.id) {
              return $q.reject();
            }
            return SponsorInvoice.get({id : vm.sponsor.id}).$promise;
        }).then(function(invoice) {
          console.log('invoice found');
          vm.sponsorInvoice = invoice;
        });

        var unsubscribe = $rootScope.$on('winkballApp:sponsorUpdate', function(event, result) {
            vm.sponsor = result;
        });

        function getAccount() {
          Principal.identity().then(function(account) {
            console.log('identity loaded');
            vm.account = account;
          });
        }

        function canEdit() {
          if(!vm.account) return false;
          return vm.sponsor.createdBy === vm.account.login ||
          Principal.hasAnyAuthority(['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SUPERVISOR']);
        }

        function isManager() {
          if(!vm.account) return false;
          return Principal.hasAnyAuthority(['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SUPERVISOR']);
        }

        function back() {
          $state.go(previousState.name, previousState.params);
        }

        $scope.$on('$destroy', unsubscribe);
    }
})();
