(function () {
    'use strict';

    angular
        .module('winkballApp')
        .controller('ProspectivePublisherDialogController', ProspectivePublisherDialogController);

    ProspectivePublisherDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', '$log', 'entity', 'ProspectivePublisher', 'PostalAddress', 'User', 'WebLink', 'Publisher', 'Upload'];

    function ProspectivePublisherDialogController($timeout, $scope, $stateParams, $uibModalInstance, $q, $log, entity, ProspectivePublisher, PostalAddress, User, WebLink, Publisher, Upload) {
        var vm = this;

        vm.publisher = entity;
        vm.clear = clear;
        vm.save = save;
        vm.deleteWebsite = deleteWebsite;
        vm.toggleCategory = toggleCategory;
        vm.categories = Publisher.categories();
        vm.thumbnailsForDeletion = [];
        vm.originalThumbnail = angular.copy(vm.publisher.profilePicture);

        vm.getImageSrc = getImageSrc;
        vm.deleteThumbnail = deleteThumbnail;
        vm.uploadFile = uploadFile;
        vm.authToken = angular.fromJson(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));

        $timeout(function () {
            angular.element('.form-group:eq(2)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.publisher.id !== null) {
                ProspectivePublisher.update(vm.publisher, onSaveSuccess, onSaveError);
            } else {
                ProspectivePublisher.save(vm.publisher, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('winkballApp:prospectivePublisherUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function deleteWebsite() {
            vm.publisher.website = null;
        }

        function toggleCategory(category) {
            var idx = vm.publisher.categories.indexOf(category);
            if (idx > -1) {
                vm.publisher.categories.splice(idx, 1);
            } else {
                vm.publisher.categories.push(category);
            }
        }

        function getImageSrc(width, height) {
            var dimentions;

            if (width && height) {
                dimentions = '?w=' + width + '&h=' + height;
            }

            if (vm.publisher.profilePicture) {
                if (dimentions) {
                    return 'https://streams2.winkball.com/images/' + vm.publisher.profilePicture + dimentions;
                } else {
                    return 'https://streams2.winkball.com/images/' + vm.publisher.profilePicture + '?quality=ORIGINAL';
                }        
            }

            return '';
        }

        function deleteThumbnail() {
            vm.thumbnailsForDeletion.push(vm.publisher.profilePicture);
            vm.publisher.profilePicture = null;
        }

        function uploadFile(file, errFiles) {
            vm.isSaving = true;
            vm.f = file;
            vm.errFile = errFiles && errFiles[0];

            if (file) {
                file.upload = Upload.upload(
                    {
                        url: "https://streams2.winkball.com/images",
                        data: { file: file },
                        headers: {
                            'Authorization': 'Bearer ' + vm.authToken
                        }
                    }
                ).then(function (response) {
                    $timeout(function () {
                        file.result = response.data;
                        vm.isSaving = false;
                        if (response.status === 201) {
                            $log.log('Image upload status 201');
                            vm.publisher.profilePicture = response.data;
                        }
                    }, 1000);
                }, function (response) {
                    $log.log('Error...');
                    $log.debug(response);
                    vm.isSaving = false;
                    if (response.status > 0) {
                        vm.errorMsg = response.status + ': ' + response.data;
                    }
                }, function (evt) {
                    file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                });
            }
        } // end of uploadFile
    }
})();
