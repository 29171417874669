(function() {
    'use strict';
    angular
        .module('winkballApp')
        .factory('Job', Job);

    Job.$inject = ['$resource', 'DateUtils'];

    function Job ($resource, DateUtils) {
        var resourceUrl =  'api/jobs/:id';

        return $resource(resourceUrl,
            {
                jobId: '@id',
                reporterId: '@reporterId',
                role: '@role'
            },
            {
                'query': { method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                            data.reportDueDate = DateUtils.convertDateTimeFromServer(data.reportDueDate);
                        }
                        return data;
                    }
                },
                'update': { method:'PUT' },
                'events': { url: 'api/jobs/:id/events', method: 'GET', isArray: true},
                'reporters': { url: 'api/jobs/:id/reporters', method: 'GET', isArray: true},
                'updateReporters': { url: 'api/jobs/:id/reporters', method: 'PUT', isArray: true},
                'sponsors': { url: 'api/jobs/:id/sponsors', method: 'GET', isArray: true},
                'applyForJob': {
                    url: 'api/jobs/:jobId/reporters/:reporterId',
                    method: 'PUT',
                    hasBody: false
                },
                'getJobApplicationsForReporter' : {
                    url: 'api/jobs/:id/reporters/:reporterId',
                    method: 'GET',
                    isArray: true
                },
                'deleteJobApplications' : {
                    url: 'api/jobs/:id/reporters/:reporterId',
                    method: 'DELETE',
                    hasBody: false
                },
                'categories': { method: 'GET', url: 'api/categories', isArray: true }
            });
    }
})();
