(function() {
    'use strict';

    angular
        .module('winkballApp')
        .filter('trustURL', trustURL);

    trustURL.$inject = ['$sce'];

    function trustURL($sce) {
        return trustURLFilter;

        function trustURLFilter(url) {
            return $sce.trustAsResourceUrl(url);
        }
    }
})();
