(function () {
    'use strict';

    angular
        .module('winkballApp')
        .controller('EventReadytofilmController', EventReadytofilmController);

    EventReadytofilmController.$inject = ['$uibModalInstance', '$q', '$scope', 'entity', 'Job', 'Event'];

    function EventReadytofilmController($uibModalInstance, $q, $scope, entity, Job, Event) {
        var vm = this;

        vm.event = entity;
        vm.clear = clear;
        vm.confirmMove = confirmMove;
        vm.isSaving = true;

        $q.all([vm.event.$promise]).then(function () {
            if (!vm.event.id) {
                return $q.reject();
            }

            // set new status
            vm.event.reportStatus = 'READY_TO_FILM';

            vm.isSaving = false;
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmMove(status) {
            vm.isSaving = true;
            if(status) {
                vm.event.reportStatus = status;
            }
            Event.update(vm.event, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('winkballApp:eventUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
