(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('VideoDetailController', VideoDetailController);

    VideoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Video', 'VideoFile', 'Location', 'Keyword'];

    function VideoDetailController($scope, $rootScope, $stateParams, previousState, entity, Video, VideoFile, Location, Keyword) {
        var vm = this;

        vm.video = entity;
        vm.previousState = previousState.name;
        vm.getVideoSrc = getVideoSrc;
        vm.getImageSrc = getImageSrc;

        function getVideoSrc(quality, format) {
            quality = quality || "HD_720P";
            format = format || "MP4";

            if (vm.video.videoFileGuid) {
                return 'https://streams2.winkball.com/videos/'+vm.video.videoFileGuid+'?quality='+quality+'&format='+format;
            }
            return "";
        }

        function getImageSrc(width, height) {
            var dimentions = '';

            if(width && height) {
                dimentions = '?w='+width+'&h='+height;
            }

            if (vm.video.thumbnail) {
                return 'https://streams2.winkball.com/images/'+vm.video.thumbnail+dimentions;
            }

            return '';
        }

        var unsubscribe = $rootScope.$on('winkballApp:videoUpdate', function(event, result) {
            vm.video = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
