(function() {
    'use strict';

    angular
        .module('winkballApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('reporter', {
            parent: 'entity',
            url: '/reporter',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'winkballApp.reporter.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/reporter/reporters.html',
                    controller: 'ReporterController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reporter');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('reporter-detail', {
            parent: 'entity',
            url: '/reporter/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'winkballApp.reporter.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/reporter/reporter-detail.html',
                    controller: 'ReporterDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reporter');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Reporter', function($stateParams, Reporter) {
                    return Reporter.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'home',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('reporter-detail-login', {
            parent: 'entity',
            url: '/reporter/user/{login}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'winkballApp.reporter.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/reporter/reporter-detail.html',
                    controller: 'ReporterDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reporter');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Reporter', function($stateParams, Reporter) {
                    return Reporter.getByLogin({login : $stateParams.login}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'reporter',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('reporter-detail.edit', {
            parent: 'reporter-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_REPORTER', 'ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'previousState', function($stateParams, $state, $uibModal, previousState) {
                $uibModal.open({
                    templateUrl: 'app/entities/reporter/reporter-dialog.html',
                    controller: 'ReporterDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Reporter', function(Reporter) {
                            return Reporter.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                  if(previousState.name === 'reporter-detail-login') {
                    $state.go(previousState.name, previousState.params, {reload: true});
                  } else {
                    $state.go('^', {}, { reload: true });
                  }
                }, function() {
                  if(previousState.name === 'reporter-detail-login') {
                    $state.go(previousState.name, previousState.params, {reload: false});
                  } else {
                    $state.go('^');
                  }
                });
            }]
        })
        .state('reporter.new', {
            parent: 'reporter',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/reporter/reporter-dialog.html',
                    controller: 'ReporterDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                puid: null,
                                level: null,
                                rating: null,
                                contactEmail: null,
                                profilePicture: null,
                                dateJoined: null,
                                contactNumber: null,
                                aboutMe: null,
                                id: null,
                                published: false,
                                langKey: "en",
                                addressId: null,
                                skills: []
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('reporter', null, { reload: true });
                }, function() {
                    $state.go('reporter');
                });
            }]
        })
        .state('reporter.edit', {
            parent: 'reporter',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/reporter/reporter-dialog.html',
                    controller: 'ReporterDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Reporter', function(Reporter) {
                            return Reporter.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('reporter', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('reporter.delete', {
            parent: 'reporter',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/reporter/reporter-delete-dialog.html',
                    controller: 'ReporterDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Reporter', function(Reporter) {
                            return Reporter.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('reporter', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
