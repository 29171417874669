(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('EventEmailController', EventEmailController);

    EventEmailController.$inject = ['$window','$uibModalInstance', '$q', '$http', '$log', 'entity', 'Job'];

    function EventEmailController($window, $uibModalInstance, $q, $http, $log, entity, Job) {
        var vm = this;

        vm.event = entity;
        vm.clear = clear;
        vm.email = {};
        vm.sendEmail = sendEmail;
        vm.contacts = [];
        vm.jobApplicants = [];
        vm.reporters = [];
        vm.authToken = angular.fromJson(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));
        vm.toggleContact = toggleContact;
        vm.eventUrl = '';

        $q.all([vm.event.$promise]).then(function() {
            if(vm.event.reporters.length) {
                vm.reporters = vm.event.reporters.map(function(reporter){
                    return { reporter: reporter.user, reporterJobRole : 'UNKNOWN', accepted: true };
                });
            }
            // set subject to report title
            vm.email.subject = vm.event.title;

            if(!vm.event.job) {
                $log.log('No job rejecting...');
                return $q.reject();
            }

            // load job applicants
            return Job.reporters({id : vm.event.job.id}).$promise;
        }).then(function(reporters) {
            // holds unique email addresses
            var emails = [];
            
            reporters.forEach(function(item) {
                if(item.accepted) {
                    vm.jobApplicants.push(item);
                    if (emails.indexOf(item.reporter.email) === -1) {
                        emails.push(item.reporter.email);
                    }
                }
            });

            if (vm.reporters.length) {
                vm.reporters.forEach(function(item){
                    if (emails.indexOf(item.reporter.email) === -1) {
                        vm.jobApplicants.push(item);
                    }
                });
            }
        }, function () {
            $log.log('No job present'); 
            vm.jobApplicants = vm.reporters;
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function sendEmail() {
            $log.log('email called');
            vm.contacts.forEach(function (to) {
                $http.post(
                    '/api/mail/message',
                    { to: to, subject: vm.email.subject, message: vm.email.message, eventId: vm.event.id }, 
                    {
                        headers:{
                            'Authorization': 'Bearer ' + vm.authToken
                        }
                    }).then(
                      function () {
                          $log.log('email sent');
                      },
                      function () {
                          $log.error('error sending emails');
                      }
                  );
            });
            $uibModalInstance.close(vm.event);
        }

        function toggleContact(contact) {
            var idx = vm.contacts.indexOf(contact);
            if(idx > -1) {
                vm.contacts.splice(idx, 1);
            } else {
                vm.contacts.push(contact);
            }
        }
    }
})();
