(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('ReporterDialogController', ReporterDialogController);

    ReporterDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', '$http', 'entity', 'Principal', 'Reporter', 'PostalAddress', 'User', 'WebLink', 'Job', 'Interview', 'Film', 'Skill', 'JhiLanguageService', 'Upload'];

    function ReporterDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, $http, entity, Principal, Reporter, PostalAddress, User, WebLink, Job, Interview, Film, Skill, JhiLanguageService, Upload) {
        var vm = this;

        vm.reporter = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.isAdmin = isAdmin;
        vm.isManager = isManager;
        vm.getImageSrc = getImageSrc;
        vm.deleteProfilePicture = deleteProfilePicture;
        vm.picturePUIDsForDeletion = [];
        vm.originalProfilePUID = vm.reporter.profilePicture;
        vm.authToken = JSON.parse(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));

        $q.all([vm.reporter.$promise]).then(function() {
            if (!vm.reporter.addressId) {
                return $q.reject();
            }
            return PostalAddress.get({id : vm.reporter.addressId}).$promise;
        }).then(function(address) {
            vm.address = address;
        });

        vm.users = User.query();
        vm.weblinks = [];
        vm.jobs = Job.query();
        vm.interviews = Interview.query();
        vm.films = Film.query({sort: 'id,desc'});
        vm.languages = null;
        vm.skills = Skill.query();
        vm.levels = ['NONE', 'BRONZE', 'SILVER', 'GOLD'];
        vm.checkedSkill = checkedSkill;
        vm.toggleSkill = toggleSkill;
        vm.address = { id: null, country: 'GBR'};

        vm.twitter = {id: null, title: 'twitter'};
        vm.facebook = {id: null, title: 'facebook'};
        vm.instagram = {id: null, title: 'instagram'};
        vm.website = {id: null, title: 'website'};
        vm.deleteLink = deleteLink;

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        $timeout(function (){
            angular.element('.form-group:eq(2)>input').focus();
        });

        $q.all([vm.reporter.$promise]).then(function() {
            if (!vm.reporter.id) {
                return $q.reject();
            }
            return Reporter.getWebLinks({id : vm.reporter.id}).$promise;
        }).then(function(weblinks) {
            console.log(weblinks);
            vm.weblinks = weblinks;

            for (var i = 0; i < vm.weblinks.length; i++) {
                if(vm.weblinks[i].title.toLowerCase() === 'twitter') {
                    vm.twitter = vm.weblinks[i];
                }

                if(vm.weblinks[i].title.toLowerCase() === 'facebook') {
                    vm.facebook = vm.weblinks[i];
                }

                if(vm.weblinks[i].title.toLowerCase() === 'instagram') {
                    vm.instagram = vm.weblinks[i];
                }

                if(vm.weblinks[i].title.toLowerCase() === 'website') {
                    vm.website = vm.weblinks[i];
                }
            }

        });

        function checkedSkill(skill) {
            return containsObjectByID(skill, vm.reporter.skills);
        }

        function containsObjectByID(obj, list) {
            var i;
            for (i = 0; i < list.length; i++) {
                if (list[i].id === obj.id) {
                    return true;
                }
            }

            return false;
        }

        function toggleSkill(skill) {
            var found = vm.reporter.skills.some(function (el) {
                return el.id === skill.id;
            });

            if (!found) {
                vm.reporter.skills.push(skill);
            } else {
                vm.reporter.skills = vm.reporter.skills.filter(function(item) {
                    return item.id !== skill.id;
                });
            }
        }

        function isAdmin() {
            return Principal.hasAnyAuthority(['ROLE_ADMIN']);
        }

        function isManager() {
            return Principal.hasAnyAuthority(['ROLE_MANAGER','ROLE_SUPERVISOR','ROLE_ADMIN']);
        }

        function clear () {
            console.log('calling clear method..');
            console.log('originalProfilePUID: '+vm.originalProfilePUID);
            vm.reporter.profilePicture = vm.originalProfilePUID;

            var puids = vm.picturePUIDsForDeletion.filter(function (value) {
                return vm.originalProfilePUID !== value;
            });

            if(puids.length > 0) {
                $http.post(
              'https://streams2.winkball.com/delete-images',
              puids, {
                  headers:{
                      'Authorization': 'Bearer ' + vm.authToken
                  }}).then(
                function () {
                    console.log('images deleted');
                    vm.picturePUIDsForDeletion = [];
                },
                function () {
                    console.log('error deleting images');
                }
            );
            }

            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            if(vm.picturePUIDsForDeletion.length > 0) {
                $http.post(
                'https://streams2.winkball.com/delete-images',
                vm.picturePUIDsForDeletion, {
                    headers:{
                        'Authorization': 'Bearer ' + vm.authToken
                    }}).then(
                  function () {
                      console.log('images deleted');
                      vm.picturePUIDsForDeletion = [];
                  },
                  function () {
                      console.log('error deleting images');
                  }
              );
            }

            if (vm.reporter.addressId !== null) {
                PostalAddress.update(vm.address, saveReporter, onSaveError);
            } else {
                PostalAddress.save(vm.address, saveReporter, onSaveError);
            }
        }

        function saveReporter(address) {
            vm.reporter.addressId = address.id;
            if (vm.reporter.id !== null) {
                Reporter.update(vm.reporter, onSaveSuccess, onSaveError);
            } else {
                Reporter.save(vm.reporter, onSaveSuccess, onSaveError);
            }
        }

        function deleteLink(link) {
            link.url = null;
        }

        function onSaveSuccess (result) {

            if(vm.twitter.url) {
                vm.twitter.reporterId = result.id;
                if(vm.twitter.id !== null) {
                    WebLink.update(vm.twitter);
                } else {
                    WebLink.save(vm.twitter);
                }
            } else if (vm.twitter.id) {
                WebLink.delete({id: vm.twitter.id});
            }

            if(vm.facebook.url) {
                vm.facebook.reporterId = result.id;
                if(vm.facebook.id !== null) {
                    WebLink.update(vm.facebook);
                } else {
                    WebLink.save(vm.facebook);
                }
            } else if (vm.facebook.id) {
                WebLink.delete({id: vm.facebook.id});
            }

            if(vm.instagram.url) {
                vm.instagram.reporterId = result.id;
                if(vm.instagram.id !== null) {
                    WebLink.update(vm.instagram);
                } else {
                    WebLink.save(vm.instagram);
                }
            } else if (vm.instagram.id) {
                WebLink.delete({id: vm.instagram.id});
            }

            if(vm.website.url) {
                vm.website.reporterId = result.id;
                if(vm.website.id !== null) {
                    WebLink.update(vm.website);
                } else {
                    WebLink.save(vm.website);
                }
            } else if (vm.website.id) {
                WebLink.delete({id: vm.website.id});
            }

            $scope.$emit('winkballApp:reporterUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            vm.originalProfilePUID = vm.reporter.profilePicture;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dateJoined = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function deleteProfilePicture() {
            vm.picturePUIDsForDeletion.push(vm.reporter.profilePicture);
            vm.reporter.profilePicture = null;
        }

        function getImageSrc(width, height) {
            var dimentions = '';

            if(width && height) {
                dimentions = '?w='+width+'&h='+height;
            }

            if (vm.reporter.profilePicture) {
                return 'https://streams2.winkball.com/images/'+vm.reporter.profilePicture+dimentions;
            }

            return '';
        }

        $scope.uploadFile = function(file, errFiles){
            console.log('calling uploadFile...');
            vm.isSaving = true;
            $scope.f = file;
            $scope.errFile = errFiles && errFiles[0];

            if(file) {
                file.upload = Upload.upload(
                    {
                        url: "https://streams2.winkball.com/images",
                        data: {file: file},
                        headers: {'Authorization': 'Bearer ' + vm.authToken}
                    }
            );
                file.upload.then(function (response) {
                    $timeout(function() {
                        console.log(response);
                        file.result = response.data;
                        vm.isSaving = false;
                        if(response.status == "201") {
                            console.log('Image upload status 201');
                            vm.reporter.profilePicture = response.data;
                            console.log(vm.reporter.profilePicture);
                        }
                    });
                }, function(response){
                    console.log('Error...');
                    vm.isSaving = false;
                    if(response.status > 0) {
                        $scope.errorMsg = response.status +': ' + response.data;
                    }
                }, function(evt) {
                    file.progress = Math.min(100, parseInt(100.0 * evt.loaded/evt.total));
                });
            }
        };
    }
})();
