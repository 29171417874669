(function() {
    'use strict';

    angular
        .module('winkballApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('event', {
            parent: 'entity',
            url: '/event?filter',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'winkballApp.event.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/event/events.html',
                    controller: 'EventController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('event');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('event-detail', {
            parent: 'entity',
            url: '/event/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'winkballApp.event.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/event/event-detail.html',
                    controller: 'EventDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('event');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Event', function($stateParams, Event) {
                    return Event.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'event',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('event-detail.edit', {
            parent: 'event-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_REPORTER','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event/event-dialog.html',
                    controller: 'EventDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Event', function(Event) {
                            return Event.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true, location: 'replace' });
                }, function() {
                    $state.go('^', null, { location: 'replace' });
                });
            }]
        })
        .state('event.new', {
            parent: 'event',
            url: '/new?job',
            data: {
                authorities: ['ROLE_REPORTER','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event/event-dialog.html',
                    controller: 'EventDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                puid: null,
                                title: null,
                                description: null,
                                startDate: null,
                                endDate: null,
                                published: false,
                                categories: [],
                                reportStatus: 'IN_PRODUCTION',
                                id: null
                            };
                        }
                    }
                }).result.then(function(result) {
                    if($stateParams.job) {
                        $state.go('job-detail', { id : $stateParams.job }, { reload: true });
                    } else {
                        $state.go('event', null, { reload: true });
                    }
                }, function(result) {
                    if($stateParams.job) {
                        $state.go('job-detail', { id : $stateParams.job });
                    } else {
                        $state.go('event');
                    }
                });
            }]
        })
        .state('event.edit', {
            parent: 'event',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_REPORTER','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event/event-dialog.html',
                    controller: 'EventDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Event', function(Event) {
                            return Event.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('event', null, { reload: true , location: 'replace'});
                }, function() {
                    $state.go('^', null, { location: 'replace' });
                });
            }]
        })
        .state('event-delete', {
            parent: 'event-detail',
            url: '/delete',
            data: {
                authorities: ['ROLE_MANAGER','ROLE_SUPERVISOR','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event/event-delete-dialog.html',
                    controller: 'EventDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Event', function(Event) {
                            return Event.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('event', null, { reload: true , location: 'replace'});
                }, function() {
                    $state.go('^', null, { location: 'replace' });
                });
            }]
        })
        .state('event-share', {
            parent: 'event-detail',
            url: '/share',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event/event-share-dialog.html',
                    controller: 'EventShareController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Event', function(Event) {
                            return Event.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    // $state.go('event', null, { reload: false, location: 'replace' });
                    $state.go('^', null, { reload: false, location: 'replace' });
                }, function() {
                    $state.go('^', null, { location: 'replace' });
                });
            }]
        }).state('event-readytofilm', {
            parent: 'event-detail',
            url: '/readytofilm',
            data: {
                authorities: ['ROLE_MANAGER', 'ROLE_SUPERVISOR', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event/event-readytofilm-dialog.html',
                    controller: 'EventReadytofilmController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Event', function (Event) {
                            return Event.get({
                                id: $stateParams.id
                            }).$promise;
                        }]
                    }
                }).result.then(function () {
                    $state.go('^', null, {
                        reload: true, location: 'replace'
                    });
                }, function () {
                    $state.go('^', null, { location: 'replace' });
                });
            }]
        }).state('event-status', {
            parent: 'event-detail',
            url: '/eventstatus?status',
            data: {
                authorities: ['ROLE_MANAGER', 'ROLE_SUPERVISOR', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event/event-status-dialog.html',
                    controller: 'EventStatusDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Event', function (Event) {
                            return Event.get({
                                id: $stateParams.id
                            }).$promise;
                        }]
                    }
                }).result.then(function () {
                    $state.go('^', null, {
                        reload: true, location: 'replace'
                    });
                }, function () {
                    $state.go('^', null, { location: 'replace' });
                });
            }]
        }).state('new-media-package', {
            parent: 'event-detail',
            url: '/new-media?event',
            data: {
                authorities: ['ROLE_REPORTER','ROLE_MANAGER','ROLE_SUPERVISOR','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/media-package/media-package-dialog.html',
                    controller: 'MediaPackageDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                title: null,
                                url: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    if($stateParams.event) {
                        $state.go('event-detail', { id : $stateParams.event }, { reload: true, location: 'replace'});
                    } else {
                        $state.go('media-package', null, { reload: true, location: 'replace' });
                    }
                }, function() {
                    if($stateParams.event) {
                        $state.go('event-detail', { id : $stateParams.event }, { reload: true, location: 'replace' });
                    } else {
                        $state.go('media-package', null, { location: 'replace' });
                    }
                });
            }]
        }).state('event-email', {
            parent: 'event-detail',
            url: '/email',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event/event-email-dialog.html',
                    controller: 'EventEmailController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Event', function(Event) {
                            return Event.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: false, location: 'replace' });
                }, function() {
                    $state.go('^', null, { location: 'replace' });
                });
            }]
        }).state('event-email-prospective-publisher', {
            parent: 'event-detail',
            url: '/email-prospective-publisher',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event/event-email-prospective-publisher-dialog.html',
                    controller: 'EventEmailProspectivePublisherController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: ['Event', function(Event) {
                            return Event.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: false, location: 'replace' });
                }, function() {
                    $state.go('^', null, { location: 'replace' });
                });
            }]
        }).state('event-update-team', {
            parent: 'event-detail',
            url: '/update-team',
            data: {
                authorities: ['ROLE_USER']
            },
            params: {applicants: null},
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event/event-update-team-dialog.html',
                    controller: 'EventUpdateTeamController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Event', function(Event) {
                            return Event.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true, location: 'replace' });
                }, function() {
                    $state.go('^', null, { location: 'replace' });
                });
            }]
        }).state('event-add-comment', {
            parent: 'event-detail',
            url: '/add-comment',
            data: {
                authorities: ['ROLE_USER']
            },
            params: {applicants: null},
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event-comment/event-comment-dialog.html',
                    controller: 'EventCommentDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                comment: null,
                                rating: null,
                                eventId: $stateParams.id,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true, location: 'replace' });
                }, function() {
                    $state.go('^', null, { location: 'replace' });
                });
            }]
        });

    }

})();
