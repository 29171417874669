(function () {
    'use strict';

    angular
        .module('winkballApp')
        .controller('JobInproductionController', JobInproductionController);

    JobInproductionController.$inject = ['$uibModalInstance', '$q', '$scope', 'entity', 'Job', 'Event'];

    function JobInproductionController($uibModalInstance, $q, $scope, entity, Job, Event) {
        var vm = this;

        vm.job = entity;
        vm.clear = clear;
        vm.confirmMove = confirmMove;
        
        vm.event = {
            puid: null,
            title: null,
            description: null,
            startDate: null,
            endDate: null,
            published: false,
            categories: [],
            id: null
        };
        vm.isSaving = true;

        $q.all([vm.job.$promise]).then(function () {
            if (!vm.job.id) {
                return $q.reject();
            }

            // copy some properties of the job to the new event
            vm.event.job = vm.job;
            vm.event.title = vm.job.title;
            vm.event.description = vm.job.description;
            vm.event.manager = vm.job.manager;
            vm.event.startDate = vm.job.startDate;
            vm.event.reportDueDate = vm.job.reportDueDate;
            vm.event.keyTalent = vm.job.keyTalent;
            vm.event.socialMedia = vm.job.socialMedia;
            vm.event.editorialStoryline = vm.job.editorialStoryline;
            vm.event.contactName = vm.job.contactName;
            vm.event.contactPhone = vm.job.contactPhone;
            vm.event.contactEmail = vm.job.contactEmail;

            vm.event.categories = vm.job.categories;
            vm.event.reportStatus = 'IN_PRODUCTION';
            

            if (vm.job.location) {
                vm.event.location = vm.job.location;
            }

            vm.isSaving = false;
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmMove() {
            vm.isSaving = true;
            Event.save(vm.event, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('winkballApp:eventUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();

