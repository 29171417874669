(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('ImageDialogController', ImageDialogController);

    ImageDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', '$log', '$q', '$http', 'entity', 'Image', 'Upload'];

    function ImageDialogController ($timeout, $scope, $uibModalInstance, $log, $q, $http, entity, Image, Upload) {
        var vm = this;

        vm.image = entity;
        vm.thumbnailsForDeletion = [];
        vm.originalThumbnail = vm.image.puid;
        vm.originalFileName = vm.image.fileName;
        vm.originalSize = vm.image.size;
        vm.originalType = vm.image.type;
        vm.clear = clear;
        vm.save = save;
        vm.getImageSrc = getImageSrc;
        vm.deleteThumbnail = deleteThumbnail;
        vm.uploadFile = uploadFile;
        vm.authToken = angular.fromJson(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            vm.image.puid = vm.originalThumbnail;
            vm.image.size = vm.originalSize;
            vm.image.fileName = vm.originalFileName;
            vm.image.type = vm.originalType;

            var puids = vm.thumbnailsForDeletion.filter(function (value) {
                return vm.originalThumbnail !== value;
            });

            if(puids.length > 0) {
                $http.post(
              'https://streams2.winkball.com/delete-images',
              puids, {
                  headers:{
                      'Authorization': 'Bearer ' + vm.authToken
                  }}).then(
                function () {
                    vm.thumbnailsForDeletion = [];
                },
                function () {
                    $log.error('error deleting images');
                }
            );
            }

            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            if(vm.thumbnailsForDeletion.length > 0) {
                $http.post(
                'https://streams2.winkball.com/delete-images',
                vm.thumbnailsForDeletion, {
                    headers:{
                        'Authorization': 'Bearer ' + vm.authToken
                    }}).then(
                  function () {
                      vm.thumbnailsForDeletion = [];
                  },
                  function () {
                      $log.error('error deleting thumbnail');
                  }
              );
            }

            if (vm.image.id !== null) {
                Image.update(vm.image, onSaveSuccess, onSaveError);
            } else {
                Image.save(vm.image, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('winkballApp:imageUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            vm.originalThumbnail = vm.image.puid;
            vm.originalSize = vm.image.size;
            vm.originalFileName = vm.image.fileName;
            vm.originalType = vm.image.type;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function deleteThumbnail() {
            vm.thumbnailsForDeletion.push(vm.image.puid);
            vm.image.puid = null;
        }

        function getImageSrc(width, height) {
            var dimentions = '';

            if(width && height) {
                dimentions = '?w='+width+'&h='+height;
            }

            if (vm.image.puid) {
                return 'https://streams2.winkball.com/images/'+ vm.image.puid + dimentions;
            }

            return '';
        }

        function uploadFile(file, errFiles) {
            vm.isSaving = true;
            vm.f = file;
            vm.errFile = errFiles && errFiles[0];
            // $log.log(vm.f);
            if (file) {
                file.upload = Upload.upload(
                    {
                        url: "https://streams2.winkball.com/images",
                        data: { file: file },
                        headers: {
                            'Authorization': 'Bearer ' + vm.authToken
                        }
                    }
              ).then(function (response) {
                  $timeout(function () {
                      file.result = response.data;
                      vm.isSaving = false;
                      if (response.status === 201) {
                          $log.log('Image upload status 201');  
                          vm.image.puid = response.data;
                          vm.image.fileName = file.name;
                          vm.image.size = file.size;
                          vm.image.type = file.type;
                          $log.log(vm.image);
                      }
                  }, 1000);
              }, function (response) {
                  $log.log('Error...');
                  $log.debug(response);
                  vm.isSaving = false;
                  if (response.status > 0) {
                      vm.errorMsg = response.status + ': ' + response.data;
                  }
              }, function (evt) {
                  file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
              });
            }
        } // end of uploadFile
    }
})();
