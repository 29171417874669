(function() {
    'use strict';

    angular
        .module('winkballApp')
        .config(tagsInputConfig);

    tagsInputConfig.$inject = ['tagsInputConfigProvider'];

    function tagsInputConfig(tagsInputConfigProvider) {
      // set below to true to make tagsInput watch placeholder dynamic value
      tagsInputConfigProvider.setActiveInterpolation('tagsInput', { placeholder: true });
    }
})();
