(function() {
    'use strict';

    angular
        .module('winkballApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('report-detail', {
            parent: 'entity',
            url: '/report/{id}?filter',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'winkballApp.job.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/report/report-detail.html',
                    controller: 'ReportDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('event');
                    $translatePartialLoader.addPart('job');
                    $translatePartialLoader.addPart('sponsor');
                    $translatePartialLoader.addPart('location');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Event', function($stateParams, Event) {
                    return Event.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'home',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        }).state('report-detail.team', {
            parent: 'report-detail',
            url: '/team/{jobId}',
            data: {
                authorities: ['ROLE_REPORTER', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/project/project-team-dialog.html',
                    controller: 'ProjectTeamDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Job', function(Job) {
                            return Job.get({id : $stateParams.jobId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true, location: 'replace' });
                }, function() {
                    $state.go('^', {}, { location: 'replace' });
                });
            }]
        }).state('report-detail.sponsor', {
            parent: 'report-detail',
            url: '/sponsor?type',
            data: {
                authorities: ['ROLE_REPORTER', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sponsor/sponsor-dialog.html',
                    controller: 'SponsorDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                sponsorName: null,
                                puid: null,
                                createdDate: null,
                                contactName: null,
                                contactEmail: null,
                                telephone: null,
                                finder: null,
                                // job: { id : $stateParams.id },
                                address: { id: null, country: 'GBR'},
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true, location: 'replace' });
                }, function() {
                    $state.go('^', {}, { location: 'replace' });
                });
            }]
        }).state('report-detail.location', {
            parent: 'report-detail',
            url: '/location/{locationId}',
            data: {
                authorities: ['ROLE_REPORTER', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/location/location-dialog.html',
                    controller: 'LocationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Location', function(Location) {
                            return Location.get({id : $stateParams.locationId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true, location: 'replace' });
                }, function() {
                    $state.go('^', {}, { location: 'replace' });
                });
            }]
        }).state('report-detail.first', {
            parent: 'report-detail',
            url: '/first',
            params: { report: null },
            data: {
                authorities: ['ROLE_REPORTER','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/report/report-wizard-first.html',
                    controller: 'FirstReportWizardController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Event', function(Event) {
                            return Event.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('report-detail.second', { report: result }, { reload: false });
                }, function() {
                    $state.go('^', {}, { location: 'replace', reload: true });
                });
            }]
        }).state('report-detail.second', {
            parent: 'report-detail',
            url: '/second',
            params: { report: null },
            data: {
                authorities: ['ROLE_REPORTER','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/report/report-wizard-second.html',
                    controller: 'SecondReportWizardController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function(result) {
                    $state.go('report-detail.last', { report: result }, { reload: false });
                }, function(result) {
                    if(result === 'cancel') {
                        $state.go('report-detail', { id: $stateParams.id}, { reload: true });
                    } else {
                        $state.go('report-detail.first', { report: result }, { reload: false });
                    }
                });
            }]
        }).state('report-detail.last', {
            parent: 'report-detail',
            url: '/last',
            params: { report: null },
            data: {
                authorities: ['ROLE_REPORTER','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/report/report-wizard-last.html',
                    controller: 'LastReporttWizardController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function(result) {
                    $state.go('report-detail', { id: $stateParams.id }, { reload: true });
                }, function(result) {
                    if(result === 'cancel') {
                        $state.go('report-detail', { id: $stateParams.id});
                    } else {
                        $state.go('report-detail.second', { report: result }, { reload: false });
                    }
                });
            }]
        }).state('report-detail.new-first', {
            parent: 'report-detail',
            url: '/new-first',
            params: { project: null },
            data: {
                authorities: ['ROLE_REPORTER','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/project/project-wizard-first.html',
                    controller: 'FirstProjectWizardController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                id: null,
                                puid: null,
                                startDate: null,
                                endDate: null,
                                title: null,
                                description: null,
                                contactName: null,
                                contactPhone: null,
                                categories: [],
                            };
                        }
                    }
                }).result.then(function(result) {
                    $state.go('report-detail.new-second', { project: result }, { reload: false });
                }, function() {
                    $state.go('^', {}, { location: 'replace' });
                });
            }]
        }).state('report-detail.new-second', {
            parent: 'report-detail',
            url: '/new-second',
            params: { project: null },
            data: {
                authorities: ['ROLE_REPORTER','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/project/project-wizard-second.html',
                    controller: 'SecondProjectWizardController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function(result) {
                    $state.go('report-detail.last', { project: result }, { reload: false });
                }, function(result) {
                    if(result === 'cancel') {
                        $state.go('report-detail');
                    } else {
                        $state.go('report-detail.new-first', { project: result }, { reload: false });
                    }
                });
            }]
        }).state('report-detail.new-last', {
            parent: 'report-detail',
            url: '/new-last',
            params: { project: null },
            data: {
                authorities: ['ROLE_REPORTER','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/project/project-wizard-last.html',
                    controller: 'ProjectWizardController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function() {
                    $state.go('home', null, { reload: true });
                }, function(result) {
                    if(result === 'cancel') {
                        $state.go('report-detail');
                    } else {
                        $state.go('report-detail.new-second', { project: result }, { reload: false });
                    }
                });
            }]
        }).state('report-email', {
            parent: 'report-detail',
            url: '/email',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event/event-email-dialog.html',
                    controller: 'EventEmailController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Event', function(Event) {
                            return Event.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: false, location: 'replace' });
                }, function() {
                    $state.go('^', null, { location: 'replace' });
                });
            }]
        }).state('report-delete', {
            parent: 'report-detail',
            url: '/delete',
            data: {
                authorities: ['ROLE_MANAGER','ROLE_SUPERVISOR','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event/event-delete-dialog.html',
                    controller: 'EventDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Event', function(Event) {
                            return Event.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('home', null, { reload: true , location: 'replace'});
                }, function() {
                    $state.go('^', null, { location: 'replace' });
                });
            }]
        }).state('report-readytofilm', {
            parent: 'report-detail',
            url: '/readytofilm',
            data: {
                authorities: ['ROLE_MANAGER', 'ROLE_SUPERVISOR', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event/event-readytofilm-dialog.html',
                    controller: 'EventReadytofilmController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Event', function (Event) {
                            return Event.get({
                                id: $stateParams.id
                            }).$promise;
                        }]
                    }
                }).result.then(function () {
                    $state.go('^', null, {
                        reload: true, location: 'replace'
                    });
                }, function () {
                    $state.go('^', null, { location: 'replace' });
                });
            }]
        }).state('report-status', {
            parent: 'report-detail',
            url: '/reportstatus?status',
            data: {
                authorities: ['ROLE_MANAGER', 'ROLE_SUPERVISOR', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event/event-status-dialog.html',
                    controller: 'EventStatusDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Event', function (Event) {
                            return Event.get({
                                id: $stateParams.id
                            }).$promise;
                        }]
                    }
                }).result.then(function () {
                    $state.go('^', null, {
                        reload: true, location: 'replace'
                    });
                }, function () {
                    $state.go('^', null, { location: 'replace' });
                });
            }]
        }).state('report-detail.film', {
            parent: 'report-detail',
            url: '/film/{filmId}',
            data: {
                authorities: ['ROLE_MANAGER', 'ROLE_SUPERVISOR', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/report/report-film-detail.html',
                    controller: 'ReportFilmDetailController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: ['Film', function (Film) {
                            return Film.get({
                                id: $stateParams.filmId
                            }).$promise;
                        }]
                    }
                }).result.then(function () {
                    $state.go('^', null, {
                        reload: true, location: 'replace'
                    });
                }, function () {
                    $state.go('^', null, { location: 'replace' });
                });
            }]
        }).state('report-detail.apply', {
            parent: 'report-detail',
            url: '/job/{jobId}/apply',
            data: {
                authorities: ['ROLE_REPORTER', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/job/job-apply-dialog.html',
                    controller: 'JobApplyController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['Job', function(Job) {
                            return Job.get({id : $stateParams.jobId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true, location: 'replace' });
                }, function() {
                    $state.go('^', {}, { location: 'replace' });
                });
            }]
        }).state('report-detail.share', {
            parent: 'report-detail',
            url: '/share',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event/event-share-dialog.html',
                    controller: 'EventShareController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Event', function(Event) {
                            return Event.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    // $state.go('event', null, { reload: false, location: 'replace' });
                    $state.go('^', null, { reload: false, location: 'replace' });
                }, function() {
                    $state.go('^', null, { location: 'replace' });
                });
            }]
        }).state('report-detail.share-interview', {
            parent: 'report-detail',
            url: '/interview/{interviewId}/share',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/interview/interview-share-dialog.html',
                    controller: 'InterviewShareController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Interview', function(Interview) {
                            return Interview.get({id : $stateParams.interviewId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: false, location: 'replace' });
                }, function() {
                    $state.go('^', null, { location: 'replace' });
                });
            }]
        }).state('report-detail.new-media-package', {
            parent: 'report-detail',
            url: '/new-media?event',
            data: {
                authorities: ['ROLE_REPORTER', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/media-package/media-package-dialog.html',
                    controller: 'MediaPackageDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                title: null,
                                url: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    if($stateParams.event) {
                        $state.go('report-detail', { id : $stateParams.event }, { reload: true, location: 'replace'});
                    } else {
                        $state.go('media-package', null, { reload: true, location: 'replace' });
                    }
                }, function() {
                    if($stateParams.event) {
                        $state.go('report-detail', { id : $stateParams.event }, { reload: true, location: 'replace' });
                    } else {
                        $state.go('media-package', null, { location: 'replace' });
                    }
                });
            }]
        });

    }

})();
