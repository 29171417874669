(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('InventoryItemDetailController', InventoryItemDetailController);

    InventoryItemDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'InventoryItem', 'User'];

    function InventoryItemDetailController($scope, $rootScope, $stateParams, previousState, entity, InventoryItem, User) {
        var vm = this;

        vm.inventoryItem = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('winkballApp:inventoryItemUpdate', function(event, result) {
            vm.inventoryItem = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
