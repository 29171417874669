(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('KeywordDialogController', KeywordDialogController);

    KeywordDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Keyword', 'Event', 'Job', 'Advert', 'Video', 'Interview', 'Film'];

    function KeywordDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Keyword, Event, Job, Advert, Video, Interview, Film) {
        var vm = this;

        vm.keyword = entity;
        vm.clear = clear;
        vm.save = save;
        vm.events = Event.query();
        vm.jobs = Job.query();
        vm.adverts = Advert.query();
        vm.videos = Video.query();
        vm.interviews = Interview.query();
        vm.films = Film.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        $scope.$watch('vm.keyword.text', function() {
          if(vm.keyword.text) {
            vm.keyword.text = vm.keyword.text.toLowerCase();            
          }
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.keyword.id !== null) {
                Keyword.update(vm.keyword, onSaveSuccess, onSaveError);
            } else {
                Keyword.save(vm.keyword, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('winkballApp:keywordUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
