(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('JobEstimatorController', JobEstimatorController);

    JobEstimatorController.$inject = ['$uibModalInstance'];

    function JobEstimatorController($uibModalInstance) {
      var vm = this;

      vm.clear = clear;
      vm.projectPrice = 500;

      vm.numReporters = 1;
      vm.numEditors = 1;
      vm.numFinders = 1;
      vm.numVideographers = 1;
      vm.numTeam = [1,2,3,4,5,6,7,8,9,10];
      vm.calculate = calculate;
      vm.reset = reset;

      calculate();

      function calculate() {
        var rawReporter = vm.projectPrice * 0.1875;
        vm.winkball = round((vm.projectPrice * 0.25), 2);
        vm.totalReporter = round(rawReporter, 2);
        vm.eachReporter = round(rawReporter / vm.numReporters, 2);
        vm.eachVideographer = round(rawReporter / vm.numVideographers, 2);
        vm.eachEditor = round(rawReporter / vm.numEditors, 2);
        vm.eachFinder = round(rawReporter / vm.numFinders, 2);
      }

      function round(number, precision) {
        var factor = Math.pow(10, precision);
        var tempNumber = number * factor;
        var roundedTempNumber = Math.round(tempNumber);
        return roundedTempNumber / factor;
      }

      function reset() {
        vm.projectPrice = 500;
        vm.numReporters = 1;
        vm.numEditors = 1;
        vm.numFinders = 1;
        vm.numVideographers = 1;
        calculate();
      }

      function clear () {
        $uibModalInstance.dismiss('cancel');
      }

    }
})();
