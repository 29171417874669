(function () {
    'use strict';

    angular
        .module('winkballApp')
        .controller('ProspectiveReporterDeleteController', ProspectiveReporterDeleteController);

    ProspectiveReporterDeleteController.$inject = ['$uibModalInstance', '$http', '$log', 'entity', 'ProspectiveReporter'];

    function ProspectiveReporterDeleteController($uibModalInstance, $http, $log, entity, ProspectiveReporter) {
        var vm = this;

        vm.reporter = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.authToken = angular.fromJson(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            ProspectiveReporter.delete({ id: id },
                function () {
                    if(vm.reporter.resume) { 
                        $http.delete('https://streams2.winkball.com/files/' + vm.reporter.resume, {
                            headers:{
                                'Authorization': 'Bearer ' + vm.authToken
                            }
                        }).then(function () {
                            $uibModalInstance.close(true);
                        }, function () {
                            $log.error('error deleting resume');
                        });
                    } else {
                        $uibModalInstance.close(true);
                    }
                });
        }

    }
})();
