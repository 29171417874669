/* global google */
(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('SecondReportWizardController', SecondReportWizardController);

    SecondReportWizardController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', '$http', '$log', 
        'Event', 'Principal', 'UserSearch', 'Location', 'Upload'
    ];

    function SecondReportWizardController($timeout, $scope, $stateParams, $uibModalInstance, $q, $http, $log,
         Event, Principal, UserSearch, Location, Upload) {

        var vm = this;

        vm.event = $stateParams.report;

        vm.clear = clear;
        vm.save = save;
        vm.back = back;
        
        // pass this data from first wizard panel
        vm.thumbnailsForDeletion = [];
        vm.originalThumbnail = angular.copy(vm.event.thumbnail);
        vm.datePickerOptions = {
            minDate: new Date(),
            initDate: new Date()
        };

        vm.account = Principal.identity();

        vm.getImageSrc = getImageSrc;
        vm.deleteThumbnail = deleteThumbnail;
        vm.searchUser = searchUser;
        vm.getManager = getManager;

        vm.authToken = angular.fromJson(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));

        $timeout(function (){
            angular.element('.form-group:eq(2)>input').focus();
        });

        function back() {
            $uibModalInstance.dismiss(vm.event);
        }

        function searchUser(query) {
            return UserSearch.query({query: query}).$promise;
        }

        function getManager(data) {
            vm.event.manager = data.originalObject;
        }

        function clear () {
            vm.event.thumbnail = vm.originalThumbnail;

            var puids = vm.thumbnailsForDeletion.filter(function (value) {
                return vm.originalThumbnail !== value;
            });

            if(puids.length > 0) {
                $http.post(
              'https://streams2.winkball.com/delete-images',
              puids, {
                  headers:{
                      'Authorization': 'Bearer ' + vm.authToken
                  }}).then(
                function () {
                    vm.thumbnailsForDeletion = [];
                },
                function () {
                    $log.log('error deleting videos');
                }
            );
            }

            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            onSaveSuccess(vm.event);
        }

        function onSaveSuccess (result) {
            vm.originalThumbnail = vm.event.thumbnail;
            if (vm.event.id !== null) {
                $scope.$emit('winkballApp:eventUpdate', result);
            }
               
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function getImageSrc(width, height) {
            var dimentions;

            if(width && height) {
                dimentions = '?w='+width+'&h='+height;
            }

            if (vm.event.thumbnail) {
                if (dimentions) {
                    return 'https://streams2.winkball.com/images/' + vm.event.thumbnail + dimentions;
                } else {
                    return 'https://streams2.winkball.com/images/' + vm.event.thumbnail + '?quality=ORIGINAL';
                }
            }

            return '';
        }

        function deleteThumbnail() {
            vm.thumbnailsForDeletion.push(vm.event.thumbnail);
            vm.event.thumbnail = null;
        }
    }
})();
