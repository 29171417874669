(function () {
    'use strict';

    angular
        .module('winkballApp')
        .controller('EventUpdateTeamController', EventUpdateTeamController);

    EventUpdateTeamController.$inject = ['$uibModalInstance', '$stateParams', '$q', '$scope', 'entity', 'Job'];

    function EventUpdateTeamController($uibModalInstance, $stateParams, $q, $scope, entity, Job) {
        var vm = this;

        vm.event = entity;
        vm.applicants = $stateParams.applicants;
        vm.clear = clear;
        vm.confirmUpdate = confirmUpdate;
        vm.isSaving = true;

        $q.all([vm.event.$promise]).then(function () {
            if (!vm.event.id) {
                return $q.reject();
            }
            vm.isSaving = false;
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmUpdate(status) {
            vm.isSaving = true;
            // update reporter applicants which will also trigger Event update
            // Resource.action([parameters], postData, [success], [error])
            Job.updateReporters({ id : vm.event.job.id }, vm.applicants, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
