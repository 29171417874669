(function() {
    'use strict';

    angular
        .module('winkballApp')
        .factory('PostalAddressSearch', PostalAddressSearch);

    PostalAddressSearch.$inject = ['$resource'];

    function PostalAddressSearch($resource) {
        var resourceUrl =  'api/_search/postal-addresses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
