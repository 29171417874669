(function() {
    'use strict';

    angular
        .module('winkballApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'wrapper',
            url: '/?filter',
            data: {
                authorities: []
            },
            views: {
                'wrapper@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('job');
                    $translatePartialLoader.addPart('global');
                    // $translatePartialLoader.addPart('sponsor');
                    return $translate.refresh();
                }]
            }
        }).state('member-software',{
            parent: 'wrapper',
            url: '/member-software',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Members Software'
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/member-software.html'
                }
            }
        }).state('sales-documents',{
            parent: 'wrapper',
            url: '/sales-documents',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Sales Documents'
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/sales-documents.html'
                }
            }
        }).state('social-media',{
            parent: 'wrapper',
            url: '/social-media',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Social Media'
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/social-media.html'
                }
            }
        }).state('training-documents',{
            parent: 'wrapper',
            url: '/training-documents',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Training Documents'
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/training-documents.html'
                }
            }
        }).state('winkball-chat',{
            parent: 'wrapper',
            url: '/winkball-chat',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Winkball Chat'
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/winkball-chat.html'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }).state('training-videos',{
            parent: 'wrapper',
            url: '/training-videos',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Training Videos'
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/training-videos.html'
                }
            }
        }).state('project-apply', {
            parent: 'home',
            url: 'new-project/{id}/apply',
            data: {
                authorities: ['ROLE_REPORTER', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/job/job-apply-dialog.html',
                    controller: 'JobApplyController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['Job', function(Job) {
                            return Job.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true, location: 'replace' });
                }, function() {
                    $state.go('^', {}, { location: 'replace' });
                });
            }]
        }).state('wizard', {
            abstract: true,
            parent: 'home',
            url: 'wizard'
        }).state('wizard.first', {
            parent: 'wizard',
            url: '/first',
            params: { project: null },
            data: {
                authorities: ['ROLE_REPORTER','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/project/project-wizard-first.html',
                    controller: 'FirstProjectWizardController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                id: null,
                                puid: null,
                                startDate: null,
                                endDate: null,
                                title: null,
                                description: null,
                                contactName: null,
                                contactPhone: null,
                                categories: [],
                            };
                        }
                    }
                }).result.then(function(result) {
                    $state.go('wizard.second', { project: result }, { reload: false });
                }, function() {
                    $state.go('home');
                });
            }]
        }).state('wizard.second', {
            parent: 'wizard',
            url: '/second',
            params: { project: null },
            data: {
                authorities: ['ROLE_REPORTER','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/project/project-wizard-second.html',
                    controller: 'SecondProjectWizardController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function(result) {
                    $state.go('wizard.last', { project: result }, { reload: false });
                }, function(result) {
                    if(result === 'cancel') {
                        $state.go('home');
                    } else {
                        $state.go('wizard.first', { project: result }, { reload: false });
                    }
                });
            }]
        }).state('wizard.last', {
            parent: 'wizard',
            url: '/last',
            params: { project: null },
            data: {
                authorities: ['ROLE_REPORTER','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/project/project-wizard-last.html',
                    controller: 'ProjectWizardController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function() {
                    $state.go('home', null, { reload: true });
                }, function(result) {
                    if(result === 'cancel') {
                        $state.go('home');
                    } else {
                        $state.go('wizard.second', { project: result }, { reload: false });
                    }
                });
            }]
        });
    }
})();
