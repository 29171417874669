(function () {
    'use strict';

    angular
        .module('winkballApp')
        .controller('ProspectiveReporterEmailController', ProspectiveReporterEmailController);

    ProspectiveReporterEmailController.$inject = ['$uibModalInstance', '$stateParams', 'entity', 'ProspectiveReporter'];

    function ProspectiveReporterEmailController($uibModalInstance, $stateParams, entity, ProspectiveReporter) {
        var vm = this;

        vm.reporter = entity;
        vm.clear = clear;
        vm.confirmEmail = confirmEmail;
        
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmEmail() {
            if($stateParams.accepted.toLowerCase() === 'true') {
                ProspectiveReporter.invite(vm.reporter,
                    function () {
                        $uibModalInstance.close(true);
                    }
                );
            } else {
                ProspectiveReporter.reject(vm.reporter,
                    function () {
                        $uibModalInstance.close(true);
                    }
                );
            }
            
        }

    }
})();
