(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('KeywordDetailController', KeywordDetailController);

    KeywordDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Keyword', 'Event', 'Job', 'Advert', 'Video', 'Interview', 'Film'];

    function KeywordDetailController($scope, $rootScope, $stateParams, previousState, entity, Keyword, Event, Job, Advert, Video, Interview, Film) {
        var vm = this;

        vm.keyword = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('winkballApp:keywordUpdate', function(event, result) {
            vm.keyword = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
