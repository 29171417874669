(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('JobController', JobController);

    JobController.$inject = ['$scope', '$state', '$stateParams', 'Job', 'JobSearch', 'ParseLinks', 'AlertService', 'Principal'];

    function JobController ($scope, $state, $stateParams, Job, JobSearch, ParseLinks, AlertService, Principal) {
        var vm = this;

        vm.jobs = [];
        vm.loadPage = loadPage;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.predicate = 'startDate';
        vm.reset = reset;
        vm.reverse = false;
        vm.inTransit = false;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.search = search;
        vm.setApproved = setApproved;
        vm.canEdit = canEdit;
        vm.canDelete = canDelete;
        vm.filter = 'all';
        vm.statuses = [];
        vm.setFilter = setFilter;
        vm.getImageSrc = getImageSrc;
        vm.goToReport = goToReport;
        vm.setStatus = setStatus;

        if($stateParams.filter) {
            vm.filter = $stateParams.filter;
        }

        if($stateParams.status) {
            vm.statuses.push($stateParams.status);
        }

        //Principal.identity();
        getAccount();
        loadAll();

        function loadAll () {
            if (vm.currentSearch) {
                vm.filter = 'all';
                JobSearch.query({
                    query: vm.currentSearch,
                    page: vm.page,
                    size: 15,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Job.query({
                    page: vm.page,
                    size: 15,
                    sort: sort(),
                    filter: vm.filter,
                    status: vm.statuses
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.jobs.push(data[i]);
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset () {
            vm.page = 0;
            vm.jobs = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function clear () {
            vm.jobs = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = 'startDate';
            vm.reverse = false;
            vm.searchQuery = null;
            vm.currentSearch = null;
            vm.loadAll();
        }

        function setFilter() {
            vm.page = 0;
            vm.jobs = [];
            vm.loadAll();
        }

        function setStatus(status) {

            var idx = vm.statuses.indexOf(status);

            // if currently selected
            if (idx > -1) {
                // remove status
                vm.statuses.splice(idx, 1);
            } else {
                // add status
                vm.statuses.push(status);
            }

            setFilter();
        }

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function setApproved(job, isApproved) {
            if(Principal.hasAnyAuthority(['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SUPERVISOR'])) {
                job.approved = isApproved;
                Job.update(job, function () {
                    // vm.loadAll();
                    vm.clear();
                });
            }
        }

        function canEdit(job) {
            if(!vm.account) return false;
            return job.createdBy === vm.account.login ||
          (job.manager && job.manager.login === vm.account.login) ||
          Principal.hasAnyAuthority(['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SUPERVISOR']);
        }

        function canDelete() {
            return Principal.hasAnyAuthority(['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SUPERVISOR']);
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }

            vm.jobs = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.loadAll();
        }

        function getImageSrc(thumbnail, width, height) {
            var dimentions = '';

            if (width && height) {
                dimentions = '?w=' + width + '&h=' + height;
            }
            
            if (thumbnail) {
                if (dimentions) {
                    return 'https://streams2.winkball.com/images/' + thumbnail + dimentions;
                } else {
                    return 'https://streams2.winkball.com/images/' + thumbnail + '?quality=ORIGINAL';
                }
            }

            return '';
        }

        function goToReport(jobId) {
            vm.inTransit = true;
            Job.events({id : jobId}).$promise.then(function(events) {
                vm.inTransit = false;
                if(angular.isArray(events) && events.length) {
                    $state.go('event-detail', {id: events[0].id});
                } else {
                    $state.go('job-detail', {id: jobId});
                }               
            }, function(){
                vm.inTransit = false;
                $state.go('job-detail', {id: jobId});
            });          
        }
    }
})();
