(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('AdvertDialogController', AdvertDialogController);

    AdvertDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Advert', 'VideoFile', 'KeywordSearch', 'Upload'];

    function AdvertDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Advert, VideoFile, KeywordSearch, Upload) {
        var vm = this;

        vm.advert = entity;
        vm.clear = clear;
        vm.save = save;
        vm.getVideoSrc = getVideoSrc;
        vm.searchKeywords = searchKeywords;

        vm.videofiles = VideoFile.query({filter: 'advert-is-null'});
        $q.all([vm.advert.$promise, vm.videofiles.$promise]).then(function() {
            if (!vm.advert.videoFileId) {
                return $q.reject();
            }
            return VideoFile.get({id : vm.advert.videoFileId}).$promise;
        }).then(function(videoFile) {
            vm.videofiles.push(videoFile);
        });

        function searchKeywords(query) {
            return KeywordSearch.query({
                query: query,
                page: 0,
                size: 10
            }).$promise;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.advert.id !== null) {
                Advert.update(vm.advert, onSaveSuccess, onSaveError);
            } else {
                Advert.save(vm.advert, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('winkballApp:advertUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function getVideoSrc(quality, format) {
            quality = quality || "HD_720P";
            format = format || "MP4";

            if (vm.advert.videoFileGuid) {
                return 'https://streams2.winkball.com/videos/'+vm.advert.videoFileGuid+'?quality='+quality+'&format='+format;
            }
            return "";
        }

        $scope.uploadFile = function(file, errFiles){
            vm.isSaving = true;
            $scope.f = file;
            $scope.errFile = errFiles && errFiles[0];

            var authToken = angular.fromJson(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));

            if(file) {
                file.upload = Upload.upload(
                    {
                        url: "https://streams2.winkball.com/videos",
                        data: {file: file},
                        headers: {'Authorization': 'Bearer ' + authToken}
                    }
            );
                file.upload.then(function (response) {
                    $timeout(function() {
                        file.result = response.data;
                        vm.isSaving = false;
                        if(response.status == '201') {
                          vm.advert.videoFileGuid = response.data;
                          vm.advert.videoFileName = file.name;
                      }
                    });
                }, function(response){
                    console.log('Error...');
                    vm.isSaving = false;
                    if(response.status > 0) {
                        $scope.errorMsg = response.status +': ' + response.data;
                    }
                }, function(evt) {
                    file.progress = Math.min(100, parseInt(100.0 * evt.loaded/evt.total));
                });
            }
        };
    }
})();
