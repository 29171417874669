(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('InterviewDetailController', InterviewDetailController);

    InterviewDetailController.$inject = ['$scope', '$rootScope', 'previousState', 'entity', 'Principal', 'Interview', 'VideoFile', 'Location', 'Keyword', 'Reporter', 'Event'];

    function InterviewDetailController($scope, $rootScope, previousState, entity, Principal, Interview, VideoFile, Location, Keyword, Reporter, Event) {
        var vm = this;

        vm.interview = entity;
        vm.previousState = previousState.name;
        vm.getVideoSrc = getVideoSrc;
        vm.getImageSrc = getImageSrc;
        vm.isManager = isManager;

        function getVideoSrc(quality, format) {
            quality = quality || "HD_720P";
            format = format || "MP4";

            if (vm.interview.videoFileGuid) {
                return 'https://streams2.winkball.com/videos/'+vm.interview.videoFileGuid+'?quality='+quality+'&format='+format;
            }
            return "";
        }

        function getImageSrc(width, height) {
            var dimentions = '';

            if(width && height) {
                dimentions = '?w='+width+'&h='+height;
            }

            if (vm.interview.thumbnail) {
                return 'https://streams2.winkball.com/images/'+vm.interview.thumbnail+dimentions;
            }

            return '';
        }

        function isManager() {
            return Principal.hasAnyAuthority(['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SUPERVISOR']);
        }

        var unsubscribe = $rootScope.$on('winkballApp:interviewUpdate', function(event, result) {
            vm.interview = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
