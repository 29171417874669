(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('FilmController', FilmController);

    FilmController.$inject = ['$scope', '$state','Principal', 'Film', 'FilmSearch', 'ParseLinks', 'AlertService'];

    function FilmController ($scope, $state, Principal, Film, FilmSearch, ParseLinks, AlertService) {
        var vm = this;

        vm.films = [];
        vm.loadPage = loadPage;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.predicate = 'createdDate';
        vm.reset = reset;
        vm.reverse = false;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.search = search;
        vm.canEdit = canEdit;
        vm.canDelete = canDelete;

        getAccount();

        loadAll();

        function loadAll () {
            if (vm.currentSearch) {
                FilmSearch.query({
                    query: vm.currentSearch,
                    page: vm.page,
                    size: 20,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Film.query({
                    page: vm.page,
                    size: 20,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.films.push(data[i]);
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset () {
            vm.page = 0;
            vm.films = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function clear () {
            vm.films = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = 'createdDate';
            vm.reverse = false;
            vm.searchQuery = null;
            vm.currentSearch = null;
            vm.loadAll();
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.films = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.loadAll();
        }

        function canEdit(film) {
          if(!vm.account) return false;
          return film.createdBy === vm.account.login ||
          (film.leadReporter && film.leadReporter.login === vm.account.login) ||
          (film.editor && film.editor.login === vm.account.login) ||
          Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SUPERVISOR']);
        }

        function canDelete() {
          if(!vm.account) return false;
          return Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SUPERVISOR']);
        }

        function getAccount() {
          Principal.identity().then(function(account) {
            console.log('identity loaded');
              vm.account = account;
              vm.isAuthenticated = Principal.isAuthenticated;
          });
        }
    }
})();
