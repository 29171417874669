(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('EventController', EventController);

    EventController.$inject = ['$scope', '$state', '$stateParams', 'Principal', 'Event', 'EventSearch', 'ParseLinks', 'AlertService'];

    function EventController ($scope, $state, $stateParams, Principal, Event, EventSearch, ParseLinks, AlertService) {
        var vm = this;

        vm.events = [];
        vm.loadPage = loadPage;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.predicate = 'startDate';
        vm.reset = reset;
        vm.reverse = false;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.search = search;
        vm.account = null;
        vm.canEdit = canEdit;
        vm.canDelete = canDelete;
        vm.filter = 'all';
        vm.statuses = [];
        vm.setFilter = setFilter;
        vm.setStatus = setStatus;
        vm.getImageSrc = getImageSrc;
        vm.goToProposals = goToProposals;

        if($stateParams.filter) {
            vm.filter = $stateParams.filter;
        }
        
        getAccount();
        loadAll();

        function loadAll () {
            if (vm.currentSearch) {
                vm.filter = 'all';
                EventSearch.query({
                    query: vm.currentSearch,
                    page: vm.page,
                    size: 20,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Event.query({
                    page: vm.page,
                    size: 20,
                    sort: sort(),
                    filter: vm.filter,
                    status: vm.statuses
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                // if (vm.predicate !== 'startDate') {
                //     result.push('startDate');
                // }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.events.push(data[i]);
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset () {
            vm.page = 0;
            vm.events = [];
            loadAll();
        }

        function setFilter() {
            vm.page = 0;
            vm.events = [];
            vm.loadAll();
        }

        function setStatus(status) {

            var idx = vm.statuses.indexOf(status);

            // if currently selected
            if (idx > -1) {
                // remove status
                vm.statuses.splice(idx, 1);
            } else {
                // add status
                vm.statuses.push(status);
            }

            setFilter();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function clear () {
            vm.events = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = 'startDate';
            vm.reverse = true;
            vm.searchQuery = null;
            vm.currentSearch = null;
            vm.loadAll();
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.events = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.loadAll();
        }

        function canEdit(event) {
            if(!vm.account) return false;
            return event.createdBy === vm.account.login ||
          (event.manager && event.manager.login === vm.account.login) ||
          vm.account.authorities.indexOf('ROLE_MANAGER') > -1 ||
          vm.account.authorities.indexOf('ROLE_ADMIN') > -1;
        }

        function canDelete() {
            if(!vm.account) return false;
            return Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SUPERVISOR']);
        }

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function getImageSrc(thumbnail, width, height) {
            var dimentions = '';

            if (width && height) {
                dimentions = '?w=' + width + '&h=' + height;
            }
            
            if (thumbnail) {
                if (dimentions) {
                    return 'https://streams2.winkball.com/images/' + thumbnail + dimentions;
                } else {
                    return 'https://streams2.winkball.com/images/' + thumbnail + '?quality=ORIGINAL';
                }
            }

            return '';
        }

        function goToProposals() {
            $state.go('job', { status: 'PROPOSAL'});
        }
    }
})();
