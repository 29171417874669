(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('ReportDetailController', ReportDetailController);

    ReportDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$q', '$translate', 'previousState', 'entity', 'Principal', 'Event', 'MediaPackage', 'Job'];

    function ReportDetailController($scope, $rootScope, $state, $stateParams, $q, $translate, previousState, entity, Principal, Event, MediaPackage, Job) {
        var vm = this;

        vm.event = entity;
        vm.getImageSrc = getImageSrc;
        vm.getVideoSrc = getVideoSrc;
        vm.canEdit = canEdit;
        vm.canDelete = canDelete;
        vm.isCurrentUserApproved = isCurrentUserApproved;
        vm.back = back;
        vm.hasSponsorPack = hasSponsorPack;
        vm.sponsorPack = false;
        vm.jobApplicants = [];
        vm.sponsors = [];
        vm.getStatusClass = getStatusClass;
        vm.isManager = isManager;
        vm.toggleAccepted = toggleAccepted;
        vm.updateTeam = updateTeam;
        vm.categories = Job.categories();
        vm.getCategoryDisplayName = getCategoryDisplayName;
        vm.setFilter = setFilter;

        if($stateParams.filter) {
            vm.filter = $stateParams.filter;
        } else {
            vm.filter = 'mine';
        }
        
        $translate('reportStatus.' + vm.event.reportStatus).then(function(value){
            vm.statusName = value;
        });

        getAccount();

        $q.all([vm.event.$promise]).then(function() {
            if(!vm.event.id) {
                return $q.reject();
            }
          // ignore parameter tells global http interceptor to ignore status 404
          // we do not need 'Not found' error alert in this case
            return MediaPackage.get({id : vm.event.id, ignore: 404}).$promise;
        }).then(function(sponsorPack) {
            if(sponsorPack.id) {
                vm.sponsorPack = true;
            }
        });

        $q.all([vm.event.$promise]).then(function() {
            if(!vm.event.job) {
                return $q.reject();
            }

            // load job applicants
            return Job.reporters({id : vm.event.job.id}).$promise;
        }).then(function(reporters) {

            var counter = 0;

            vm.jobApplicants = reporters.map(function(item) {
                counter++;
                item.id = counter;
                return item;
            });

            // show only accepted applications to reporters
            if (!isManager()) {
                vm.jobApplicants = vm.jobApplicants.filter(function(item) {
                    return item.accepted;
                });
            }
            
        });

        $q.all([vm.event.$promise]).then(function() {
            if(!vm.event.job) {
                return $q.reject();
            }

          // load job applicants
            return Job.sponsors({id : vm.event.job.id}).$promise;
        }).then(function(sponsors) {
            vm.sponsors = sponsors;
        });

        $q.all([vm.event.$promise]).then(function() {
            if(!vm.event.id) {
                return $q.reject();
            }

          // load job applicants
            return Event.comments({id : vm.event.id}).$promise;
        }).then(function(comments) {
            vm.comments = comments;
        });

        function getCategoryDisplayName(name) {
            var cat = vm.categories.find(function(el){
                return el.name === name;
            });

            return cat ? cat.display : 'N/A';
        }

        function toggleAccepted(applicant) {
            applicant.accepted = !applicant.accepted;
        }

        function updateTeam() {
            $state.go('event-update-team', {id: vm.event.id, applicants: vm.jobApplicants});
        }

        function getImageSrc(width, height) {
            var dimentions = '';

            if(width && height) {
                dimentions = '?w='+width+'&h='+height;
            }

            if (vm.event.thumbnail) {
                if (dimentions) {
                    return 'https://streams2.winkball.com/images/' + vm.event.thumbnail + dimentions;
                } else {
                    return 'https://streams2.winkball.com/images/' + vm.event.thumbnail + '?quality=ORIGINAL';

                }
            }

            return '';
        }

        function getVideoSrc(film, quality, format) {
            
            quality = quality || "HD_720P";
            format = format || "MP4";

            if (film.videoFile.guid) {
                return 'https://streams2.winkball.com/videos/'+ film.videoFile.guid +'?quality=' + quality + '&format=' + format;
            }

            return '';
        }

        function canEdit() {
            if(!vm.account) return false;
            return vm.event.createdBy === vm.account.login ||
          (vm.event.manager && vm.event.manager.login === vm.account.login) ||
          vm.account.authorities.indexOf('ROLE_MANAGER') > -1 ||
          vm.account.authorities.indexOf('ROLE_ADMIN') > -1;
        }

        function isCurrentUserApproved() {
            var accepted = vm.jobApplicants.filter(function (item) {
                return item.reporter.login === vm.account.login && item.accepted;
            });
            var team = vm.event.reporters.filter(function (reporter){
                return reporter.user.login === vm.account.login;
            });

            return accepted.length > 0 || team.length > 0;
        }

        function canDelete() {
            if(!vm.account) return false;
            return Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SUPERVISOR']);
        }

        function isManager() {
            return Principal.hasAnyAuthority(['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SUPERVISOR']);
        }

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function hasSponsorPack() {
            return vm.sponsorPack;
        }

        function getStatusClass() {
            switch (vm.event.reportStatus) {
            case 'PROPOSAL':
                return 'bkg-proposal';
            case 'IN_PRODUCTION':
                return 'bkg-in-production';
            case 'READY_TO_FILM':
                return 'bkg-ready-to-film';
            case 'AWATING_SIGN_OFF':
                return 'bkg-awaiting-signoff';
            case 'COMPLETED':
                return 'completed';
            case 'CANCELLED':
                return 'bkg-cancelled';
            default:
                return '';
            }
        }

        function back() {
            // if(previousState.name === 'project-detail') {
            //     $state.go(previousState.name, previousState.params);
            // } else {
            $state.go('home', { filter: vm.filter });
            //}
        }

        function setFilter($event, filter) {
            $event.stopPropagation();
            $event.preventDefault();

            if (filter) {
                vm.filter = filter;
            }
            
            $state.go('home', { filter: vm.filter }, { reload: true , location: 'replace'});
        }

        var unsubscribe = $rootScope.$on('winkballApp:eventUpdate', function(event, result) {
            vm.event = result;
        });

        $scope.$on('$destroy', unsubscribe);
    }
})();
