(function () {
    'use strict';

    angular
        .module('winkballApp')
        .factory('CommissionReportRequestSearch', CommissionReportRequestSearch);

    CommissionReportRequestSearch.$inject = ['$resource'];

    function CommissionReportRequestSearch($resource) {
        var resourceUrl = 'api/_search/commission-report-requests/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();
