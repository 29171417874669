(function () {
    'use strict';

    angular
        .module('winkballApp')
        .controller('ProspectivePublisherController', ProspectivePublisherController);

    ProspectivePublisherController.$inject = ['$scope', '$state', 'ProspectivePublisher', 'ProspectivePublisherSearch', 'ParseLinks', 'AlertService'];

    function ProspectivePublisherController($scope, $state, ProspectivePublisher, ProspectivePublisherSearch, ParseLinks, AlertService) {
        var vm = this;

        vm.publishers = [];
        vm.loadPage = loadPage;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.search = search;
        vm.getImageSrc = getImageSrc;

        loadAll();

        function loadAll() {
            if (vm.currentSearch) {
                ProspectivePublisherSearch.query({
                    query: vm.currentSearch,
                    page: vm.page,
                    size: 20,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                ProspectivePublisher.query({
                    page: vm.page,
                    size: 20,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.publishers.push(data[i]);
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset() {
            vm.page = 0;
            vm.publishers = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function clear() {
            vm.publishers = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = null;
            vm.currentSearch = null;
            vm.loadAll();
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.publishers = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.loadAll();
        }

        function getImageSrc(thumbnail, width, height) {
            var dimentions = '';

            if (width && height) {
                dimentions = '?w=' + width + '&h=' + height;
            }
            
            if (thumbnail) {
                if (dimentions) {
                    return 'https://streams2.winkball.com/images/' + thumbnail + dimentions;
                } else {
                    return 'https://streams2.winkball.com/images/' + thumbnail + '?quality=ORIGINAL';
                }
            }

            return '';
        }
    }
})();
