(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('InterviewDialogController', InterviewDialogController);

    InterviewDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', '$http', 'entity', 'Interview', 'VideoFile', 'Location', 'KeywordSearch', 'Reporter', 'Event', 'Upload', 'LocationSearch', 'EventSearch', 'ReporterSearch'];

    function InterviewDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, $http, entity, Interview, VideoFile, Location, KeywordSearch, Reporter, Event, Upload, LocationSearch, EventSearch, ReporterSearch) {
        var vm = this;

        vm.interview = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.thumbnailsForDeletion = [];
        vm.originalThumbnail = vm.interview.thumbnail;
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.getVideoSrc = getVideoSrc;
        vm.getImageSrc = getImageSrc;
        vm.deleteThumbnail = deleteThumbnail;
        vm.searchLocations = searchLocations;
        vm.tagLocation = [];
        vm.searchEvents = searchEvents;
        vm.getEvent = getEvent;
        vm.searchReporters = searchReporters;

        console.debug(vm.tagLocation);
        vm.authToken = angular.fromJson(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));
        vm.videofiles = VideoFile.query({filter: 'interview-is-null'});

        $q.all([vm.interview.$promise, vm.videofiles.$promise]).then(function() {
            if (!vm.interview.videoFileId) {
                return $q.reject();
            }
            return VideoFile.get({id : vm.interview.videoFileId}).$promise;
        }).then(function(videoFile) {
            vm.videofiles.push(videoFile);
        });
        $q.all([vm.interview.$promise]).then(function() {
            if (!vm.interview.locationId) {
                return $q.reject();
            }
            return Location.get({id : vm.interview.locationId}).$promise;
        }).then(function(location) {
            vm.tagLocation = [location];
        });

        function searchEvents(query) {
            return EventSearch.query({
                query: query,
                page: 0,
                size: 10
            }).$promise;
        }

        function getEvent(data) {
            vm.interview.eventId = data.originalObject.id;
        }

        function searchReporters(query) {
            return ReporterSearch.query({
                query: query,
                page: 0,
                size: 10
            }).$promise;
        }

        $q.all([vm.interview.$promise]).then(function() {
            if (!vm.interview.eventId) {
                return $q.reject();
            }

            return Event.get({id : vm.interview.eventId}).$promise;
        }).then(function(item) {
            // used for setting a value on Event autocomplete input field
            $scope.$broadcast('angucomplete-alt:changeInput', 'field_event', item);
        });

        vm.searchKeywords = searchKeywords;

        function searchKeywords(query) {
            return KeywordSearch.query({
                query: query,
                page: 0,
                size: 10
            }).$promise;
        }

        function searchLocations(query) {
            return LocationSearch.query({
                query: query,
                page: 0,
                size: 10
            }).$promise;
        }

        $timeout(function (){
            angular.element('.form-group:eq(2)>input').focus();
        });

        function clear () {
            console.log('calling clear method..');
            console.log('originalThumbnail: '+vm.originalThumbnail);
            vm.interview.thumbnail = vm.originalThumbnail;

            var puids = vm.thumbnailsForDeletion.filter(function (value) {
                return vm.originalThumbnail != value;
            });

            if(puids.length > 0) {
                $http.post(
              'https://streams2.winkball.com/delete-images',
              puids, {
                  headers:{
                      'Authorization': 'Bearer ' + vm.authToken
                  }}).then(
                function () {
                    console.log('images deleted');
                    vm.thumbnailsForDeletion = [];
                },
                function () {
                    console.log('error deleting images');
                }
            );
            }

            vm.tagLocation = [];

            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            if(vm.thumbnailsForDeletion.length > 0) {
                $http.post(
                'https://streams2.winkball.com/delete-images',
                vm.thumbnailsForDeletion, {
                    headers:{
                        'Authorization': 'Bearer ' + vm.authToken
                    }}).then(
                  function () {
                      console.log('thumbnail deleted');
                      vm.thumbnailsForDeletion = [];
                  },
                  function () {
                      console.log('error deleting thumbnail');
                  }
              );
            }

            console.debug(vm.tagLocation);

            if(vm.tagLocation.length > 0) {
                vm.interview.locationId = vm.tagLocation[0].id;
            } else {
                vm.interview.locationId = null;
            }

            if (vm.interview.id !== null) {
                Interview.update(vm.interview, onSaveSuccess, onSaveError);
            } else {
                Interview.save(vm.interview, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('winkballApp:interviewUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            vm.originalThumbnail = vm.interview.thumbnail;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function deleteThumbnail() {
            console.log('calling deleteThumbnail');
            vm.thumbnailsForDeletion.push(vm.interview.thumbnail);
            vm.interview.thumbnail = null;
        }

        function getVideoSrc(quality, format) {
            quality = quality || "HD_720P";
            format = format || "MP4";

            if (vm.interview.videoFileGuid) {
                return 'https://streams2.winkball.com/videos/'+vm.interview.videoFileGuid+'?quality='+quality+'&format='+format;
            }
            return "";
        }

        function getImageSrc(width, height) {
            var dimentions = '';

            if(width && height) {
                dimentions = '?w='+width+'&h='+height;
            }

            if (vm.interview.thumbnail) {
                return 'https://streams2.winkball.com/images/'+vm.interview.thumbnail+dimentions;
            }

            return '';
        }

        vm.datePickerOpenStatus.interviewDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $scope.uploadFile = function(file, errFiles, image){
            console.log('calling uploadFile...');
            vm.isSaving = true;
            $scope.f = file;
            $scope.video = !image;
            $scope.errFile = errFiles && errFiles[0];

            if(file) {
                var uploadURL = "https://streams2.winkball.com/videos";

                if(image) {
                  uploadURL = "https://streams2.winkball.com/images";
              }

                file.upload = Upload.upload(
                  {
                      url: uploadURL,
                      data: {file: file},
                      headers: {'Authorization': 'Bearer ' + vm.authToken}
                  }
            );

                file.upload.then(function (response) {
                  $timeout(function() {

                    file.result = response.data;
                    vm.isSaving = false;

                    if(response.status == "201") {
                      console.log('Video upload status 201');
                      if(image) {
                        vm.interview.thumbnail = response.data;
                    } else {
                        vm.interview.videoFileGuid = response.data;
                        vm.interview.videoFileName = file.name;
                    //console.log(vm.interview.videoFileName);
                    }
                  }
                });
              }, function(response){
                  console.log('Error...');
                  vm.isSaving = false;
                  if(response.status > 0) {
                    $scope.errorMsg = response.status +': ' + response.data;
                }
              }, function(evt) {
                file.progress = Math.min(100, parseInt(100.0 * evt.loaded/evt.total));
            });
            }
        };
    }
})();
