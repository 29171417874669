(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('FilmShareController', FilmShareController);

    FilmShareController.$inject = ['$uibModalInstance', '$http', 'entity', 'Film'];

    function FilmShareController($uibModalInstance, $http, entity, Film) {
        var vm = this;

        vm.film = entity;
        vm.clear = clear;
        vm.shareFilm = shareFilm;
        vm.share = {};
        vm.share.message = vm.film.title+' https://www.winkball.com/film/' + vm.film.id;
        vm.authToken = angular.fromJson(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));
        vm.twitterUrl = getTwitterUrl();
        vm.mailToUrl = getMailToUrl();
        vm.pinterestUrl = getPinterestUrl();
        vm.videoEmbed = getVideoEmbedCode();

        //console.dir(vm.film);

        function clear () {
            console.log('clear is called');
            $uibModalInstance.dismiss('cancel');
        }

        function shareFilm (id) {
            console.log('shareFilm is called');

            if(vm.share.twitter) {
                console.log('sharing on Twitter');
                console.log(vm.share.message);

                $http.post('https://social.winkball.com/tweets/winkball', {
                    message: vm.share.message
                }, {
                    headers:{
                        'Authorization': 'Bearer ' + vm.authToken
                    }
                }).then(
                function () {
                    console.log('Tweet sent...');
                },
                function () {
                    console.log('Error twitting...');
                }
            );
            }

            if(vm.share.youtube && vm.film.videoFileGuid) {
                console.log('sharing on Youtube');

                var url = 'https://streams2.winkball.com/videos/'+vm.film.videoFileGuid+'?quality=HD_720P';

                var kw = [];
                for (var keyword in vm.film.keywords) {
                    kw.push(keyword.text);
                }

                var youtube = {
                    videoLink: url,
                    title: vm.film.title,
                    description: vm.film.description,
                    tags: kw,
                    channelId: "UC6KIYZw-S2GvisuHd9PC-4g"
                };

                $http.post('https://social.winkball.com/youtube/winkball/video',
                  youtube, {
                      headers: {
                          'Authorization': 'Bearer ' + vm.authToken
                      }
                  }).then(
                    function () {
                        console.log('Youtube video sent...');
                    },
                    function () {
                        console.log('Error sendign Youtube video...');
                    }
                  );
            }

            if(vm.share.facebook) {
                console.log('sharing on Facebook');

                var facebook = {
                    linkUrl: 'https://www.winkball.com/film/' + vm.film.id,
                    title: vm.film.title,
                    description: vm.film.description,
                    caption: 'https://www.winkball.com',
                    message: vm.share.message
                };

                $http.post(
              'https://social.winkball.com/facebook/winkball/page',
              facebook, {
                  headers:{
                      'Authorization': 'Bearer ' + vm.authToken
                  }}).then(
                function () {
                    console.log('Facebook video sent...');
                },
                function () {
                    console.log('Error sendign Facebook video...');
                }
            );
            }

            $uibModalInstance.close(true);
        }

        function getTwitterUrl() {
            return 'https://twitter.com/intent/tweet?url=https://www.winkball.com/films/'+vm.film.id+'&text='+ window.encodeURIComponent(vm.film.title);
        }

        function getMailToUrl() {
            return 'mailto:?subject='+ window.encodeURIComponent(vm.film.title) +'&body=https://www.winkball.com/films/' + vm.film.id;
        }

        function getPinterestUrl() {
            return 'https://pinterest.com/pin/create/button/?url=https://www.winkball.com/films/'+vm.film.id+'&description='+ window.encodeURIComponent(vm.film.title) +'&media=video';
        }

        function getVideoEmbedCode(width, height) {
            if (!width || !height) {
              // youtube defaults
                width = 560;
                height = 315;
            }
            return '<iframe src="https://www.winkball.com/playercard/' + vm.film.videoFileGuid + '" frameborder="0" scrolling="no" width="' + width + '" height="' + height + '"></iframe>';
        }
    }
})();
