(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('WebLinkDialogController', WebLinkDialogController);

    WebLinkDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'WebLink', 'Reporter'];

    function WebLinkDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, WebLink, Reporter) {
        var vm = this;

        vm.webLink = entity;
        vm.clear = clear;
        vm.save = save;
        
        // vm.customers = Customer.query();
        vm.reporters = Reporter.query({
            page: 0,
            size: 60,
            sort: ["user.firstName,asc"]
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.webLink.id !== null) {
                WebLink.update(vm.webLink, onSaveSuccess, onSaveError);
            } else {
                WebLink.save(vm.webLink, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('winkballApp:webLinkUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
    }
})();
