(function() {
    'use strict';

    angular
        .module('winkballApp')
        .factory('SponsorInvoiceSearch', SponsorInvoiceSearch);

    SponsorInvoiceSearch.$inject = ['$resource'];

    function SponsorInvoiceSearch($resource) {
      var resourceUrl =  'api/_search/sponsor-invoices/:id';

      return $resource(resourceUrl, {}, {
          'query': { method: 'GET', isArray: true}
      });
    }
})();
