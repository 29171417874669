(function () {
    'use strict';
    angular
        .module('winkballApp')
        .factory('ProspectiveReporter', ProspectiveReporter);

    ProspectiveReporter.$inject = ['$resource', 'DateUtils'];

    function ProspectiveReporter($resource, DateUtils) {
        var resourceUrl = 'api/prospective-reporters/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.birthday = DateUtils.convertLocalDateFromServer(data.birthday);
                    }
                    return data;
                }
            },
            'update': { method: 'PUT' },
            'categories': { method: 'GET', url: 'api/categories', isArray: true },
            'invite': { method: 'POST', url: 'api/prospective-reporters/invite', hasBody: true},
            'reject': { method: 'POST', url: 'api/prospective-reporters/reject', hasBody: true},
        });
    }
})();
