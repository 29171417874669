(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('ProjectTeamDialogController', ProjectTeamDialogController);

    ProjectTeamDialogController.$inject = ['$state', '$uibModalInstance', '$q', 'entity', 'Job', 'Principal', 'User'];

    function ProjectTeamDialogController($state, $uibModalInstance, $q, entity, Job, Principal, User) {
        var vm = this;

        vm.job = entity;
        vm.clear = clear;
        vm.jobApplicants = [];
        vm.isManager = isManager;
        vm.isCurrentUserApproved = isCurrentUserApproved;
        vm.toggleApproved = toggleApproved;
        vm.toggleAccepted = toggleAccepted;
        vm.updateTeam = updateTeam;

        getAccount();

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

         // load job applicants
        $q.all([vm.job.$promise]).then(function() {
            if(!vm.job.id) {
                return $q.reject();
            }

            return Job.reporters({id : vm.job.id}).$promise;
        }).then(function(reporters) {
            vm.jobApplicants = reporters;

          // set fake id for job applications multiselect directive
            if(vm.jobApplicants.length) {
                var counter = 0;
                vm.jobApplicants = vm.jobApplicants.map(function(item) {
                    counter++;
                    item.id = counter;
                    return item;
                });

                if(!canEdit()) {
                    vm.jobApplicants = vm.jobApplicants.filter(function (item) {
                        return item.reporter.login === vm.account.login || item.accepted;
                    });
                }
            }
        });
        
        function toggleAccepted(applicant) {
            applicant.accepted = !applicant.accepted;
        }
        
        function updateTeam() {
            vm.isSaving = true;
            // update reporter applicants which will also trigger Event update
            // Resource.action([parameters], postData, [success], [error])
            Job.updateReporters({ id : vm.job.id }, vm.jobApplicants, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
        
        function isCurrentUserApproved() {
            var accepted = vm.jobApplicants.filter(function (item) {
                return item.reporter.login === vm.account.login && item.accepted;
            });
            return accepted.length > 0;
        }

        function toggleApproved() {
            if(Principal.hasAnyAuthority(['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SUPERVISOR'])) {
                vm.job.approved = !vm.job.approved;
                Job.update(vm.job, function () {
                    // console.log('job updated');
                });
            }
        }

        function isManager() {
            return Principal.hasAnyAuthority(['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SUPERVISOR']);
        }

        function canEdit() {
            if(!vm.account) return false;
            return vm.job.createdBy === vm.account.login ||
          (vm.job.manager && vm.job.manager.login === vm.account.login) ||
          vm.account.authorities.indexOf('ROLE_MANAGER') > -1 ||
          vm.account.authorities.indexOf('ROLE_ADMIN') > -1;
        }

        function getAccount() {
            Principal.identity().then(function(account) {
                // console.log('identity loaded');
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

    }
})();
