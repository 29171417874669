(function () {
    'use strict';

    angular
        .module('winkballApp')
        .controller('ProspectivePublisherDeleteController', ProspectivePublisherDeleteController);

    ProspectivePublisherDeleteController.$inject = ['$uibModalInstance', 'entity', 'ProspectivePublisher'];

    function ProspectivePublisherDeleteController($uibModalInstance, entity, ProspectivePublisher) {
        var vm = this;

        vm.publisher = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            ProspectivePublisher.delete({ id: id },
                function () {
                    $uibModalInstance.close(true);
                });
        }

    }
})();
