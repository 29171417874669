(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('SponsorDeleteController',SponsorDeleteController);

    SponsorDeleteController.$inject = ['$uibModalInstance', 'entity', 'Sponsor'];

    function SponsorDeleteController($uibModalInstance, entity, Sponsor) {
        var vm = this;

        vm.sponsor = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
          Sponsor.delete({id: id},
              function () {
                  $uibModalInstance.close(true);
              });
        }
    }
})();
