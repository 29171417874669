(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('ProjectDetailController', ProjectDetailController);

    ProjectDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$translate', '$q', 'Principal', 'previousState', 'entity', 'Job', 'User'];

    function ProjectDetailController($scope, $rootScope, $state, $stateParams, $translate, $q, Principal, previousState, entity, Job, User) {
        var vm = this;

        vm.job = entity;
        vm.back = back;
        vm.canEdit = canEdit;
        vm.creator = null;
        vm.events = [];
        vm.getSponsors = hasSponsors;
        vm.sponsors = [];
        vm.toggleApproved = toggleApproved;
        vm.isManager = isManager;
        vm.jobApplicants = [];
        vm.getImageSrc = getImageSrc;
        vm.isCurrentUserApproved = isCurrentUserApproved;
        vm.getStatusClass = getStatusClass;
        vm.categories = Job.categories();
        vm.getCategoryDisplayName = getCategoryDisplayName;
        vm.setFilter = setFilter;

        if($stateParams.filter) {
            vm.filter = $stateParams.filter;
        } else {
            vm.filter = 'mine';
        }

        $translate('reportStatus.' + vm.job.reportStatus).then(function(value){
            vm.statusName = value;
        });

        getAccount();

        $q.all([vm.job.$promise]).then(function() {
            if (!vm.job.createdBy) {
              // console.log('no creator to load....');
                return $q.reject();
            }
            return User.get({login : vm.job.createdBy}).$promise;
        }).then(function(creator) {
          // console.log('creator found');
            vm.creator = creator;
        });

        $q.all([vm.job.$promise]).then(function() {
            if (!vm.job.id) {
                return $q.reject();
            }
            // console.log('Retrieving sponsors!!!');
            return Job.sponsors({id : vm.job.id}).$promise;
        }).then(function(sponsors) {
            // console.log('Sponsors found');
            vm.sponsors = sponsors;
        });

        $q.all([vm.job.$promise]).then(function() {
            return Job.events({id : vm.job.id}).$promise;
        }).then(function(events) {
            // console.log('events found');
            vm.events = events;
        });

        // load job applicants
        $q.all([vm.job.$promise]).then(function() {
            if(!vm.job.id) {
                return $q.reject();
            }

            return Job.reporters({id : vm.job.id}).$promise;
        }).then(function(reporters) {
            vm.jobApplicants = reporters;

          // set fake id for job applications multiselect directive
            if(vm.jobApplicants.length) {
                var counter = 0;
                vm.jobApplicants = vm.jobApplicants.map(function(item) {
                    counter++;
                    item.id = counter;
                    return item;
                });

                if(!canEdit()) {
                    vm.jobApplicants = vm.jobApplicants.filter(function (item) {
                        return item.reporter.login === vm.account.login;
                    });

                }
            }
        });

        function getAccount() {
            Principal.identity().then(function(account) {
                // console.log('identity loaded');
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function back() {
            // if(previousState.name === 'home') {
            $state.go('home', { filter: vm.filter } );
            // } else {
            //     $state.go('project-detail', { filter: vm.filter });
            // }
        }

        function canEdit() {
            if(!vm.account) return false;
            return vm.job.createdBy === vm.account.login ||
          (vm.job.manager && vm.job.manager.login === vm.account.login) ||
          vm.account.authorities.indexOf('ROLE_MANAGER') > -1 ||
          vm.account.authorities.indexOf('ROLE_ADMIN') > -1;
        }

        function isCurrentUserApproved() {
            var accepted = vm.jobApplicants.filter(function (item) {
                return item.reporter.login === vm.account.login && item.accepted;
            });
            return accepted.length > 0;
        }

        function toggleApproved() {
            if(Principal.hasAnyAuthority(['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SUPERVISOR'])) {
                vm.job.approved = !vm.job.approved;
                Job.update(vm.job, function () {
                    // console.log('job updated');
                });
            }
        }

        function isManager() {
            return Principal.hasAnyAuthority(['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SUPERVISOR']);
        }

        function hasSponsors() {
            return vm.sponsors.length > 0;
        }

        function getCategoryDisplayName(name) {
            var cat = vm.categories.find(function(el){
                return el.name === name;
            });

            return cat ? cat.display : 'N/A';
        }

        function getImageSrc(width, height) {
            var dimentions = '';

            if(width && height) {
                dimentions = '?w='+width+'&h='+height;
            }

            if (vm.job.thumbnail) {
                if (dimentions) {
                    return 'https://streams2.winkball.com/images/' + vm.job.thumbnail + dimentions;
                } else {
                    return 'https://streams2.winkball.com/images/' + vm.job.thumbnail + '?quality=ORIGINAL';

                }
            }

            return '';
        }

        function getStatusClass() {
            switch (vm.job.reportStatus) {
            case 'PROPOSAL':
                return 'bkg-proposal';
            case 'IN_PRODUCTION':
                return 'bkg-in-production';
            case 'READY_TO_FILM':
                return 'bkg-ready-to-film';
            case 'AWATING_SIGN_OFF':
                return 'bkg-awaiting-signoff';
            case 'COMPLETED':
                return 'bkg-black';
            case 'CANCELLED':
                return 'bkg-cancelled';
            default:
                return '';
            }
        }

        function setFilter($event, filter) {
            $event.stopPropagation();
            $event.preventDefault();

            if (filter) {
                vm.filter = filter;
            }
            
            $state.go('home', { filter: vm.filter }, { reload: true , location: 'replace'});
        }

        var unsubscribe = $rootScope.$on('winkballApp:jobUpdate', function(event, result) {
            vm.job = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
