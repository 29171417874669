(function () {
    'use strict';
    angular
        .module('winkballApp')
        .factory('ProspectivePublisher', ProspectivePublisher);

    ProspectivePublisher.$inject = ['$resource', 'DateUtils'];

    function ProspectivePublisher($resource, DateUtils) {
        var resourceUrl = 'api/prospective-publishers/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method: 'PUT' },
            'categories': { method: 'GET', url: 'api/categories', isArray: true },
            'invite': { method: 'POST', url: 'api/prospective-publishers/invite', hasBody: true},
        });
    }
})();
