(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('EventDetailController', EventDetailController);

    EventDetailController.$inject = ['$scope', '$rootScope', '$state', '$q', '$translate', 'previousState', 'entity', 'Principal', 'Event', 'MediaPackage', 'Job'];

    function EventDetailController($scope, $rootScope, $state, $q, $translate, previousState, entity, Principal, Event, MediaPackage, Job) {
        var vm = this;

        vm.event = entity;
        vm.getImageSrc = getImageSrc;
        vm.getVideoSrc = getVideoSrc;
        vm.canEdit = canEdit;
        vm.canDelete = canDelete;
        vm.isCurrentUserApproved = isCurrentUserApproved;
        vm.back = back;
        vm.hasSponsorPack = hasSponsorPack;
        vm.sponsorPack = false;
        vm.jobApplicants = [];
        vm.sponsors = [];
        vm.getStatusClass = getStatusClass;
        vm.isManager = isManager;
        vm.toggleAccepted = toggleAccepted;
        vm.updateTeam = updateTeam;

        $translate('reportStatus.' + vm.event.reportStatus).then(function(value){
            vm.statusName = value;
        });

        getAccount();

        $q.all([vm.event.$promise]).then(function() {
            if(!vm.event.id) {
                return $q.reject();
            }
          // ignore parameter tells global http interceptor to ignore status 404
          // we do not need 'Not found' error alert in this case
            return MediaPackage.get({id : vm.event.id, ignore: 404}).$promise;
        }).then(function(sponsorPack) {
            if(sponsorPack.id) {
                vm.sponsorPack = true;
            }
        });

        $q.all([vm.event.$promise]).then(function() {
            if(!vm.event.job) {
                return $q.reject();
            }

            // load job applicants
            return Job.reporters({id : vm.event.job.id}).$promise;
        }).then(function(reporters) {

            var counter = 0;

            vm.jobApplicants = reporters.map(function(item) {
                counter++;
                item.id = counter;
                return item;
            });

            // show only accepted applications to reporters
            if (!isManager()) {
                vm.jobApplicants = vm.jobApplicants.filter(function(item) {
                    return item.accepted;
                });
            }
            
        });

        $q.all([vm.event.$promise]).then(function() {
            if(!vm.event.job) {
                return $q.reject();
            }

          // load job applicants
            return Job.sponsors({id : vm.event.job.id}).$promise;
        }).then(function(sponsors) {
            vm.sponsors = sponsors;
        });

        $q.all([vm.event.$promise]).then(function() {
            if(!vm.event.id) {
                return $q.reject();
            }

          // load job applicants
            return Event.comments({id : vm.event.id}).$promise;
        }).then(function(comments) {
            vm.comments = comments;
        });

        function toggleAccepted(applicant) {
            applicant.accepted = !applicant.accepted;
        }

        function updateTeam() {
            $state.go('event-update-team', {id: vm.event.id, applicants: vm.jobApplicants});
        }

        function getImageSrc(width, height) {
            var dimentions = '';

            if(width && height) {
                dimentions = '?w='+width+'&h='+height;
            }

            if (vm.event.thumbnail) {
                if (dimentions) {
                    return 'https://streams2.winkball.com/images/' + vm.event.thumbnail + dimentions;
                } else {
                    return 'https://streams2.winkball.com/images/' + vm.event.thumbnail + '?quality=ORIGINAL';

                }
            }

            return '';
        }

        function getVideoSrc(film, quality, format) {
            
            quality = quality || "HD_720P";
            format = format || "MP4";

            if (film.videoFile.guid) {
                return 'https://streams2.winkball.com/videos/'+ film.videoFile.guid +'?quality=' + quality + '&format=' + format;
            }

            return '';
        }

        function canEdit(event) {
            if(!vm.account) return false;
            return event.createdBy === vm.account.login ||
          (event.manager && event.manager.login === vm.account.login) ||
          vm.account.authorities.indexOf('ROLE_MANAGER') > -1 ||
          vm.account.authorities.indexOf('ROLE_ADMIN') > -1;
        }

        function isCurrentUserApproved() {
            var accepted = vm.jobApplicants.filter(function (item) {
                return item.reporter.login === vm.account.login && item.accepted;
            });
            var team = vm.event.reporters.filter(function (reporter){
                return reporter.user.login === vm.account.login;
            });

            return accepted.length > 0 || team.length > 0;
        }

        function canDelete() {
            if(!vm.account) return false;
            return Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SUPERVISOR']);
        }

        function isManager() {
            return Principal.hasAnyAuthority(['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SUPERVISOR']);
        }

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function hasSponsorPack() {
            return vm.sponsorPack;
        }

        function getStatusClass() {
            switch (vm.event.reportStatus) {
            case 'PROPOSAL':
                return 'bkg-proposal';
            case 'IN_PRODUCTION':
                return 'bkg-in-production';
            case 'READY_TO_FILM':
                return 'bkg-ready-to-film';
            case 'AWATING_SIGN_OFF':
                return 'bkg-awaiting-signoff';
            case 'COMPLETED':
                return 'bkg-black';
            case 'CANCELLED':
                return 'bkg-cancelled';
            default:
                return '';
            }
        }

        function back() {
            if(previousState.name === 'job-detail') {
                $state.go(previousState.name, previousState.params);
            } else {
                if(previousState.name === 'home') {
                    $state.go('home');
                } else {
                    $state.go('event');
                }
                
            }
            
        }

        var unsubscribe = $rootScope.$on('winkballApp:eventUpdate', function(event, result) {
            vm.event = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
