/* global google */
(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('FirstReportWizardController', FirstReportWizardController);

    FirstReportWizardController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', '$http', '$log', 
        'entity', 'Event', 'Job', 'Principal', 'User', 'Location', 'Upload'
    ];

    function FirstReportWizardController($timeout, $scope, $stateParams, $uibModalInstance, $q, $http, $log,
        entity, Event, Job, Principal, User, Location, Upload) {

        var vm = this;
        if ($stateParams.report) {
            vm.event = $stateParams.report;
        } else {
            vm.event = entity;
        }

        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.search = "";
        vm.users = [];
        vm.phoneRegex = /^[\+|\d]{1}[0-9|\s]{3,20}$/;
        vm.thumbnailsForDeletion = [];
        vm.originalThumbnail = angular.copy(vm.event.thumbnail);
        vm.datePickerOptions = {
            minDate: new Date(),
            initDate: new Date()
        };

        vm.account = Principal.identity();

        vm.getImageSrc = getImageSrc;
        vm.deleteThumbnail = deleteThumbnail;
        vm.uploadFile = uploadFile;

        vm.authToken = angular.fromJson(localStorage.getItem("jhi-authenticationToken") || sessionStorage.getItem("jhi-authenticationToken"));

        $timeout(function (){
            angular.element('.form-group:eq(2)>input').focus();
        });

        // add current user as a manager for new jobs
        vm.account.then(function(account){
            $q.all([vm.event.$promise]).then(function() {
                if(vm.event.id) {
                    return $q.reject();
                }

                return User.get({login:account.login}).$promise;
            }).then(function(user) {
                vm.event.manager = user;
                vm.users.push(user);

                if(user.login !== 'winkball') {
                    var winkballUser = User.get({login:'winkball'}).$promise;
                    winkballUser.then(function (wbUser) {
                        vm.users.push(wbUser);
                    }, function (reason){
                        $log.log(reason);
                    });
                }
            });
        });

        // create a list of reporters from applicants
        $q.all([vm.event.$promise]).then(function() {
            if (!vm.event.id) {
                return $q.reject();
            }

            // set date picker min date depending on the start date
            // for editing of an existing Job
            vm.datePickerOptions.minDate = vm.event.startDate;

            return Job.reporters({id : vm.event.job.id}).$promise;
        }).then(function(reporters) {

            // unwraps reporter and removes duplicate based on id
            vm.users = reporters
                .map(function(resource){
                    return resource.reporter;})
                    .filter(function(reporter, pos, arr) { 
                        return arr.map(function(user){
                            return user.id;
                        }).indexOf(reporter.id) === pos;
                    });

            if(vm.event.manager) {
                vm.users.push(vm.event.manager);
            }
        });

        $q.all([vm.event.$promise]).then(function() {
            if (!vm.event.location) {
                return $q.reject();
            }

            return Location.get({id : vm.event.location.id}).$promise;
        }).then(function(location) {
            vm.tagLocation = [location];
        });

        function clear () {
            vm.event.thumbnail = vm.originalThumbnail;

            var puids = vm.thumbnailsForDeletion.filter(function (value) {
                return vm.originalThumbnail !== value;
            });

            if (puids.length > 0) {
                $http.post(
                    'https://streams2.winkball.com/delete-images',
                    puids, {
                        headers: {
                            'Authorization': 'Bearer ' + vm.authToken
                        }
                    }).then(
                    function () {
                        vm.thumbnailsForDeletion = [];
                    },
                    function () {
                        $log.log('error deleting videos');
                    }
                );
            }

            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            if(vm.thumbnailsForDeletion.length > 0) {
                $http.post(
                'https://streams2.winkball.com/delete-images',
                vm.thumbnailsForDeletion, {
                    headers:{
                        'Authorization': 'Bearer ' + vm.authToken
                    }}).then(
                    function () {
                        vm.thumbnailsForDeletion = [];
                    },
                    function () {
                        $log.log('error deleting thumbnail');
                    }
                );
            }

            onSaveSuccess(vm.event);
        }

        function onSaveSuccess (result) {
            vm.originalThumbnail = vm.event.thumbnail;
            if (vm.event.id !== null) {
                $scope.$emit('winkballApp:eventUpdate', result);
            }

            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function getImageSrc(width, height) {
            var dimentions;

            if(width && height) {
                dimentions = '?w='+width+'&h='+height;
            }

            if (vm.event.thumbnail) {
                if (dimentions) {
                    return 'https://streams2.winkball.com/images/' + vm.event.thumbnail + dimentions;
                } else {
                    return 'https://streams2.winkball.com/images/' + vm.event.thumbnail + '?quality=ORIGINAL';
                }
            }

            return '';
        }

        function deleteThumbnail() {
            vm.thumbnailsForDeletion.push(vm.event.thumbnail);
            vm.event.thumbnail = null;
        }

        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;
        vm.datePickerOpenStatus.reportDueDate = false;
        vm.datePickerOpenStatus.interviewsDueDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function uploadFile(file, errFiles) {
            $log.log('calling uploadFile...');
            vm.isSaving = true;
            vm.f = file;
            vm.errFile = errFiles && errFiles[0];

            if(file) {
                file.upload = Upload.upload(
                    {
                        url: "https://streams2.winkball.com/images",
                        data: {file: file},
                        headers: {
                            'Authorization': 'Bearer ' + vm.authToken
                        }
                    }
            ).then(function (response) {
                $timeout(function() {
                    file.result = response.data;
                    vm.isSaving = false;
                    if(response.status == "201") {
                        $log.log('Image upload status 201');
                        vm.event.thumbnail = response.data;
                    }
                }, 1000);
            }, function(response){
                $log.log('Error...');
                $log.debug(response);
                vm.isSaving = false;
                if(response.status > 0) {
                    vm.errorMsg = response.status +': ' + response.data;
                }
            }, function(evt) {
                file.progress = Math.min(100, parseInt(100.0 * evt.loaded/evt.total));
            });
            }
        } // end of uploadFile
    }
})();
