(function () {
    'use strict';

    angular
        .module('winkballApp')
        .controller('EventStatusDialogController', EventStatusDialogController);

    EventStatusDialogController.$inject = ['$uibModalInstance', '$q', '$scope', '$stateParams', '$translate', 'entity', 'Event'];

    function EventStatusDialogController($uibModalInstance, $q, $scope, $stateParams, $translate, entity, Event) {
        var vm = this;

        vm.event = entity;
        vm.clear = clear;
        vm.confirmMove = confirmMove;
        vm.status = $stateParams.status;
        
        $translate('reportStatus.' + vm.status).then(function(value){
            vm.statusName = value;
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmMove() {
            vm.isSaving = true;

            if($stateParams.status) {
                vm.event.reportStatus = $stateParams.status;
            }

            if(vm.event.reportStatus === 'CANCELLED') {
                vm.event.published = false;
            }

            if(vm.event.reportStatus === 'COMPLETED') {
                vm.event.published = true;
            }

            // if(!vm.event.published) {
            //     // unpublish films too
            //     if (vm.event.films) {
            //         vm.event.films.array.forEach(function(element) {
            //             element.published = false;
            //         });
            //     }
            // } else {
            //     if (vm.event.films && vm.event.length > 0) {
            //         vm.event.films[0].published = true;
            //     }
            // }

            Event.update(vm.event, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('winkballApp:eventUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
