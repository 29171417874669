(function() {
    'use strict';

    angular
        .module('winkballApp')
        .controller('AdvertDetailController', AdvertDetailController);

    AdvertDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Advert', 'VideoFile', 'Keyword'];

    function AdvertDetailController($scope, $rootScope, $stateParams, previousState, entity, Advert, VideoFile, Keyword) {
        var vm = this;

        vm.advert = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('winkballApp:advertUpdate', function(event, result) {
            vm.advert = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
